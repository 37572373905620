// src/App.js
import React, {useEffect, useState, useRef } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import './App.css';
import { throttle } from 'lodash'; // lodash throttle for throttling the function calls
import { CoinbaseWalletSDK } from '@coinbase/wallet-sdk';


import {createPublicClient, http } from 'viem'
import { base } from 'viem/chains'
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';



let signatureSave = null;
let messageSave = null
let pageSave = "None";
let smartWalletAccount = null;
let chainSave = null;
let basecolorTimestamp = 0;


const TARGET_CHAIN_ID = '0x2105'; // Chain ID in hexadecimal (8453 in decimal)
let lastUpdateTime = 0;
let lastServerTransactionTime = 0;

let provider = null

let walletMode = 0;
let localGasPrice = 0;
const App = () => {

  const publicClient = createPublicClient({
    chain: base,
    transport: http()
  })
  const admins = ["0xF5226f23063F3a40Ef21cdA06EB0226d72cfB57E","0xd6c4FDf4B8BcbA11cCb8Fb8af0F684e320AF3C1e"]; // dont even try ;)
  const mods = ["0xF5226f23063F3a40Ef21cdA06EB0226d72cfB57E","0xd6c4FDf4B8BcbA11cCb8Fb8af0F684e320AF3C1e","0xD1EC8245c8850A151843ce8a3AFdca3b19747706",
    "0xd15973d6e8d5d2669d2aD3b1fd21067863358F3f", "0x0fABF023B032659195F9d8590aFfc257412bb5D6", "0xcA88B61fb6441EAb2EF32BbC13db2380BddcD763",
    "0x9c9360419f1a24b28755E670F183B70d60848723"
  ];
  
 // const fakeRequests = [{id: 0, clan_id: 1, eth_address: "0xd6c4FDf4B8BcbA11cCb8Fb8af0F684e320AF3C1e", name: "bobbyswhip"}]

  const fakeStockLog = [{id:0, buyer: "bobbyswhip", mode: "buy", clan_id:  1, price: 25.0, timestamp: 1725291568}]

  const fakeKills = [{id: 0, winner_name: "bobbyswhip", loser_name: "loser", winner_clan_name: "basedmc", loser_clan_name:"losers", token_amount: 25.5, timestamp: 1725291564},
    {id: 1, winner_name: "bobbyswhip", loser_name: "loser", winner_clan_name: "basedmc", loser_clan_name:"losers", token_amount: 24.5, timestamp: 1725291564},
    {id: 2, winner_name: "bobbyswhip", loser_name: "loser", winner_clan_name: "basedmc", loser_clan_name:"losers", token_amount: 23.5, timestamp: 1725291565},
    {id: 3, winner_name: "bobbyswhip", loser_name: "loser", winner_clan_name: "basedmc", loser_clan_name:"losers", token_amount: 22.5, timestamp: 1725291566},
    {id: 4, winner_name: "bobbyswhip", loser_name: "loser", winner_clan_name: "basedmc", loser_clan_name:"losers", token_amount: 21.5, timestamp: 1725291567},
    {id: 5, winner_name: "bobbyswhip", loser_name: "loser", winner_clan_name: "basedmc", loser_clan_name:"losers", token_amount: 20.5, timestamp: 1725291568},

  ]

  const fakeClans = [{name: "clan1", stock_price: 50.0, ticker: "cl1", members: [{kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  ]},
  {name: "clan1", stock_price: 50.0, ticker: "cl1", members: [{kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  ]},
  {name: "clan1", stock_price: 50.0, ticker: "cl1", members: [{kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  ]},
  {name: "clan1", stock_price: 50.0, ticker: "cl1", members: [{kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
    {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  ]}
,{name: "clan1", stock_price: 50.0, ticker: "cl1", members: [{kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
]}
,{name: "clan1", stock_price: 50.0, ticker: "cl1", members: [{kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
]}
,{name: "clan1", stock_price: 50.0, ticker: "cl1", members: [{kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
  {kills: 1, killstreak: 0, deaths: 0, death_reset_rank_count: 0, rank: 0, coffer: 0, balance: 100},
]}]
  
  const lowercaseAdmins = admins.map(admin => admin.toLowerCase());
  const lowercaseMods = mods.map(mod => mod.toLowerCase());
  const [admin, setAdmin] = useState(false);
  const [moderator, setModerator] = useState(false);
  const [adminTarget, setAdminTarget] = useState("");
  const [adminValue, setAdminValue] = useState(0.0);
  const [adminMode, setAdminMode] = useState(0);

  const [colorblindMode, setColorblindMode] = useState(false);
  const [screenMode, setScreenMode] = useState(false);

  const [targetClanID, setTargetClanID] = useState(0);
  const [targetClanMemberName, setTargetClanMemberName] = useState("");
  const [targetClanMemberAddress, setTargetClanMemberAddress] = useState("");
  const [targetClanMemberRank, setTargetClanMemberRank] = useState("");
  const [targetClanMemberRankDesc, setTargetClanMemberRankDesc] = useState("");

  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [recieveOn, setRecieveOn] = useState("Wallet");
  const [sendFrom, setSendFrom] = useState("Server");
  const [buyMode, setBuyMode] = useState(0);
  const [swapMode, setSwapMode] = useState(0);
  const [rank, setRank] = useState(0);
  const [bridgeMode, setBridgeMode] = useState(0);
  const [bridgeInput, setBridgeInput] = useState(0);
  const [nameInput, setNameInput] = useState("");
  const [referralInput, setReferralInput] = useState("User who referred you!");


  const [dailyTaxRateInput, setDailyTaxRateInput] = useState();
  const [baseColorTokenIDInput, setBaseColorTokenIDInput] = useState(0);
  const [tickerInput, setTickerInput] = useState();
  const [clanNameInput, setClanNameInput] = useState();
  const [clanDescInput, setClanDescInput] = useState();
  const [isClanMember, setIsClanMember] = useState(false);


  const [baseColor, setBaseColor] = useState("#F6F6F7");


  const handleTaxRateChange = (event) => {
    let value = event.target.value;
    // Remove any characters that are not digits or a single decimal point
    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    // Ensure only one decimal point is allowed
    const validValue = sanitizedValue.includes('.') 
      ? sanitizedValue.split('.').slice(0, 2).join('.')
      : sanitizedValue;
  
    // If the value is empty or just a decimal, update directly
    if (validValue === '' || validValue === '.') {
      setDailyTaxRateInput(validValue);
      return;
    }
  
    // Convert the value to a number
    let numericValue = parseFloat(validValue);
  
    // If the numeric value is valid, enforce min/max limits
    if (!isNaN(numericValue)) {
      if (numericValue > 100) {
        numericValue = 100;
      }
    }
  
    setDailyTaxRateInput(validValue);
  };
  const handleTickerChange = (event) => {
    const value = event.target.value;
    // Remove any non-alphanumeric characters and spaces
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, ''); 
    if (sanitizedValue.length <= 8) {
      setTickerInput(sanitizedValue);
    }
  };
  const handleClanNameChange = (event) => {
    const value = event.target.value;
    // Allow letters, numbers, and spaces only
    const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
    if (sanitizedValue.length <= 24) {
      setClanNameInput(sanitizedValue);
    }
  };
  const handleClanDescChange = (event) => {
    const value = event.target.value;
    // Remove any non-alphanumeric characters (no spaces allowed)
    const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
    if (sanitizedValue.length <= 64) {
      setClanDescInput(sanitizedValue);
    }
  };


  const [sellPath, setSellPath] = useState(0);

  const [specialShopID, setSpecialShopID] = useState(0);
  const [specialShopCost, setSpecialShopCost] = useState(0);
  const [specialShopName, setSpecialShopName] = useState("");
  const [specialShopPopup, setSpecialShopPopup] = useState(false);

  const [specialShopData, setSpecialShopData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [baltopData, setBaltopData] = useState([]);

  const [clanData, setClanData] = useState([]);
  const [ourClanData, setOurClanData] = useState([]);
  const [filteredClanData, setFilteredClanData] = useState([]);
  
  
  const [clanKillsData, setClanKillsData] = useState([]);

  const [basecolorsData, setBasecolorsData] = useState([]);

  const [onlinePlayers, setOnlinePlayers] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");
  const [shopMode, setShopMode] = useState(0);


  const [charmMode, setCharmMode] = useState(0);
  const [charmPopup, setCharmPopup] = useState(false);
  const [charmEnchantments, setCharmEnchantments] = useState([]);
  const [cleanEnchantments, setCleanEnchantments] = useState([]);
  const [charmImage, setCharmImage] = useState("./invis.png");
  const [charmItemID, setCharmItemID] = useState(0);
  const [charmName, setCharmName] = useState("");

  const [itemShopInput, setItemShopInput] = useState(0);

  const [itemDurability, setItemDurability] = useState(0);
  const [itemMaxDurability, setItemMaxDurability] = useState(0);
  const [itemShopID, setItemShopID] = useState(0);
  const [itemShopMode, setItemShopMode] = useState(0);
  const [itemShopEnchantments, setItemShopEnchantments] = useState([]);
  const [filteredItemShopData, setFilteredItemShopData] = useState([]);
  const [itemShopData, setItemShopData] = useState([]);
  const [itemShopName, setItemShopName] = useState("");
  const [itemShopImage, setItemShopImage] = useState("./invis.png");
  const [itemShopPopup, setItemShopPopup] = useState(false);


  const [createClanPopup, setCreateClanPopup] = useState(false);
  const [clanRequestPopup2, setClanRequestPopup2] = useState(false);
  const [clanRequestPopup, setClanRequestPopup] = useState(false);
  const [clanMemberPopup, setClanMemberPopup] = useState(false);
  const [clanMemberPopupMod, setClanMemberPopupMod] = useState(false);

  const [bondingCurveImage, setBondingCurveImage] = useState("./Token512.png");
  const [bondingCurveMode, setBondingCurveMode] = useState(0);
  const [bondingCurveName, setBondingCurveName] = useState("Server");
  const [bondingCurvePrice, setBondingCurvePrice] = useState(0);
  const [bondingCurveInput, setBondingCurveInput] = useState(0);
  const [bondingCurveTokens, setBondingCurveTokens] = useState(0);
  const [bondingCurveBlocks, setBondingCurveBlocks] = useState(0);
  const [curveBalance, setCurveBalance] = useState(0);
  const [curveBalanceUSD, setCurveBalanceUSD] = useState(0);
  const [curvePopup, setCurvePopup] = useState(false);

  const [username, setUsername] = useState("0");
  const [unverified_username, setUnverified_Username] = useState("0");
  const [airdrop, setAirdrop] = useState(0.0);
  const [lastHash, setLastHash] = useState("0x");

  const [filteredCurveData, setFilteredCurveData] = useState([]);
  const [filteredBlocksData, setFilteredBlocksData] = useState({});
  const [filteredItemsData, setFilteredItemsData] = useState([]);
  const [curveData, setCurveData] = useState([]);
  const [tokensInCurves, setTokensInCurves] = useState(0);


  const [AllDuels, setAllDuels] = useState([]);
  const [allData, setAllData] = useState([]);
  const inventoryRef = useRef(null); // Ref to store reference to the inventory grid
  const [scrollPosition, setScrollPosition] = useState(0); // Initialize scrollPosition state


  const [backgroundImageUrl, setBackgroundImageUrl] = useState("/Background2.png");

  const [ethBalance, setEthBalance] = useState(0);

  const [signatureReal, setSignatureReal] = useState(null);

  const [savedTransactionHash, setSavedTransactionHash] = useState(null);

  const [message, setMessage] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [page, setPage] = useState("Home");
  const [pageStarted, setPageStarted] = useState(false);
  const [airdropInput, setAirdropInput] = useState(0.000);

  const myRef = useRef(null);

  const [gasPrice, setGasPrice] = useState(0);
  const [tokenBalanceWallet, setTokenBalanceWallet] = useState(0);
  const [tokenBalanceServer, setTokenBalanceServer] = useState(0);
  const [approvedTokensBridge, setApprovedTokensBridge] = useState(0);
  const [approvedTokensSwap, setApprovedTokensSwap] = useState(0);


  const bridgeAddress = "0xEDEd26FA2b13f073d985d10F75faFD0a25E73802";
  const basecraftAddress = "0x96A07274aBf958Aa04a05b87434DF4cd1BC77e06";

  
  const swapperAddress = '0x35A8399135eb881D75e095EBAB93d27f9be64624'; 

  const sdk = new CoinbaseWalletSDK({
    appName: 'basedMC',
    appChainIds: [8453]
  });





  const basecolorsABI = require('./baseColorsABI.js'); 
  const swapperABI = require('./swapperABI.js'); 
  const basecraftABI = require('./basecraftABI.js'); 
  const bridgeABI = require('./bridgeABI.js'); 

  const [serverIP, setServerIP] = useState(process.env.REACT_APP_SERVER);
  const [alchemyAPIKey, setAlchemyAPIKey] = useState(process.env.REACT_APP_ALCHEMY);
  const MessageToSign = "This signature is used to verify wallet ownership to the bobbywip server.";
  const messageToSign = '\x19Ethereum Signed Message:\n' + MessageToSign.length + MessageToSign;
  

  const [sendPopup, setSendPopup] = useState(false);
  const [itemMaxBalance, setItemMaxBalance] = useState(0);
  const [sendItem, setSendItem] = useState(false);
  const [itemAmount, setItemAmount] = useState(0); // for tools
  const [itemEnchantments, setItemEnchantments] = useState([]); // for tools
  const [itemID, setItemID] = useState(0); // for tools
  const [blockID, setBlockID] = useState("DIRT"); // for blocks
  const [itemName, setItemName] = useState("Diamond Sword"); // for tools
  const [itemImage, setItemImage] = useState("./Items/dirt.png");
  const [blockAmount, setBlockAmount] = useState(0);


  const [buyPopup, setBuyPopup] = useState(false);
  const [buyImage, setBuyImage] = useState("./Items/dirt.png");
  const [buyID, setBuyID] = useState(0); // for tools
  const [buyPrice, setBuyPrice] = useState(0); // for tools
  const [buyEnchantments, setBuyEnchantments] = useState([]);


  const [adminPopup, setAdminPopup] = useState(false);

  const [modPopup, setModPopup] = useState(false);
  const [settingsPopup, setSettingsPopup] = useState(false);
  const [linkPopup, setLinkPopup] = useState(false);
  const [sellPopup, setSellPopup] = useState(false);
  const [transferPopup, setTransferPopup] = useState(false);
  const [createPopup, setCreatePopup] = useState(false);
  const [cleanPopup, setCleanPopup] = useState(false);


  const [blocksData, setBlocksData] = useState(null);
  const [itemsData, setItemsData] = useState(null);


  const [rankupPopup, setRankupPopup] = useState(false);
  const [mainPopup, setMainPopup] = useState(false);
  const [mainPopupTitle, setMainPopupTitle] = useState("None");
  const [mainPopupDescription, setMainPopupDescription] = useState("None");
  const [mainPopupPath, setMainPopupPath] = useState("./LoginSuccess.png");
  const [mainPopupButtonText, setMainPopupButtonText] = useState("None");
  const [mainPopupButton, setMainPopupButton] = useState(0);
  

  const [loginPopup, setLoginPopup] = useState(true);

  const [metamaskInstalled, setMetamaskInstalled] = useState(false);
  const [timestamp, setTimestamp] = useState(0);



  const [amountIn, setAmountIn] = useState('');
  const [amountOut, setAmountOut] = useState('0');
  const [slippage, setSlippage] = useState(0.5);
  const [tokenPrice, setTokenPrice] = useState(0.000000);
  const [ethToUSD, setETHTOUSD] = useState(0.000000);
  const [ourDuel, setOurDuel] = useState(0);

  const [ourClanID, setOurClanID] = useState(0);
  const [clanCreation, setClanCreation] = useState(false);
  const [clanView, setClanView] = useState(false);
  const [clanLeaderboardMode, setClanLeaderboardMode] = useState(0);
  const [clanActionMode, setClanActionMode] = useState(0);



  const [playerRank, setPlayerRank] = useState(0);
  const [nextRankCost, setNextRankCost] = useState(0.0);
  const [rankImage, setRankImage] = useState("./Rank0.png");
  const [nextRankImage, setNextRankImage] = useState("./Rank1.png");
  function truncateAddress(address) {
    if (address && address.startsWith('0x') && address.length > 6) {
      return `${address.substring(0, 8)}...`;
    } else {
      return address; // Return the original address if it doesn't meet the expected format
    }
  }
  const handleShopMode = (mode) => {
    setSearchTerm("");
    setShopMode(mode); 
  };
  const handleChangeClanActionMode = () => {
    if (clanActionMode == 0) {
      setClanActionMode(1);
    } else {
      setClanActionMode(0);
    }
  }
  const handleChangeClanLeaderboardMode = () => {
    if (clanLeaderboardMode == 0) {
      setClanLeaderboardMode(1);
    } else {
      setClanLeaderboardMode(0);
    }
  }


  const handleReset = () => {
    setSearchTerm("");
  }
  const handleSetMaxBondingSell = (max) => {
    setBondingCurveInput(max);
  }
  const handleSetMaxBondingBuy = (max) => {
    setBondingCurveInput(max);
  }


  
  const EventList = ({ events }) => {
    let currentTimestampInSeconds = Math.floor(Date.now() / 1000);

    return (
      <div>
      {events.map((event) => (
        <div key={event.timestamp + "_" + event.message} className="baltop-item" style={{
          backgroundImage: "url(/invis.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
        }}>

          



          <p className="setup-description5" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`${event.message} - ${currentTimestampInSeconds - event.timestamp}s`}</p>



      
        </div>
      ))}
    </div>
    );
  };
  const handleClanIDClick = (clanID) => {
    // Ensure clanData is defined and has items
    let realId = clanID - 1;
    console.log(clanData);
    if (clanData && Array.isArray(clanData) && clanData.length >= realId && clanData[realId]) {
      console.log(`Clicked Clan# ${clanData[realId].id}`);
      setFilteredClanData(clanData[realId]);
      setClanActionMode(0);
      setClanLeaderboardMode(0);
      setClanView(true);
      setTargetClanID(clanData[realId].id);
    } else {
      console.error('Invalid clanID or clanData is undefined.');
    }
    
    // Add your custom logic here
  };

  const StockList = ({ stocks }) => {
    const handleButtonClick = (clan) => {
      // Handle the button click for the specific duel ID



      
      // Add your custom logic here
    };
    return (
      <div>

      {stocks.map((stock) => (
        <div key={stock.id} className="baltop-item" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
        }}>

          



          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`${stock.buyer}`}</p>

          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`${stock.mode} 1x for`}</p>

          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`${stock.price} $basedMC`}</p>


        </div>
      ))}
    </div>
    );
  };


  const MembersKillsList = ({ members }) => {
    const handleButtonClick = (clan) => {
      // Handle the button click for the specific duel ID

      console.log(`Clicked Clan# ${clan.id}`);
      setTargetClanID(clan.id);
      setFilteredClanData(clan);

      
      // Add your custom logic here
    };
    return (
      <div>


      {members.map((member) => (
        <div key={member.id} className="baltop-item" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
        }}>

          



          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`${member.name}:`}</p>

<p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`kills=${member.kills}`}</p>
                
            
                <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`deaths=${member.deaths}`}</p>

                <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`streak=${member.killstreak}`}</p>
            
            


        </div>
      ))}
    </div>
    );
  };


  const handleRank = (rankNum) => {
    if (rankNum == 0) {
      setTargetClanMemberRank("Not Ranked");
      setTargetClanMemberRankDesc("User can't earn/lose clan tokens for dying.");

    } else if (rankNum == 1) {
      setTargetClanMemberRank("Member");
      setTargetClanMemberRankDesc("User can earn/lose clan tokens for dying.");
    } else if (rankNum == 2) {
      setTargetClanMemberRank("Builder");
      setTargetClanMemberRankDesc("User can also build in clan zones.");
    }else if (rankNum == 3) {
      setTargetClanMemberRank("Economist");
      setTargetClanMemberRankDesc("User can also open chests in clan zones.");
    }else if (rankNum >= 4 && rankNum <= 8) {
      setTargetClanMemberRank("Leader");
      setTargetClanMemberRankDesc("User can open chests in clan zones.");
    }else if (rankNum == 9) {
      setTargetClanMemberRank("Moderator");
      setTargetClanMemberRankDesc("User can moderate the clan.");
    }else if (rankNum == 10) {
      setTargetClanMemberRank("Owner");
      setTargetClanMemberRankDesc("User owns the clan.");
    }else {
      setTargetClanMemberRank("Not Ranked");
    }
  }

  const MembersList = ({ members, moderator }) => {
    const handleButtonClick = (member) => {
      // Handle the button click for the specific duel ID

      console.log(`Clicked Member# ${member}`);
      

      setTargetClanMemberAddress(member.eth_address);
      setTargetClanMemberName(member.name);
      handleRank(member.rank);

      setClanMemberPopupMod(true);
      
      // Add your custom logic here
    };
    return (
      <div>

<img src={colorblindMode ? "./clanmembers1.png" : "./clanmembers.png"} alt="transfer block" className="inventory-button-3" style={{width:"90%", marginTop: "0%"}}/>


      {members.map((member) => (
        <div key={member.id} className="baltop-item" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
        }}>

          



          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`[${member.name}]`}</p>

                  {
                    moderator ? (<img src={colorblindMode ? "./viewButton1.png" : "./viewButton.png"} alt="create duel" class="duel-accept-button"
                      style={{width:"30%"}}
                      
                      onClick={() => {handleButtonClick(member)}}/>) : (<div/>)
                  }


        </div>
      ))}
    </div>
    );
  };
  const RequestsList = ({ requests }) => {
    const handleButtonClick = (request) => {
      // Handle the button click for the specific duel ID

      console.log(`Clicked Request# ${request}`);

      setTargetClanMemberAddress(request.eth_address);
      setTargetClanMemberName(request.name);
      handleRank(request.rank);
      setClanRequestPopup(true);


      
      // Add your custom logic here
    };
    return (
      <div>

<img src={colorblindMode ? "./joinrequests1.png" : "./joinrequests.png"} alt="transfer block" className="inventory-button-3" style={{width:"90%", marginTop: "0%"}}/>


      {requests.map((request) => (
        <div key={request.key} className="baltop-item" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
        }}>

          



          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`[${request.name}]`}</p>

<img src={colorblindMode ? "./viewButton1.png" : "./viewButton.png"} alt="create duel" class="duel-accept-button"
                      style={{width:"30%"}}
                      
                      onClick={() => {handleButtonClick(request)}}/>
        </div>
      ))}
    </div>
    );
  };
  const KillsList = ({ kills }) => {
    const handleButtonClick = (clan) => {
      // Handle the button click for the specific duel ID

      
      // Add your custom logic here
    };
    return (
      <div>
      {kills.map((kill) => (
        <div key={kill.id} className="baltop-item" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
        }}>

          



          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`[${kill.winner_clan_name}]`}</p>
          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`Killed`}</p>
          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`[${kill.loser_clan_name}]`}</p>
          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`for ${kill.token_amount.toFixed(1)} $basedmc`}</p>

        </div>
      ))}
    </div>
    );
  };

  const ClansList = ({ clans }) => {
    const handleButtonClick = (clan) => {
      // Assuming you have the user's eth_address
    
      // Check if the user is in the clan's members list
      const isMember = clan.members.some(member => member.eth_address.toLowerCase() === account.toLowerCase());
    
      // Set the isClanMember state based on whether the user is found in the members array
      setIsClanMember(isMember);
      console.log("is member = " + isMember);
    
      console.log(`Clicked Clan# ${clan.id}`);
      setFilteredClanData(clan);
      setClanActionMode(0);
      setClanLeaderboardMode(0);
      setClanView(true);
      setTargetClanID(clan.id);
    
      // Add your custom logic here
    };
    return (
      <div>
      {clans.map((clan) => (
        <div key={clan.ticker} className="baltop-item" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
        }}>

          



          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`[${clan.ticker}]`}</p>
          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`Stock Price: ${clan.stock_price.toFixed(1)} $basedMC`}</p>
          <p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`${clan.members.length} members`}</p>
<img src={colorblindMode ? "./viewButton1.png" : "./viewButton.png"} alt="create duel" class="duel-accept-button" onClick={() => {handleButtonClick(clan)}}/>

      
        </div>
      ))}
    </div>
    );
  };


  const BaltopList = ({ balances }) => {

    return (
      <div>
      {balances.map((balance) => (
        <div key={balance.address} className="baltop-item" style={{
          backgroundImage: "url(/invis.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
        }}>

          



          <p className="setup-description5" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`${balance.username}:`}</p>
          <p className="setup-description5" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`${balance.tokens.toFixed(1)} tokens`}</p>


      
        </div>
      ))}
    </div>
    );
  };
  
  const BasecolorList = ({ balances }) => {
    const handleBaseColorButtonClick = (id, color) => {
      // Handle the button click for the specific duel ID
      console.log(`Button clicked for BaseColor ID: ${id}`);

      setBaseColorTokenIDInput(id);
      setBaseColor(color);
      // Add your custom logic here
    };
  
    return (
      <div>
      {balances.map((balance) => (
        <p key={balance.id} className="basecolor-item" style={{
          backgroundColor: balance.color,
          width: "50%",
          height: "20%",
          marginLeft: "25%",
          cursor: "pointer",
          userSelect: "none"
        }} onClick={() => handleBaseColorButtonClick(balance.id, balance.color)}>{balance.id}</p>
      ))}
    </div>
    );
  };
  const DuelList = ({ duels }) => {
    const handleDuelButtonClick = (duelId) => {
      // Handle the button click for the specific duel ID
      console.log(`Button clicked for Duel ID: ${duelId}`);

      
      // Add your custom logic here
    };
  
    return (
      <div>
      {duels.map((duel) => (
        <div key={duel.verified_address} className="duel-item" style={{
          backgroundImage: `url(${!screenMode ? "./Plank.png" : "./Plank.png"})`,
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
        }}>

          



          <p className="setup-description5" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`${truncateAddress(duel.address) || 'N/A'}`}</p>
          <p className="setup-description5" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`Tokens: ${duel.duel || 'N/A'}`}</p>


<img src={colorblindMode ? "./duel1.png" : "./duel.png"} alt="create duel" class="duel-accept-button" onClick={() => handleAccept(duel.address)}/>
      
        </div>
      ))}
    </div>
    );
  };
  const ItemImage = ({ name }) => {
    const lowercaseName = name.toLowerCase(); // Convert name to lowercase
    const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/Items/${lowercaseName}.png`);
  
    useEffect(() => {
      const imageUrl = `${process.env.PUBLIC_URL}/Items/${lowercaseName}.png`;
  
      // Create a new Image object to check if the image exists
      const img = new Image();
      img.src = imageUrl;
  
      img.onload = () => {
        setImageSrc(imageUrl);
      };
  
      img.onerror = () => {
        
        if (colorblindMode) {
          setImageSrc(`${process.env.PUBLIC_URL}/close1.png`);

        } else{
          setImageSrc(`${process.env.PUBLIC_URL}/close.png`);

        }
      };
    }, [lowercaseName]); // Depend on lowercaseName instead of imageSrc
  
    // Style object for the container div
    const containerStyle = {
      position: "relative", // Ensure relative positioning
      width: "100%", // Set the width to 100%
      height: "100%", // Set the height to 100%
      zIndex: 22,
      // overflow: "hidden", // Remove or adjust this line if necessary
    };
  
    // Style object for the image
    const imageStyle = {
      marginTop: "1%",
      marginLeft: "1%",
      width: "33%", // Ensure the image takes up the entire width of the container
      height: "auto", // Let the height adjust proportionally to maintain aspect ratio
      display: "block", // Ensure the image behaves like a block element
      zIndex: 22,
    };
  
    return (
      <div style={containerStyle}>
        <img
          src={imageSrc}
          alt={name}
          style={imageStyle}
        />
      </div>
    );
  };

  const ShopImage = ({ name, type, amount }) => {
    const lowercaseName = name.toLowerCase(); // Convert name to lowercase
    const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/Items/${lowercaseName}.png`);
  
    useEffect(() => {
      const imageUrl = `${process.env.PUBLIC_URL}/Items/${lowercaseName}.png`;
  
      // Create a new Image object to check if the image exists
      const img = new Image();
      img.src = imageUrl;
  
      img.onload = () => {
        setImageSrc(imageUrl);
      };
  
      img.onerror = () => {



        if (colorblindMode) {
          setImageSrc(`${process.env.PUBLIC_URL}/close1.png`);
        } else {
          setImageSrc(`${process.env.PUBLIC_URL}/close.png`);
        }
        
      };
    }, [lowercaseName]); // Depend on lowercaseName instead of imageSrc
  
    // Style object for the container div
    const containerStyle = {
      position: "relative", // Ensure relative positioning
      width: "100%", // Set the width to 100%
      height: "100%", // Set the height to 100%
      zIndex: 22,
      // overflow: "hidden", // Remove or adjust this line if necessary
    };
  
    // Style object for the image
    const imageStyle = {
      marginTop: "-15%",
      marginLeft: "15%",
      width: "70%", // Ensure the image takes up the entire width of the container
      height: "auto", // Let the height adjust proportionally to maintain aspect ratio
      display: "block", // Ensure the image behaves like a block element
      zIndex: 22,
    };
  
    return (
      <div style={containerStyle}>
                 
            <div className="shop-text">{type}</div>
        
        <img
          src={imageSrc}
          alt={name}
          style={imageStyle}
        />
  <div className="shop-top">{amount}</div>

      </div>
    );
  };



  const itemDurabilityMapping = {
    WOOD_AXE: 59,
    STONE_AXE: 131,
    IRON_AXE: 250,
    GOLD_AXE: 32,
    DIAMOND_AXE: 1561,
    NETHERITE_AXE: 2031,
    
    WOOD_HOE: 59,
    STONE_HOE: 131,
    IRON_HOE: 250,
    GOLD_HOE: 32,
    DIAMOND_HOE: 1561,
    NETHERITE_HOE: 2031,
  
    WOOD_PICKAXE: 59,
    STONE_PICKAXE: 131,
    IRON_PICKAXE: 250,
    GOLD_PICKAXE: 32,
    DIAMOND_PICKAXE: 1561,
    NETHERITE_PICKAXE: 2031,
  
    WOOD_SHOVEL: 59,
    STONE_SHOVEL: 131,
    IRON_SHOVEL: 250,
    GOLD_SHOVEL: 32,
    DIAMOND_SHOVEL: 1561,
    NETHERITE_SHOVEL: 2031,
  
    WOOD_SWORD: 59,
    STONE_SWORD: 131,
    IRON_SWORD: 250,
    GOLD_SWORD: 32,
    DIAMOND_SWORD: 1561,
    NETHERITE_SWORD: 2031,
  
    BOW: 384,
    CROSSBOW: 326,
    TRIDENT: 250,
    FISHING_ROD: 64,
    SHEARS: 238,
    FLINT_AND_STEEL: 64,
    SHIELD: 336,
  
    LEATHER_HELMET: 55,
    GOLD_HELMET: 77,
    CHAINMAIL_HELMET: 165,
    IRON_HELMET: 165,
    DIAMOND_HELMET: 363,
    NETHERITE_HELMET: 407,
    
    LEATHER_CHESTPLATE: 80,
    GOLD_CHESTPLATE: 112,
    CHAINMAIL_CHESTPLATE: 240,
    IRON_CHESTPLATE: 240,
    DIAMOND_CHESTPLATE: 528,
    NETHERITE_CHESTPLATE: 592,
    
    LEATHER_LEGGINGS: 75,
    GOLD_LEGGINGS: 105,
    CHAINMAIL_LEGGINGS: 225,
    IRON_LEGGINGS: 225,
    DIAMOND_LEGGINGS: 495,
    NETHERITE_LEGGINGS: 555,
  
    LEATHER_BOOTS: 65,
    GOLD_BOOTS: 91,
    CHAINMAIL_BOOTS: 195,
    IRON_BOOTS: 195,
    DIAMOND_BOOTS: 429,
    NETHERITE_BOOTS: 481,
  
    ELYTRA: 432,
  };





  function rollEnchantments(item) {
    const numEnchantmentWeights = [11, 5, 4, 3]; // More common for 1, less common for 2, even less for 3, rare for 4
  
    // Select the number of enchantments based on weights
    const numEnchantments = weightedRandomIndex(numEnchantmentWeights) + 1; // Add 1 to index to get 1 to 4 enchantments
    const result = [];
  
    for (let i = 0; i < numEnchantments; i++) {
      const availableEnchantments = item.types.filter((_, index) => !result.some(enchantment => enchantment.type === item.types[index]));
      if (availableEnchantments.length === 0) break; // If all enchantments have been chosen, stop rolling
  
      const chosenEnchantmentIndex = weightedRandomIndex(item.weight);
      const chosenEnchantment = availableEnchantments[chosenEnchantmentIndex];
      const chosenEnchantmentTierIndex = item.types.indexOf(chosenEnchantment);
      if (chosenEnchantmentTierIndex === -1) continue; // If chosen enchantment is not found in the types array, skip this iteration
  
      const tierOptions = item.tiers[chosenEnchantmentTierIndex];
      const chosenTier = tierOptions[Math.floor(Math.random() * tierOptions.length)]; // Randomly select a tier from the available options
  
      result.push({ type: chosenEnchantment, tier: chosenTier });
    }
  
    if (result == []){
      console.log("Error")
    }
    return result;
  }
  
  function weightedRandomIndex(weights) {
    const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);
    let random = Math.random() * totalWeight;
  
    for (let i = 0; i < weights.length; i++) {
      random -= weights[i];
      if (random <= 0) return i;
    }
  }
  
  const testEnchant = (id) => {
    const itemId = id;
    const item = enchantments[itemId];
    const rolledEnchantments = rollEnchantments(item);
  
  
    return rolledEnchantments;
  };
  const enchantments = {
    "110877487741203241378316780288804649491638371106195801780989302248319305746080": { // diamond sword
      types: ["Looting", "Sharpness", "Fire Aspect", "Unbreaking", "Mending", "Sweeping Edge", "Knockback"],
      tiers: [[1, 2, 3], [1, 2, 3, 4, 5], [1, 2], [1, 2, 3], [1], [1, 2, 3], [1, 2]],
      weight: [30, 40, 20, 40, 1, 15, 20]
    },
    "110877487741203241378316780288804649491638371106245427417693895602440696333984": { // iron sword
      types: ["Looting", "Sharpness", "Fire Aspect", "Unbreaking", "Mending", "Sweeping Edge", "Knockback"],
      tiers: [[1, 2, 3], [1, 2, 3, 4, 5], [1, 2], [1, 2, 3], [1], [1, 2, 3], [1, 2]],
      weight: [30, 40, 20, 40, 1, 15, 20]
    },
  
    "110877487741203241378316780288804649491638371106197066678510893770526806869664": { // diamond shovel
      types: ["Mending", "Silk Touch", "Unbreaking", "Fortune", "Efficiency"],
      tiers: [[1], [1], [1,2,3], [1,2,3], [1,2,3,4,5]],
      weight: [1, 2, 30, 20, 25]
    },
    "110877487741203241378316780288804649491638371106206063627023041762516051003040": { // iron shovel
      types: ["Mending", "Silk Touch", "Unbreaking", "Fortune", "Efficiency"],
      tiers: [[1], [1], [1,2,3], [1,2,3], [1,2,3,4,5]],
      weight: [1, 2, 30, 20, 25]
    },
  
    "110877487741203241378316780288804649491638371106223935928289006938081632290464": { // diamond hoe
      types: ["Mending", "Silk Touch", "Unbreaking", "Fortune", "Efficiency"],
      tiers: [[1], [1], [1,2,3], [1,2,3], [1,2,3,4,5]],
      weight: [1, 2, 30, 20, 25]
    },
    "110877487741203241378316780288804649491638371106228134747914334148623520073376": { // iron hoe
      types: ["Mending", "Silk Touch", "Unbreaking", "Fortune", "Efficiency"],
      tiers: [[1], [1], [1,2,3], [1,2,3], [1,2,3,4,5]],
      weight: [1, 2, 30, 20, 25]
    },
  
    "110877487741203241378316780288804649491638371106250828891765537665617806591648": { // diamond axe
      types: ["Mending", "Silk Touch", "Unbreaking", "Fortune", "Efficiency"],
      tiers: [[1], [1], [1,2,3], [1,2,3], [1,2,3,4,5]],
      weight: [1, 2, 30, 20, 25]
    },
    "110877487741203241378316780288804649491638371106260990790717925852622931199648": { // iron axe
      types: ["Mending", "Silk Touch", "Unbreaking", "Fortune", "Efficiency"],
      tiers: [[1], [1], [1,2,3], [1,2,3], [1,2,3,4,5]],
      weight: [1, 2, 30, 20, 25]
    },
  
    "110877487741203241378316780288804649491638371106250389938197783356694427960992": { // diamond pickaxe
      types: ["Mending", "Silk Touch", "Unbreaking", "Fortune", "Efficiency"],
      tiers: [[1], [1], [1,2,3], [1,2,3], [1,2,3,4,5]],
      weight: [1, 2, 30, 20, 25]
    },
    "110877487741203241378316780288804649491638371106225569039409006601648669296288": { // iron pickaxe
      types: ["Mending", "Silk Touch", "Unbreaking", "Fortune", "Efficiency"],
      tiers: [[1], [1], [1,2,3], [1,2,3], [1,2,3,4,5]],
      weight: [1, 2, 30, 20, 25]
    },
  
  
  
  
  
    "110877487741203241378316780288804649491638371106228930108792035130559472609040": { // diamond helm
      types: ["Mending", "Blast Protection", "Fire Protection", "Projectile Protection", "Protection", "Thorns"],
      tiers: [[1], [1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3]],
      weight: [1, 25, 25, 15, 45, 3]
    },
    "94951260369568964240529839402263118415859081572963568783321848582865745356560": { // iron helm
      types: ["Mending", "Blast Protection", "Fire Protection", "Projectile Protection", "Protection", "Thorns"],
      tiers: [[1], [1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3]],
      weight: [1, 25, 25, 15, 45, 3]
    },
  
    "110877487741203241378316780288804649491638371106221262446272289266495085618960": { // diamond chestplate
      types: ["Mending", "Blast Protection", "Fire Protection", "Projectile Protection", "Protection", "Thorns"],
      tiers: [[1], [1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3]],
      weight: [1, 25, 25, 15, 45, 3]
    },
    "94951260369568964240529839402263118415859081572954496973176712534863015585552": { // iron chestplate
      types: ["Mending", "Blast Protection", "Fire Protection", "Projectile Protection", "Protection", "Thorns"],
      tiers: [[1], [1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3]],
      weight: [1, 25, 25, 15, 45, 3]
    },
  
    "110877487741203241378316780288804649491638371106195159362052967485142873417488": { // diamond leggings
      types: ["Mending", "Blast Protection", "Fire Protection", "Projectile Protection", "Protection", "Thorns"],
      tiers: [[1], [1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3]],
      weight: [1, 25, 25, 15, 45, 3]
    },
    "94951260369568964240529839402263118415859081572960071151580569501919640299280": { // iron leggings
      types: ["Mending", "Blast Protection", "Fire Protection", "Projectile Protection", "Protection", "Thorns"],
      tiers: [[1], [1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3]],
      weight: [1, 25, 25, 15, 45, 3]
    },
  
    "110877487741203241378316780288804649491638371106247099082487040188744460150544": { // diamond boots
      types: ["Mending", "Blast Protection", "Feather Falling", "Fire Protection", "Projectile Protection", "Protection", "Thorns"],
      tiers: [[1], [1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3]],
      weight: [1, 15, 25, 25, 15, 45, 3]
    },
    "94951260369568964240529839402263118415859081572895297238309954850668819785488": { // iron boots
      types: ["Mending", "Blast Protection", "Fire Protection", "Projectile Protection", "Protection", "Thorns"],
      tiers: [[1], [1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3]],
      weight: [1, 15, 25, 25, 15, 45, 3]
    },
  
  
  
  };





  const handleOpenClanRequest = () => {
    setClanRequestPopup2(true);
  }




  const openCleanFromCharm = () => {
    setItemImage(charmImage);
    setItemEnchantments(charmEnchantments);
    setCleanEnchantments(charmEnchantments);
    setCharmEnchantments([]);
    
    setItemID(charmItemID);
  
    setItemShopID(charmItemID);
    setItemShopName(charmName);
    setItemShopEnchantments(charmEnchantments);
    setItemShopImage(charmImage);
    setItemShopMode(0);
    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setCleanPopup(true);
    setCharmMode(1);
  }
  




const openShopFromCharm = () => {
  setItemImage(charmImage);
  setItemEnchantments(charmEnchantments);

  setItemShopID(charmItemID);
  setItemShopName(charmName);
  setItemShopEnchantments(charmEnchantments);
  setItemShopImage(charmImage);
  setItemShopMode(0);
  setItemShopPopup(true);
  setCreateClanPopup(false);
  setClanRequestPopup(false);
  setClanRequestPopup2(false);
  
  setSpecialShopPopup(false);
  setCharmPopup(false);
  setAdminPopup(false);
  setModPopup(false);
  setSettingsPopup(false);
  setCleanPopup(false);
}

const SpecialList = ({ listings = {} }) => {
  const handleClick = (itemType, itemDetails, itemName, itemTitle, itemImage) => {
    console.log("Clicked item data:", itemType, itemDetails, itemName, itemTitle, itemImage);
    // Implement your click handler logic here
    setSpecialShopName(itemTitle);
    setSpecialShopCost(itemDetails.cost);
    setSpecialShopID(itemDetails.id);

    setItemImage(itemImage);
    setSpecialShopPopup(true);
  };

  let checkSumAccount = web3.utils.toChecksumAddress(account);





  
  const renderListing = (itemType, itemDetails, itemName, itemTitle, itemImage) => (

    

    <div
      key={itemType}
      className="auction-item"
      style={{
        backgroundImage: `url(${!screenMode ? "./Plank.png" : "./Plank.png"})`,
        backgroundSize: "auto",
        backgroundPosition: "center top",
        backgroundRepeat: "repeat",
      }}
    >
      <ItemImage name={itemName} />
      <div className="auction-top">{itemTitle}</div>

      <div className="inventory-text" style={{marginTop:"10%"}}>${itemDetails.cost}</div>
      <div className="inventory-buttons">
      <img
            src={colorblindMode ? "./smallBuy1.png" : "./smallbuy.png"}
            alt="transfer block"
            className="inventory-button-2"
            onClick={() =>
              handleClick(itemType, itemDetails, itemName, itemTitle, itemImage)
            }
          />
      </div>
    </div>
  );

  return (
    <div className="inventory-box">
      {Object.entries(listings).map(([itemType, itemDetails]) =>
        renderListing(itemType, itemDetails, parseNameOrTitle(itemType, 0), parseNameOrTitle(itemType, 1), `./Items/${parseNameOrTitle(itemType, 0).toLowerCase()}.png`)
      )}
    </div>
  );
};
const handleRemoveAndReplaceUnderscores = (input) => {
  const result = input.replace(/_/g, ' ');
  return result;
};
const parseNameOrTitle = (incoming, mode) => {
  let name = incoming
  let title = handleRemoveAndReplaceUnderscores(incoming.toLowerCase());
  if (incoming.includes("_SPAWNER")) {
    name = "SPAWNER";
    title = handleRemoveAndReplaceUnderscores(incoming.toLowerCase());
  } else if (incoming.includes("DEPOSITOR")) {
    name = "CHEST";
    title = "depositor"
  } else if (incoming.includes("SMITHING_TEMPLATE")) {
    name = "netherite_upgrade_smithing_template";
    title = handleRemoveAndReplaceUnderscores("SMITHING_TEMPLATE".toLowerCase());
  }

  if (mode == 0){
    return name;
  } else {
    return title;
  }

}


const ListingList = ({ listings = [] }) => {
  const handleClick = (item) => {
    console.log('Clicked item data:', item);
    // Implement your click handler logic here
  };

  let checkSumAccount = web3.utils.toChecksumAddress(account);
  const renderListing = (item) => (
    <div
      key={item.shop_item_id}
      className="auction-item"
      style={{
        backgroundImage: `url(${!screenMode ? "./Plank.png" : "./Plank.png"})`,
        backgroundSize: 'auto',
        backgroundPosition: 'center top',
        backgroundRepeat: 'repeat',
      }}
    >
      {/* Replace with your actual item image component */}
      <ItemImage name={item.item_type} />
      <div className="auction-top">${item.price}</div>
      <EnchantmentList2 itemEnchantments={item.enchantments} style={{position:"inherit"}} />

     
      <div className="inventory-text"></div>
      <div className="inventory-buttons">

        {
          item.eth_address == checkSumAccount ? (<img src={colorblindMode ? "./Unlist1.png" : "./Unlist.png"} alt="transfer block" className="unlist-button" onClick={() => unlistItem(item.item_id)} />) : (<img src={colorblindMode ? "./smallBuy1.png" : "./smallbuy.png"} alt="transfer block" className="inventory-button-2" onClick={() => openBuy(item.enchantments, item.item_id, `./Items/${item.item_type.toLowerCase()}.png`, item.price, item.item_type.toLowerCase())} />)
        }
        
      </div>
    </div>
  );

  return (
    <div className="inventory-box">
      {listings.map(item => renderListing(item))}
    </div>
  );
};


  const InventoryList = ({ tools = [], blocks = {} }) => {
    const handleSellClick = (item) => {
      console.log('SELL Clicked item data:', item);
      // Implement click handling logic here
      if (item.type === "block") {
        console.log("sell block");
       // openSend(false, 0, item.blockType, 1, item.blockAmount, [], "NONE");
       // const itemimg = `./Items/${item.blockType.toLowerCase()}.png`; // Adjust path if needed
        openShopFromWallet(item.blockType);
       // setItemImage(itemimg);
       // setItemID(0);
      } else {
        console.log("sell Item");
        //openSend(true, item.item_id, item.item_name, 1, 1, item.enchantments, item.name);
        setItemID(item.item_id);
        const itemimg = `./Items/${item.item_type.toLowerCase()}.png`; // Adjust path if needed
  

       
        if (item.enchantments.length == 0) {
          //charm

          setCharmMode(0);


          setCharmImage(itemimg);
          setCharmName(item.item_name);
          setCharmItemID(item.item_id);
          setCharmPopup(true);
          setSpecialShopPopup(false);
          setAdminPopup(false);
          setModPopup(false);
          setSettingsPopup(false);
          setCharmEnchantments([]);
        } else {
          //sell

          setItemImage(itemimg);
          setItemEnchantments(item.enchantments);
  
          setItemShopID(item.item_id);
          setItemShopName(item.item_name);
          setItemShopEnchantments(item.enchatments);
          setItemShopImage(itemimg);
          setItemShopMode(0);
          setItemShopPopup(true);
          setCreateClanPopup(false);
          setClanRequestPopup(false);
          setClanRequestPopup2(false);
          setCleanPopup(false);
        }

      }


    };


    const handleCleanClick = (item) => {
      console.log('CLEAN Clicked item data:', item);
      // Implement click handling logic here
      if (item.type === "block") {

      } else {
        console.log("clean Item");
        //openSend(true, item.item_id, item.item_name, 1, 1, item.enchantments, item.name);
        setItemID(item.item_id);
        const itemimg = `./Items/${item.item_type.toLowerCase()}.png`; // Adjust path if needed
  
        if (item.enchantments.length == 0) {
          //charm
          console.log("NO ENCHANTS");

        } else {
          //sell

          setCharmMode(1);


          const itemMaxDurability = itemDurabilityMapping[item.item_type];
          setItemMaxDurability(itemMaxDurability);

          if (item.durability == 0) {
            setItemDurability(itemMaxDurability);
          } else {
            setItemDurability(item.durability);
          }
          
          setCharmName(item.item_name);
          setCharmItemID(item.item_id);
          setCharmImage(itemimg);
          setCharmItemID(item.item_id);
          setCleanEnchantments(item.enchantments);
          setItemImage(itemimg);
          setItemEnchantments(item.enchantments);
          setCharmMode(1);
          setCharmEnchantments([]);
          setItemShopID(item.item_id);
          setItemShopName(item.item_name);
          setItemShopEnchantments(item.enchatments);
          setItemShopImage(itemimg);
          setItemShopMode(0);
          setItemShopPopup(false);
          setCreateClanPopup(false);
          setClanRequestPopup(false);
          setClanRequestPopup2(false);
          setCleanPopup(true);
          
        }

      }


    };


  
    const handleTransferClick = (item) => {
      console.log('TRANSFER Clicked item data:', item);
      
      // Determine whether it's a block or an item
      if (item.type === "block") {
        console.log("transfer block");
        setItemID(0);
        openSend(false, 0, item.blockType, 1, item.blockAmount, [], "NONE");
        const itemimg = `./Items/${item.blockType.toLowerCase()}.png`; // Adjust path if needed
    
        setItemImage(itemimg);
      } else {
        console.log("transfer Item");
        openSend(true, item.item_id, item.item_name, 1, 1, item.enchantments, item.name); // Example, replace with actual data
        setItemID(item.item_id);
        const itemimg = `./Items/${item.item_type.toLowerCase()}.png`; // Adjust path if needed
  
        setItemImage(itemimg);

        setItemEnchantments(item.enchantments);
      }
    
      // Set the itemImage dynamically based on item.blockType

    };
  
    const renderBlock = (blockType, blockAmount) => {

      
      if (blockAmount === 0) {
        return null; // Do not render if blockAmount is 0
      }
  
      return (
        <div
          key={blockType}
          className="inventory-item"
          style={{
            backgroundImage: `url(${!screenMode ? "./Plank.png" : "./Plank.png"})`,
            backgroundSize: 'auto',
            backgroundPosition: 'center top',
            backgroundRepeat: 'repeat',
          }}
        >
          <ItemImage name={blockType} />
          <div className="inventory-top">{blockAmount}x</div>
          <div className="inventory-text">{blockType}</div>
  
          <div className="inventory-buttons">
            <div><img src={colorblindMode ? "./sell1.png" : "./sell.png"} alt="sell block" className="inventory-button-1" onClick={() => handleSellClick({ type: 'block', blockType, blockAmount })} />
            </div>
            <div> <img src={colorblindMode ? "./Transfer1.png" : "./Transfer.png"} alt="transfer block" className="inventory-button-1" onClick={() => handleTransferClick({ type: 'block', blockType, blockAmount })} /></div>
           
          </div>
        </div>
      );
    };
  
    const renderTool = (tool) => (
      <div
        key={tool.item_id}
        className="inventory-item"
        style={{
          backgroundImage: `url(${!screenMode ? "./Plank.png" : "./Plank.png"})`,
          backgroundSize: 'auto',
          backgroundPosition: 'center top',
          backgroundRepeat: 'repeat',
        }}
      >
        <ItemImage name={tool.item_type} />
        <div className="inventory-top"></div>
        
        {
          tool.enchantments.length == 0 ? (<div className="inventory-text">{tool.item_name !== "" ? tool.item_name : tool.item_type}</div>) : (          <div className="inventory-text"><EnchantmentList2 itemEnchantments={tool.enchantments} style={{position:"inherit"}} /></div>
          )
        }
        
        {
          tool.enchantments.length == 0 ? (


          <div className="inventory-buttons3">
          <div>
          <img src={colorblindMode ? "./Enchant1.png" : "./Enchant.png"} alt="sell block" className="inventory-button-3" style={{width:"100%"}} onClick={() => handleSellClick({ type: 'item', item_id: tool.item_id, name: tool.item_name, item_type: tool.item_type, enchantments: tool.enchantments, durability: tool.durability })} />
          </div>
          <div>
          <img src={colorblindMode ? "./Transfer1.png" : "./Transfer.png"} alt="transfer block" className="inventory-button-3" style={{width:"75%" , marginTop: "0.5%"}} onClick={() => handleTransferClick({ type: 'item', item_id: tool.item_id, name: tool.item_name, item_type: tool.item_type, enchantments: tool.enchantments, durability: tool.durability })} />


          </div>
          
        </div>


         ) : (

            <div className="inventory-buttons2">

          <div>
          <img src={colorblindMode ? "./sell1.png" : "./sell.png"} alt="sell block" className="inventory-button-1" style={{width:"150%", marginLeft: "-600%"}} onClick={() => handleSellClick({ type: 'item', item_id: tool.item_id, name: tool.item_name, item_type: tool.item_type, enchantments: tool.enchantments, durability: tool.durability })} />
          </div>
          <div>
          <img src={colorblindMode ? "./edit1.png" : "./edit.png"} alt="sell block" className="inventory-button-1" style={{width:"150%", marginLeft: "-500%"}} onClick={() => handleCleanClick({ type: 'item', item_id: tool.item_id, name: tool.item_name, item_type: tool.item_type, enchantments: tool.enchantments, durability: tool.durability })} />
          </div>
          <div>
          <img src={colorblindMode ? "./Transfer1.png" : "./Transfer.png"} alt="transfer block" className="inventory-button-1" style={{width:"150%", marginLeft: "-100%"}} onClick={() => handleTransferClick({ type: 'item', item_id: tool.item_id, name: tool.item_name, item_type: tool.item_type, enchantments: tool.enchantments, durability: tool.durability })} />


          </div>
          
        </div>

            
         )
        }

      </div>
    );
  
    return (
      <div className="inventory-box">
        {/* Render blocks if it's an object */}
        {Object.entries(blocks).map(([blockType, blockAmount]) => renderBlock(blockType, blockAmount))}
  
        {/* Render tools if it's an array */}
        {tools.map(tool => renderTool(tool))}
      </div>
    );
  };
  

  



  const openShopFromWallet = (curveName) => {
    const data = curveData.find(curve => curve.item_id === curveName);
  
    if (data != null) {
      console.log('BONDING CURVE Clicked item data:', data);


  
      // Uncomment these lines if needed
      // setShopMode(0);
      // setBondingCurveMode(0);
  
      // Check if data.name exists before calling toLowerCase()
      const lowercaseName = curveName ? curveName.toLowerCase() : '';
  
      // Implement click handling logic here
      const imageUrl = `${process.env.PUBLIC_URL}/Items/${lowercaseName}.png`;
  
      if (blocksData.hasOwnProperty(curveName)) {
        const blockAmount = blocksData[curveName];
        const usdValue = ((blockAmount * data.price) * tokenPrice).toFixed(5);
        console.log("WE OWN " + blockAmount + " = $" + usdValue);
        console.log("Pool Info: Tokens:" + data.reserve_tokens + " / Blocks:" + data.reserve_blocks);
        setCurveBalance(blockAmount);
        setCurveBalanceUSD(usdValue);
        // Implement further logic here using blockAmount
      } else {
        setCurveBalance(0);
        setCurveBalanceUSD(0);
        console.log("BLOCK AMOUNT IS not available for " + curveName);
      }
  
      setBondingCurveImage(imageUrl);
      setBondingCurvePrice(data.price);
      setBondingCurveName(curveName);
      setBondingCurveTokens(data.reserve_tokens);
      setBondingCurveBlocks(data.reserve_blocks);
      setBondingCurveMode(1);
  
      setCurvePopup(true);
    } else {
      console.error(`No data found for curveName: ${curveName}`);
    }
  };
  
  const ShopList = ({ curves = {} }) => {
    const handleBondingCurveClick = (data) => {
      console.log('BONDING CURVE Clicked item data:', data);

      console.log("PRICE IS " + data.price);
      console.log("NAME IS " + data.name);

     // setShopMode(0);
     // setBondingCurveMode(0);
      const lowercaseName = data.name.toLowerCase();
      // Implement click handling logic here
      const imageUrl = `${process.env.PUBLIC_URL}/Items/${lowercaseName}.png`;
   
      if (blocksData.hasOwnProperty(data.name)) {
        const blockAmount = blocksData[data.name];
        const usdValue = ((blockAmount * data.price) * tokenPrice).toFixed(5);
        console.log("WE OWN " + blockAmount + " = $" + usdValue);
        console.log("Pool Info: Tokens:" + data.tokens + " / Blocks:" + data.blocks);
        setCurveBalance(blockAmount);
        setCurveBalanceUSD(usdValue);
        // Implement further logic here using blockAmount
      } else {
        setCurveBalance(0);
        setCurveBalanceUSD(0);
        console.log("BLOCK AMOUNT IS not available for " + data.name);
      }


      setBondingCurveImage(imageUrl);
      setBondingCurvePrice(data.price);
      setBondingCurveName(data.name);
      setBondingCurveTokens(data.tokens);
      setBondingCurveBlocks(data.blocks);
      
      setCurvePopup(true);


      //blockAmount is price of 1 token with 0 tax


    };
    

    const renderPreview = (blockType, blockAmount, tokens, blocks) => {
      if (blockAmount === 0) {
        return null; // Do not render if blockAmount is 0
      }
  
      return (
        <div
          key={blockType}
          className="shop-item"
          style={{
            backgroundImage: "url(/Plank.png)",
            backgroundSize: 'auto',
            backgroundPosition: 'center top',
            backgroundRepeat: 'repeat',
          }}
          onClick={() => handleBondingCurveClick({ name: blockType, price: blockAmount, tokens: tokens, blocks: blocks })}
        >
          <ShopImage name={blockType} type={blockType} amount={blockAmount} />

        </div>
      );
    };
  
    return (
      <div className="inventory-box">
        {/* Render blocks if it's an object */}
        {curves.map((curve) => renderPreview(curve.item_id, curve.price, curve.reserve_tokens, curve.reserve_blocks))}
      </div>
    );
  };
  
  const handleAccept = async (enemy) => {

    var time = getTimestamp();

    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }

    
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }



    console.log("Dueling " + enemy);
    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/accept`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature, 
              expectedAddress: account,
              enemyAddress: enemy,
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('Duel Accepted');
            handleRefresh(account);
            // setSignPassed(true)
          } else {
            console.error('Duel Failed');
            //fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else  {
      console.log("cannot do this for 1s");
    }

  };
  const handleBondingChange = (event) => {
    // Remove non-numeric and non-decimal characters using a regular expression
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
  
    // Ensure there is only one decimal point
    const parts = sanitizedValue.split('.');
    const decimalPart = parts.length > 1 ? `.${parts.pop()}` : '';
  
    // Limit the decimal part to three digits
    const limitedDecimalPart = decimalPart.slice(0, 5);
    const finalValue = parts.join('') + limitedDecimalPart;
  
    // Update the amountIn state
    setBondingCurveInput(finalValue);
  

  };

  const handleItemShopChange = (event) => {
    // Remove non-numeric and non-decimal characters using a regular expression
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
  
    // Ensure there is only one decimal point
    const parts = sanitizedValue.split('.');
    const decimalPart = parts.length > 1 ? `.${parts.pop()}` : '';
  
    // Limit the decimal part to three digits
    const limitedDecimalPart = decimalPart.slice(0, 5);
    const finalValue = parts.join('') + limitedDecimalPart;
  
    // Update the amountIn state
    setItemShopInput(finalValue);
  };
  const handleInputChangeIn = (event) => {
    // Remove non-numeric and non-decimal characters using a regular expression
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
  
    // Ensure there is only one decimal point
    const parts = sanitizedValue.split('.');
    const decimalPart = parts.length > 1 ? `.${parts.pop()}` : '';
  
    // Limit the decimal part to three digits
    const limitedDecimalPart = decimalPart.slice(0, 5);
    const finalValue = parts.join('') + limitedDecimalPart;
  
    // Update the amountIn state
    setAmountIn(finalValue);
  
    // Use the sanitized value for calculations
    const dollars = (finalValue * 10 ** 18) / ethToUSD;
    const numberOfCoins = dollars / tokenPrice;
  
    // Update the amountOut state
    setAmountOut(numberOfCoins);
  };
  const handleInputChangeOut = (event) => {
    // Remove non-numeric and non-decimal characters using a regular expression
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
  
    // Ensure there is only one decimal point
    const parts = sanitizedValue.split('.');
    const decimalPart = parts.length > 1 ? `.${parts.pop()}` : '';
  
    // Limit the decimal part to three digits
    const limitedDecimalPart = decimalPart.slice(0, 5);
    const finalValue = parts.join('') + limitedDecimalPart;
  
    // Update the amountIn state
    setAmountIn(finalValue);
  
    // Use the sanitized value for calculations
    const dollars = finalValue * tokenPrice;
    const numberOfCoins = dollars * ethToUSD;
  
    const fixedOutput = numberOfCoins / 10 ** 18;
    // Update the amountOut state
    setAmountOut(fixedOutput);
  };

  const handleSendChange = (event) => {
    // Remove non-numeric and non-decimal characters using a regular expression
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
  
    // Ensure there is only one decimal point
    const parts = sanitizedValue.split('.');
    const decimalPart = parts.length > 1 ? `.${parts.pop()}` : '';
  
    // Limit the decimal part to three digits
    const limitedDecimalPart = decimalPart.slice(0, 5);
    const finalValue = parts.join('') + limitedDecimalPart;
  
    // Update the amountIn state
    setItemAmount(finalValue);
  

  };

  const setMaxSend = (maxValue) => {
    setItemAmount(maxValue);

  }
  const setMaxValueOut = (maxValue) => {
    setAmountIn(maxValue);


      
    // Use the sanitized value for calculations
    const dollars = maxValue * tokenPrice;
    const numberOfCoins = dollars * ethToUSD;
  
    const fixedOutput = numberOfCoins / 10 ** 18;
    // Update the amountOut state
    setAmountOut(fixedOutput);
  }
  const setMaxValueIn = (maxValue) => {
    setAmountIn(maxValue);


      
    // Use the sanitized value for calculations
    const dollars = (maxValue * 10 ** 18) / ethToUSD;
    const numberOfCoins = dollars / tokenPrice;
  
    // Update the amountOut state
    setAmountOut(numberOfCoins);
  }


  const pingNewServerTransaction = () => {
    const nowInMilliseconds = Date.now(); // Get current time in milliseconds
const nowInSeconds = Math.floor(nowInMilliseconds / 1000);


    lastServerTransactionTime = nowInSeconds;
  }
  const openTransaction = () => {
    newPopup("You sent a transaction!", "You can view it by clicking open.", "./LoginSuccess.png", "Open", 420);
  }

  const handleInfoButton = () => {

    if (mainPopupButton == 0) {
    } else if (mainPopupButton == 1) {
      setPage("Shop");
    } else if (mainPopupButton == 420) {
      window.open("https://basescan.org/tx/" + savedTransactionHash, '_blank'); // Open the URL in a new tab
    }

    setMainPopup(false);
    setTransferPopup(false);
    setMainPopupButton(0);
    setSavedTransactionHash(null);
    setSellPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCleanPopup(false);

  }
  const closePopup = () => {
    setTransferPopup(false);
    setMainPopup(false);
    setMainPopupButton(0);
    setSavedTransactionHash(null);
    setCreatePopup(false);
    setSellPopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);

    setClanMemberPopup(false);
    setClanMemberPopupMod(false);

    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    //setCharmMode(0);
    setBuyPopup(false);
    setCleanPopup(false);
  }
  const openBondingCurve = () => {
    setMainPopup(false);
    setSellPopup(false);
    setTransferPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(true);
    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCleanPopup(false);
  }
  const openLink = () => {
    setMainPopup(false);
    setSellPopup(false);
    setTransferPopup(false);
    setCreatePopup(false);
    setLinkPopup(true);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCleanPopup(false);
  }
  const openSell = () => {
    setMainPopup(false);
    setSellPopup(true);
    setTransferPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCleanPopup(false);
  }
  const openTransfer = () => {
    setMainPopup(false);
    setTransferPopup(true);
    setSellPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);

    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCleanPopup(false);
  }

  const openCreate = () => {
    setMainPopup(false);
    setTransferPopup(false);
    setSellPopup(false);
    setCreatePopup(true);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCleanPopup(false);
  }
  const openClean = () => {
    setMainPopup(false);
    setTransferPopup(false);
    setSellPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCharmMode(1);
    setCleanPopup(true);
  }
  const openAdmin = () => {
    setMainPopup(false);
    setTransferPopup(false);
    setSellPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(true);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCleanPopup(false);
  }
  const openMod = () => {
    setMainPopup(false);
    setTransferPopup(false);
    setSellPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCharmPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(true);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCleanPopup(false);
  }

  const openCreateClan = () => {
    setMainPopup(false);
    setTransferPopup(false);
    setSellPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCreateClanPopup(true);

    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCleanPopup(false);
  }
  const openSettings = () => {
    setMainPopup(false);
    setTransferPopup(false);
    setSellPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);

    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(true);
    setBuyPopup(false);
    setCleanPopup(false);
  }
  const openRankup = () => {
    setMainPopup(false);
    setTransferPopup(false);
    setSellPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(true);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setCharmPopup(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCleanPopup(false);
  }
  const openBuy = (enchantments, itemID, image, price, passedName) => {


    // return123

    setItemName(passedName);


    setMainPopup(false);
    setTransferPopup(false);
    setSellPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(false);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCharmPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(true);
    setCleanPopup(false);
    
    setBuyPrice(price);
    setBuyID(itemID);
    setBuyEnchantments(enchantments);
    setBuyImage(image);
  }
  const openSend = (isItem, itemID, blockID, blockAmount, ourBalance, enchantments, itemName) => {
    setMainPopup(false);
    setTransferPopup(false);
    setSellPopup(false);
    setCreatePopup(false);
    setLinkPopup(false);
    setSendPopup(true);
    setRankupPopup(false);
    setCurvePopup(false);
    setItemShopPopup(false);
    setCharmPopup(false);
    setCreateClanPopup(false);
    setClanRequestPopup(false);
    setClanRequestPopup2(false);
    setSpecialShopPopup(false);
    setAdminPopup(false);
    setModPopup(false);
    setSettingsPopup(false);
    setBuyPopup(false);
    setCleanPopup(false);
    setItemEnchantments(enchantments);
    setItemName(itemName);
    setItemID(itemID);
    setItemMaxBalance(ourBalance);
    setBlockID(blockID);
    setBlockAmount(blockAmount);
    if (isItem == true) {
      setSendItem(true);
      

    } else {
      setSendItem(false);

    }
    

    
  }
  
  const changeSwap = () => {
    if (swapMode == 0) {
      setSwapMode(1);

    } else {
      setSwapMode(0);
    }
  }




  const newPopup = (title, desc, path, buttonText, button) => {

    setMainPopupDescription(desc);
    setMainPopupPath(path);
    setMainPopupTitle(title);
    setMainPopupButton(button);
    setMainPopupButtonText(buttonText);


    setMainPopup(true);
  }


  function getTimestamp() {
    return Date.now()
  }


  const fetchGasPrice = async (web3) => {
    try {
      const gasPrice = await web3.eth.getGasPrice();
      console.log('Gas Price:', gasPrice);
      return gasPrice;
    } catch (error) {
      console.error('Error fetching gas price:', error);
    }
  };

  const withdrawRequest = async () => {

    console.log("withdraw request 1");
    console.log(provider);
    console.log(web3);
    if (!web3 && chainId == TARGET_CHAIN_ID) {
      console.error('Web3 not initialized');
      return;
    }

    if (walletMode == 0) {
      try {
        console.log("withdraw request 2");

          
        const bridgeInstance = new web3.eth.Contract(bridgeABI, bridgeAddress);
      
        if (bridgeInstance) {
          console.log("withdraw request 3");
            let amount = BigNumber(bridgeInput*10**18);
    
        //  console.log(`${me} wants to bridge ${tokenId} x ${amount} to ${serverAddress}`);

            let gasPrice = await web3.eth.getGasPrice();
            const fee = 210000 * gasPrice;

            console.log("FEE IS " + fee);
            try {
              // Estimate Gas Limit
              const gasLimit = await bridgeInstance.methods.withdraw(amount).estimateGas({
                from: account,
                value: fee.toString(),
                gasPrice: gasPrice.toString(), // Convert back to string, as web3 requires string for gasPrice
              });
            
              // Calculate Gas with a Buffer
              let gasCalc = Math.floor(parseInt(gasLimit) * 1.1);
              console.log("Estimated Gas Limit with Buffer:", gasCalc);
            
              // Send Transaction
              console.log("Sending Transaction...");
              const receipt = await bridgeInstance.methods.withdraw(amount).send({
                from: account,
                value: fee.toString(),
                gas: gasCalc.toString(),
                gasPrice: gasPrice.toString(), // Include gas price here
              });
            
              console.log("Transaction Receipt:", receipt);
            } catch (error) {
              console.error("Error in transaction:", error);
            }
            
          // setNoteMemo("Your transaction was sent!");
           //setNoteBody(`Your withdraw was sent.`);
           
          // pingNotification();
    
        }


      } catch (e) {
        console.log(e);
      }
    } else {
      if (provider){
        try {


          const smartWeb3 = new Web3(provider);
          const bridgeInstance = new smartWeb3.eth.Contract(bridgeABI, bridgeAddress);
      
          if (bridgeInstance) {
      
              let amount = bridgeInput*10**18;
      
          //  console.log(`${me} wants to bridge ${tokenId} x ${amount} to ${serverAddress}`);
      
      
              const gasPrice = await web3.eth.getGasPrice();
              const fastGasPrice = Math.floor(parseInt(gasPrice)*1.275); 
              const fee = 210000 * fastGasPrice;
              const gasLimit = await bridgeInstance.methods.withdraw(amount).estimateGas({
                from: smartWalletAccount,
                value: fee.toString(),
                gasPrice: fastGasPrice.toString(), // Convert back to string, as web3 requires string for gasPrice
              });
              let gasCalc = Math.floor(parseInt(gasLimit) * 1.1);
              await bridgeInstance.methods.withdraw(amount).send({
                from: smartWalletAccount,
                value: fee.toString(),
                gas: gasCalc.toString(),
                gasPrice: fastGasPrice.toString(), // Include gas price here
              });
      
      
              console.log('Transaction sent successfully');
              
            // setNoteMemo("Your transaction was sent!");
             //setNoteBody(`Your withdraw was sent.`);
             
            // pingNotification();
      
          }

        } catch (e) {
          console.log(e);
        }
      } else {
        console.log("no provider");
      }
    }

  }

  function EnchantmentList2({ itemEnchantments }) {
    // Function to format enchantments into a readable string
    const formatEnchantments = () => {
      return Object.keys(itemEnchantments).map(key => (
          <div key={key}>
<span>{itemEnchantments[key].enchantment_name}: {itemEnchantments[key].enchantment_level}</span>
          </div>
      ));
  };

  return (
      <div className="enchant-box" style={{
          backgroundImage: "url(/invis.png)",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: "75vw",
          height: "80%",
          top: "5%",
          left: "0%",
          padding: "10px",

          borderRadius: "5px",
          backgroundColor: "var(--invis)",
      }}>
          <div>{formatEnchantments()}</div>
      </div>
  );
}

  function EnchantmentList({ itemEnchantments }) {
    // Function to format enchantments into a readable string
    const formatEnchantments = () => {
      return Object.keys(itemEnchantments).map(key => (
          <div key={key}>
<span>{itemEnchantments[key].enchantment_name}: {itemEnchantments[key].enchantment_level}</span>
          </div>
      ));
  };

  return (
      <div className="wallet-box-info" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: "60%",
          height: "20%",
          top: "15%",
          left: "20%",
          padding: "10px",

          borderRadius: "5px",
          backgroundColor: "#f2f2f2",
      }}>
          <div>{formatEnchantments()}</div>
      </div>
  );
}
  const swapTokenToETH = async () => {
    if (!web3) {
      console.error('Web3 not initialized');
      return;
    }
    let localChain = null;
    if (chainSave != null) {
      localChain = chainSave;
    } else if (chainId) {
      localChain = chainId;
    }
    if (localChain !== TARGET_CHAIN_ID) {
      console.log("local: " + localChain + " target: " + TARGET_CHAIN_ID);
      console.error('Wrong Blockchain');
      await switchToTargetChain(provider);
      return;
    }

    if (walletMode == 0) {
      try {
        const swapperInstance = new web3.eth.Contract(swapperABI, swapperAddress);
    

        const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
        let swapperInstanceAlchemy = new web3alchemy.eth.Contract(swapperABI, swapperAddress);
        if (swapperInstance && swapperInstanceAlchemy) {
    
            let amount = amountIn*10**18;
    
        //  console.log(`${me} wants to bridge ${tokenId} x ${amount} to ${serverAddress}`);

        const gasLimit = await swapperInstanceAlchemy.methods.swapTokenToEth(amount, 22).estimateGas({
          from: account,
          value: "0",
      });
      
      let gasCalc = Math.floor(parseInt(gasLimit) * 1.1);
      
      let transactionParams = {
          from: account,
          value: "0",
          gas: gasCalc.toString(),
      };
      
      // Check if the network supports EIP-1559
      const isEIP1559Supported = await web3alchemy.eth.getBlock('latest').then(block => block.baseFeePerGas !== undefined);
      
      if (isEIP1559Supported) {
          transactionParams.maxPriorityFeePerGas = web3.utils.toWei('0.005', 'gwei');
          transactionParams.maxFeePerGas = web3.utils.toWei('0.3', 'gwei');
      } else {
          transactionParams.gasPrice = localGasPrice.toString();
      }
      
      await swapperInstance.methods.swapTokenToEth(amount, 22).send(transactionParams);
           // console.log('Transaction sent successfully');
            
          // setNoteMemo("Your transaction was sent!");
           //setNoteBody(`Your withdraw was sent.`);
           
          // pingNotification();
    
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      if (provider) {
        try {
          const smartWeb3 = new Web3(provider);
          const swapperInstance = new smartWeb3.eth.Contract(swapperABI, swapperAddress);
          const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
          let swapperInstanceAlchemy = new web3alchemy.eth.Contract(swapperABI, swapperAddress);
          if (swapperInstance) {
      
              let amount = amountIn*10**18;
      
          //  console.log(`${me} wants to bridge ${tokenId} x ${amount} to ${serverAddress}`);
          const gasLimit = await swapperInstanceAlchemy.methods.swapTokenToEth(amount, 22).estimateGas({
            from: smartWalletAccount,
            value: "0",
        });
        
        let gasCalc = Math.floor(parseInt(gasLimit) * 1.1);
        
        let transactionParams = {
            from: smartWalletAccount,
            value: "0",
            gas: gasCalc.toString(),
        };
        
        // Check if the network supports EIP-1559
        const isEIP1559Supported = await web3alchemy.eth.getBlock('latest').then(block => block.baseFeePerGas !== undefined);
        
        if (isEIP1559Supported) {
            transactionParams.maxPriorityFeePerGas = web3.utils.toWei('0.005', 'gwei');
            transactionParams.maxFeePerGas = web3.utils.toWei('0.3', 'gwei');
        } else {
            transactionParams.gasPrice = localGasPrice.toString();
        }
        
        await swapperInstance.methods.swapTokenToEth(amount, 22).send(transactionParams);
             // console.log('Transaction sent successfully');
              
            // setNoteMemo("Your transaction was sent!");
             //setNoteBody(`Your withdraw was sent.`);
             
            // pingNotification();
      
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log("no provider");
      }
    }

  }



  
  const swapETHtoToken = async () => {
    if (!web3) {
      console.error('Web3 not initialized');
      return;
    }
    let localChain = null;
    if (chainSave != null) {
      localChain = chainSave;
    } else if (chainId) {
      localChain = chainId;
    }
    if (localChain !== TARGET_CHAIN_ID) {
      console.log("local: " + localChain + " target: " + TARGET_CHAIN_ID);
      console.error('Wrong Blockchain');
      await switchToTargetChain(provider);
      return;
    }
    if (walletMode == 0) {
      try {
        const swapperInstance = new web3.eth.Contract(swapperABI, swapperAddress);
        const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
        let swapperInstanceAlchemy = new web3alchemy.eth.Contract(swapperABI, swapperAddress);
        if (swapperInstance && swapperInstanceAlchemy) {
    
            let amount = amountIn*10**18;
    
            let fee = amount;
        //  console.log(`${me} wants to bridge ${tokenId} x ${amount} to ${serverAddress}`);

        const gasLimit = await swapperInstanceAlchemy.methods.swapEthToToken(amount, 22).estimateGas({
          from: account,
          value: fee.toString(),
      });
      
      let gasCalc = Math.floor(parseInt(gasLimit) * 1.1);
      
      let transactionParams = {
          from: account,
          value: fee.toString(),
          gas: gasCalc.toString(),
      };
      
      // Check if the network supports EIP-1559
      const isEIP1559Supported = await web3alchemy.eth.getBlock('latest').then(block => block.baseFeePerGas !== undefined);
      
      if (isEIP1559Supported) {
          transactionParams.maxPriorityFeePerGas = web3.utils.toWei('0.005', 'gwei');
          transactionParams.maxFeePerGas = web3.utils.toWei('0.3', 'gwei');
      } else {
          transactionParams.gasPrice = localGasPrice.toString();
      }
      
      await swapperInstance.methods.swapEthToToken(amount, 22).send(transactionParams);
           // console.log('Transaction sent successfully');
            
          // setNoteMemo("Your transaction was sent!");
           //setNoteBody(`Your withdraw was sent.`);
           
          // pingNotification();
    
        }
      } catch (e) {
        console.log(e);
      }
    } else  {
            // smart wallet
            if (provider) {
              try {
                const smartWeb3 = new Web3(provider);
                const swapperInstance = new smartWeb3.eth.Contract(swapperABI, swapperAddress);
                const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
                let swapperInstanceAlchemy = new web3alchemy.eth.Contract(swapperABI, swapperAddress);
        if (swapperInstance && swapperInstanceAlchemy) {
    
            let amount = amountIn*10**18;
    
        //  console.log(`${me} wants to bridge ${tokenId} x ${amount} to ${serverAddress}`);

            let fee = amount;
            const gasLimit = await swapperInstanceAlchemy.methods.swapEthToToken(amount, 22).estimateGas({
              from: smartWalletAccount,
              value: fee.toString(),
          });
          
          let gasCalc = Math.floor(parseInt(gasLimit) * 1.1);
          
          let transactionParams = {
              from: smartWalletAccount,
              value: fee.toString(),
              gas: gasCalc.toString(),
          };
          
          // Check if the network supports EIP-1559
          const isEIP1559Supported = await web3alchemy.eth.getBlock('latest').then(block => block.baseFeePerGas !== undefined);
          
          if (isEIP1559Supported) {
              transactionParams.maxPriorityFeePerGas = web3.utils.toWei('0.005', 'gwei');
              transactionParams.maxFeePerGas = web3.utils.toWei('0.3', 'gwei');
          } else {
              transactionParams.gasPrice = localGasPrice.toString();
          }
          
          await swapperInstance.methods.swapEthToToken(amount, 22).send(transactionParams);
           // console.log('Transaction sent successfully');
            
          // setNoteMemo("Your transaction was sent!");
           //setNoteBody(`Your withdraw was sent.`);
           
          // pingNotification();
    
        }
              } catch (e) {
                console.log(e);
              }
            } else {
              console("No proivder");
            }
    }
 
  }





  const approveSwap = async () => {
    if (!web3) {
      console.error('Web3 not initialized');
      return;
    }
    let localChain = null;
    if (chainSave != null) {
      localChain = chainSave;
    } else if (chainId) {
      localChain = chainId;
    }
    if (localChain !== TARGET_CHAIN_ID) {
      console.log("local: " + localChain + " target: " + TARGET_CHAIN_ID);
      console.error('Wrong Blockchain');
      await switchToTargetChain(provider);
      return;
    }
    if (walletMode == 0) {
      //injected wallet
      try {
        const instance = new web3.eth.Contract(basecraftABI, basecraftAddress);
    

        const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
        let instanceAlchemy = new web3alchemy.eth.Contract(basecraftABI, basecraftAddress);
        if (instance && instanceAlchemy) {
    

            const gasLimit = await instanceAlchemy.methods.approve(swapperAddress, (10000000*(10**18))).estimateGas({
              from: account,
              value: "0",
              gasPrice: localGasPrice.toString(), // Convert back to string, as web3 requires string for gasPrice
            });
            let gasCalc = Math.floor(parseInt(gasLimit)*1.1);
            await instance.methods.approve(swapperAddress, (10000000*(10**18))).send({
              from: account,
              gas: gasCalc.toString(),
              gasPrice: localGasPrice.toString(), // Include gas price here
            });
           // console.log('Transaction sent successfully');
           setApprovedTokensSwap((10000000*(10**18)));
    
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      // smart wallet
      if (provider) {
        try {
          const smartWeb3 = new Web3(provider);
          const instance = new smartWeb3.eth.Contract(basecraftABI, basecraftAddress);
          const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
          let instanceAlchemy = new web3alchemy.eth.Contract(basecraftABI, basecraftAddress);
          if (instance && instanceAlchemy) {
      

              const gasLimit = await instanceAlchemy.methods.approve(swapperAddress, (10000000*(10**18))).estimateGas({
                from: smartWalletAccount,
                value: "0",
                gasPrice: localGasPrice.toString(), // Convert back to string, as web3 requires string for gasPrice
              });
              let gasCalc = Math.floor(parseInt(gasLimit)*1.1);
              await instance.methods.approve(swapperAddress, (10000000*(10**18))).send({
                from: smartWalletAccount,
                gas: gasCalc.toString(),
                gasPrice: localGasPrice.toString(), // Include gas price here
              });
             // console.log('Transaction sent successfully');
             setApprovedTokensSwap((10000000*(10**18)));
      
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        console("No proivder");
      }

    }

  }



  const approveBridge = async () => {
    if (!web3) {
      console.error('Web3 not initialized');
      return;
    }
    let localChain = null;
    if (chainSave != null) {
      localChain = chainSave;
    } else if (chainId) {
      localChain = chainId;
    }
    if (localChain !== TARGET_CHAIN_ID) {
      console.log("local: " + localChain + " target: " + TARGET_CHAIN_ID);
      console.error('Wrong Blockchain');
      await switchToTargetChain(provider);
      return;
    }

    if (walletMode == 0) {
      try {
        const instance = new web3.eth.Contract(basecraftABI, basecraftAddress);
        const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
        let instanceAlchemy = new web3alchemy.eth.Contract(basecraftABI, basecraftAddress);
        if (instance && instanceAlchemy) {
    
    
    

            const gasLimit = await instance.methods.approve(bridgeAddress, (10000000*(10**18))).estimateGas({
              from: account,
              value: "0",
              gasPrice: localGasPrice.toString(), // Convert back to string, as web3 requires string for gasPrice
            });
            let gasCalc = Math.floor(parseInt(gasLimit)*1.1);
            await instance.methods.approve(bridgeAddress, (10000000*(10**18))).send({
              from: account,
              gas: gasCalc.toString(),
              gasPrice: localGasPrice.toString(), // Include gas price here
            });
           // console.log('Transaction sent successfully');
           setApprovedTokensBridge((10000000*(10**18)));
    
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      if (provider) {
        try {
          const smartWeb3 = new Web3(provider);
          const instance = new smartWeb3.eth.Contract(basecraftABI, basecraftAddress);
          const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
          let instanceAlchemy = new web3alchemy.eth.Contract(basecraftABI, basecraftAddress);
          if (instance && instanceAlchemy) {
      

              const gasLimit = await instanceAlchemy.methods.approve(bridgeAddress, (10000000*(10**18))).estimateGas({
                from: smartWalletAccount,
                value: "0",
                gasPrice: localGasPrice.toString(), // Convert back to string, as web3 requires string for gasPrice
              });
              let gasCalc = Math.floor(parseInt(gasLimit)*1.1);
              await instance.methods.approve(bridgeAddress, (10000000*(10**18))).send({
                from: smartWalletAccount,
                gas: gasCalc.toString(),
                gasPrice: localGasPrice.toString(), // Include gas price here
              });
             // console.log('Transaction sent successfully');
             setApprovedTokensBridge((10000000*(10**18)));
      
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log("no provider");
      }
    }

  }






  const handleBridge = async () => {
   // console.log(`server is ${online}`);

    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      
      if (bridgeInput >= 1 && approvedTokensBridge >= bridgeInput*10**18) {
        
        if (!web3 && chainId == TARGET_CHAIN_ID) {
          console.error('Web3 not initialized');
          return;
        }


        setTimestamp(time);
        
        if (walletMode == 0) {
          // injected wallet

          try {
          
            let bridgeContract = new web3.eth.Contract(bridgeABI, bridgeAddress);

            const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
            let bridgeContractAlchemy = new web3alchemy.eth.Contract(bridgeABI, bridgeAddress);
            let bigNum = (bridgeInput * 10 ** 18);
            
            const gasLimit = await bridgeContractAlchemy.methods.bridge(bigNum).estimateGas({ from: account });
            let gasCalc = Math.floor(parseInt(gasLimit) * 1.1);
            
            let transactionParams = {
                from: account,
                gas: gasCalc.toString(),
            };
            
            // Check if the network supports EIP-1559
            const isEIP1559Supported = await web3alchemy.eth.getBlock('latest').then(block => block.baseFeePerGas !== undefined);
            
            if (isEIP1559Supported) {
                transactionParams.maxPriorityFeePerGas = web3.utils.toWei('0.005', 'gwei');
                transactionParams.maxFeePerGas = web3.utils.toWei('0.3', 'gwei');
            } else {
                transactionParams.gasPrice = localGasPrice.toString();
            }
            
            const transaction = await bridgeContract.methods.bridge(bigNum).send(transactionParams);
            console.log('Bridge transaction successful:', transaction);
            setSavedTransactionHash(transaction.transactionHash);
            //fetchUserDataByVerifiedAddress(account);
           // setPage("Transaction Sent");


   
   




            bridgeContract = null;
            } catch (error) {
            console.error('Error bridging tokens:', error.message);
            }
    
        } else {
          //smart wallet


          if (provider) {
            try {
              const smartWeb3 = new Web3(provider)
              let bridgeContract = new smartWeb3.eth.Contract(bridgeABI, bridgeAddress);
              let bigNum = (bridgeInput * 10 ** 18);
              const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
              let bridgeContractAlchemy = new web3alchemy.eth.Contract(bridgeABI, bridgeAddress);
              const gasLimit = await bridgeContractAlchemy.methods.bridge(bigNum).estimateGas({ from: smartWalletAccount });
              let gasCalc = Math.floor(parseInt(gasLimit) * 1.1);
              
              let transactionParams = {
                  from: smartWalletAccount,
                  gas: gasCalc.toString(),
              };
              
              // Check if the network supports EIP-1559
              const isEIP1559Supported = await web3alchemy.eth.getBlock('latest').then(block => block.baseFeePerGas !== undefined);
              
              if (isEIP1559Supported) {
                  transactionParams.maxPriorityFeePerGas = web3.utils.toWei('0.005', 'gwei');
                  transactionParams.maxFeePerGas = web3.utils.toWei('0.3', 'gwei');
              } else {
                  transactionParams.gasPrice = localGasPrice.toString();
              }
              
              const transaction = await bridgeContract.methods.bridge(bigNum).send(transactionParams);
              console.log('Bridge transaction successful:', transaction);
              setSavedTransactionHash(transaction.transactionHash);
              //fetchUserDataByVerifiedAddress(account);
             // setPage("Transaction Sent");
              bridgeContract = null;
              } catch (error) {
              console.error('Error bridging tokens:', error.message);
              }
          } else {
            console.log("No provider found");
          }



        }







    
      } else {

        if (bridgeInput >= 1) {
          console.log("Must approve tokens first");
          approveBridge();
        } else {
          console.log("Must bridge atleast 1");
        }
    
      }
    } else {
      console.log("Cannot click this again for 1s");
    }


  };




  const handleRemove = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }

    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature  !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/remove`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature, // Fix: Use signatureSave instead of signature
              expectedAddress: account
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('Duel Remove succeeded');
            //fetchUserDataByVerifiedAddress(account);
            handleRefresh(account);
            // setSignPassed(true)
          } else {
            console.error('Duel Remove failed');
            //fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("cannot do this for 1s");
    }
    
  };


  const sellBondingCurve = async () => {
  
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }
    console.log("User wants to sell " + bondingCurveInput + " " + bondingCurveName);
    const time = getTimestamp();
  
    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
  

  
        try {
          const response = await fetch(`https://${serverIP}/sellCurve`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature,
              expectedAddress: account,
              blocksIn: bondingCurveInput,
              curveName: bondingCurveName
            }),
          });
  
          const data = await response.json();
  
          if (data.success) {
            console.log('Sell Item succeeded');
            handleRefresh(account);
            setCurvePopup(false);
          } else {
            console.error('Sell Item failed:', data.error || 'Unknown error');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("Cannot do this for 1s");
    }
  };


  const buyBondingCurve = async () => {
  
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }
    console.log("User wants to buy " + bondingCurveInput + " " + bondingCurveName);
    const time = getTimestamp();
  
    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
  

  
        try {
          const response = await fetch(`https://${serverIP}/buyCurve`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature,
              expectedAddress: account,
              blocksOut: bondingCurveInput,
              curveName: bondingCurveName
            }),
          });
  
          const data = await response.json();
  
          if (data.success) {
            console.log('Buy Item succeeded');
            handleRefresh(account);
            setCurvePopup(false);
          } else {
            console.error('Buy Item failed:', data.error || 'Unknown error');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("Cannot do this for 1s");
    }
  };

  
  const repairItem = async (itemId) => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }
  
    const time = getTimestamp();
  
    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.from(messageUint8, byte => byte.toString(16).padStart(2, '0')).join('');
  
        try {
          console.log('Sending request to server...');
          const response = await fetch(`https://${serverIP}/repairItem`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature,
              expectedAddress: account,
              itemId: itemId
            }),
          });
  
          const data = await response.json();
          console.log('Response data:', data);
  
          if (data.success) {
            console.log('repair Item succeeded');
            handleRefresh(account);
  


            setItemDurability(itemMaxDurability);
            closePopup();
            // Convert enchantments to a readable string format
            //const enchantmentsString = data.enchantments.map(enchantment => `${enchantment.type} (Tier ${enchantment.tier})`).join(', ');
  
            // Set the readable enchantments string to state or context
           // setCharmEnchantments(data.enchantments);
          } else {
            console.error('charm Item failed:', data.error || 'Unknown error');
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      } else {
        console.log('No valid signature found');
      }
    } else {
      console.log("Cannot do this for 1s");
    }
  };


  const charmItem = async (itemId) => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }
  
    const time = getTimestamp();
  
    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.from(messageUint8, byte => byte.toString(16).padStart(2, '0')).join('');
  
        try {
          console.log('Sending request to server...');
          const response = await fetch(`https://${serverIP}/charmItem`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature,
              expectedAddress: account,
              itemId: itemId
            }),
          });
  
          const data = await response.json();
          console.log('Response data:', data);
  
          if (data.success) {
            console.log('charm Item succeeded');
            handleRefresh(account);
  
            // Convert enchantments to a readable string format
            //const enchantmentsString = data.enchantments.map(enchantment => `${enchantment.type} (Tier ${enchantment.tier})`).join(', ');
  
            // Set the readable enchantments string to state or context
            setCharmEnchantments(data.enchantments);
          } else {
            console.error('charm Item failed:', data.error || 'Unknown error');
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      } else {
        console.log('No valid signature found');
      }
    } else {
      console.log("Cannot do this for 1s");
    }
  };

  const adminGiveBalance = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }
  
    const time = getTimestamp();
  
    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.from(messageUint8, byte => byte.toString(16).padStart(2, '0')).join('');
  
        try {
          console.log('Sending request to server...');
          const response = await fetch(`https://${serverIP}/adminBalanceUpdate`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature,
              expectedAddress: account,
              inputAddress: adminTarget,
              amount: adminValue
            }),
          });
  
          const data = await response.json();
          console.log('Response data:', data);
  
          if (data.success) {
            console.log('Admin update succeeded');
            setAdminPopup(false);
            handleRefresh(account);

          } else {
            console.error('admin update failed:', data.error || 'Unknown error');
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      } else {
        console.log('No valid signature found');
      }
    } else {
      console.log("Cannot do this for 1s");
    }
  };



  const adminUnbanUser = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }
  
    const time = getTimestamp();
  
    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.from(messageUint8, byte => byte.toString(16).padStart(2, '0')).join('');
  
        try {
          console.log('Sending request to server...');
          const response = await fetch(`https://${serverIP}/adminUnbanFromSite`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature,
              expectedAddress: account,
              playerName: adminTarget
            }),
          });
  
          const data = await response.json();
          console.log('Response data:', data);
  
          if (data.success) {
            console.log('Admin update succeeded');
            setAdminPopup(false);
            handleRefresh(account);

          } else {
            console.error('admin update failed:', data.error || 'Unknown error');
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      } else {
        console.log('No valid signature found');
      }
    } else {
      console.log("Cannot do this for 1s");
    }
  };


  const modBanUser = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }
  
    const time = getTimestamp();
  
    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.from(messageUint8, byte => byte.toString(16).padStart(2, '0')).join('');
  
        try {
          console.log('Sending request to server...');
          const response = await fetch(`https://${serverIP}/modBanFromSite`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature,
              expectedAddress: account,
              playerName: adminTarget
            }),
          });
  
          const data = await response.json();
          console.log('Response data:', data);
  
          if (data.success) {
            console.log('Admin update succeeded');
            setModPopup(false);
            handleRefresh(account);

          } else {
            console.error('admin update failed:', data.error || 'Unknown error');
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      } else {
        console.log('No valid signature found');
      }
    } else {
      console.log("Cannot do this for 1s");
    }
  };








  const cleanItem = async (itemId) => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }
  
    const time = getTimestamp();
  
    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.from(messageUint8, byte => byte.toString(16).padStart(2, '0')).join('');
  
        try {
          console.log('Sending request to server...');
          const response = await fetch(`https://${serverIP}/cleanItem`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature,
              expectedAddress: account,
              itemId: itemId
            }),
          });
  
          const data = await response.json();
          console.log('Response data:', data);
  
          if (data.success) {
            console.log('clean Item succeeded');
            handleRefresh(account);
  
            // Convert enchantments to a readable string format
            //const enchantmentsString = data.enchantments.map(enchantment => `${enchantment.type} (Tier ${enchantment.tier})`).join(', ');
  
            // Set the readable enchantments string to state or context
            setCharmEnchantments([]);
            setCharmMode(0);
            setCleanPopup(false);
            setCharmPopup(false);
            closePopup();
            
          } else {
            console.error('clean Item failed:', data.error || 'Unknown error');
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      } else {
        console.log('No valid signature found');
      }
    } else {
      console.log("Cannot do this for 1s");
    }
  };

  const buySpecialItem = async (itemId) => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }

    const time = getTimestamp();

    if (timestamp + 1000 <= time) {
        setTimestamp(time);
        if (localSignature) {
            const encoder = new TextEncoder();
            const messageUint8 = encoder.encode(messageToSign);
            const messageHex = Array.from(messageUint8, byte => byte.toString(16).padStart(2, '0')).join('');

            try {
                console.log('Sending request to server... for ' + itemId);
                const response = await fetch(`https://${serverIP}/buySpecialItem`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                    body: JSON.stringify({
                        message: localMessage,
                        signature: localSignature,
                        expectedAddress: account,
                        itemId: itemId
                    }),
                });

                const data = await response.json();
                console.log('Response data:', data);

                if (data.success) {
                    console.log('buy special Item succeeded');
                    handleRefresh(account);
                    setPage("Wallet");
                    setSpecialShopPopup(false);
                } else {
                    console.error('buy special Item failed:', data.error || 'Unknown error');
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        } else {
            console.log('No valid signature found');
        }
    } else {
        console.log("Cannot do this for 1s");
    }
};
  
  const buyItem = async (itemId) => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }

    const time = getTimestamp();

    if (timestamp + 1000 <= time) {
        setTimestamp(time);
        if (localSignature) {
            const encoder = new TextEncoder();
            const messageUint8 = encoder.encode(messageToSign);
            const messageHex = Array.from(messageUint8, byte => byte.toString(16).padStart(2, '0')).join('');

            try {
                console.log('Sending request to server...');
                const response = await fetch(`https://${serverIP}/buyItem`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                    body: JSON.stringify({
                        message: localMessage,
                        signature: localSignature,
                        expectedAddress: account,
                        itemId: itemId
                    }),
                });

                const data = await response.json();
                console.log('Response data:', data);

                if (data.success) {
                    console.log('buy Item succeeded');
                    handleRefresh(account);
                    setBuyPopup(false);
                } else {
                    console.error('buy Item failed:', data.error || 'Unknown error');
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        } else {
            console.log('No valid signature found');
        }
    } else {
        console.log("Cannot do this for 1s");
    }
};


  const unlistItem = async (itemId) => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }

    const time = getTimestamp();

    if (timestamp + 1000 <= time) {
        setTimestamp(time);
        if (localSignature) {
            const encoder = new TextEncoder();
            const messageUint8 = encoder.encode(messageToSign);
            const messageHex = Array.from(messageUint8, byte => byte.toString(16).padStart(2, '0')).join('');

            try {
                console.log('Sending request to server...');
                const response = await fetch(`https://${serverIP}/unlistItem`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                    body: JSON.stringify({
                        message: localMessage,
                        signature: localSignature,
                        expectedAddress: account,
                        itemId: itemId
                    }),
                });

                const data = await response.json();
                console.log('Response data:', data);

                if (data.success) {
                    console.log('UnList Item succeeded');
                    handleRefresh(account);
                    setItemShopPopup(false);
                } else {
                    console.error('UnList Item failed:', data.error || 'Unknown error');
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        } else {
            console.log('No valid signature found');
        }
    } else {
        console.log("Cannot do this for 1s");
    }
};



  const listItem = async (itemId, price) => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }


    const time = getTimestamp();

    if (timestamp + 1000 <= time) {
        setTimestamp(time);
        if (localSignature) {
            const encoder = new TextEncoder();
            const messageUint8 = encoder.encode(messageToSign);
            const messageHex = Array.from(messageUint8, byte => byte.toString(16).padStart(2, '0')).join('');

            try {
                console.log('Sending request to server...');
                const response = await fetch(`https://${serverIP}/listItem`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                    body: JSON.stringify({
                        message: localMessage,
                        signature: localSignature,
                        expectedAddress: account,
                        itemId: itemId,
                        price: price
                    }),
                });

                const data = await response.json();
                console.log('Response data:', data);

                if (data.success) {
                    console.log('List Item succeeded');
                    handleRefresh(account);
                    setItemShopPopup(false);
                } else {
                    console.error('List Item failed:', data.error || 'Unknown error');
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        } else {
            console.log('No valid signature found');
        }
    } else {
        console.log("Cannot do this for 1s");
    }
};

  const sendToMinecraft = async (toolId, tokenId, amount, isTool) => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }
    const time = getTimestamp();
  
    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
  
        // Log the data being sent for debugging

  
        try {
          let fixedSendAmount = amount;
          if (fixedSendAmount > 2368) {
            fixedSendAmount = 2368;
          }
          const response = await fetch(`https://${serverIP}/sendToMinecraft`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },

            body: JSON.stringify({
              message: localMessage,
              signature: localSignature,
              expectedAddress: account,
              toolId: toolId,
              tokenId: tokenId,
              amount: fixedSendAmount,
              isTool: isTool
            }),
          });
  
          const data = await response.json();
  
          if (data.success) {
            console.log('Send Item succeeded');
            handleRefresh(account);
            setSendPopup(false);
          } else {
            console.error('Send Item failed:', data.error || 'Unknown error');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("Cannot do this for 1s");
    }
  };


  const handleRankup = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }

    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }
    
    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (signatureSave !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/rankUp`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature, // Fix: Use signatureSave instead of signature
              expectedAddress: account,
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('Rankup succeeded');
            handleRefresh(account);
            setRankupPopup(false);
            // setSignPassed(true)
          } else {
            console.error('Rankup failed');
           // fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("cannot do this for 1s");
    }
    
  };

  const handleRequestClan = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }

    
    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (signatureSave !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/requestClan`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature, // Fix: Use signatureSave instead of signature
              expectedAddress: account,
              clanID: targetClanID,
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('request succeeded');
            handleRefresh(account);
            closePopup();
            // setSignPassed(true)
          } else {
            console.error('request failed');
           // fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("cannot do this for 1s");
    }
    
  };


  const handleKickFromClan = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }

    
    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (signatureSave !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/banPlayerFromClan`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature, // Fix: Use signatureSave instead of signature
              expectedAddress: account,
              clanID: targetClanID,
              ethAddress: targetClanMemberAddress
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('kick request succeeded');
            handleRefresh(account);

            handleChangeClanView();

            closePopup();
            // setSignPassed(true)
          } else {
            console.error('kick request failed');
           // fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("cannot do this for 1s");
    }
    
  };



  const handleRejectJoinRequest = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }

    
    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (signatureSave !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/rejectJoinRequest`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature, // Fix: Use signatureSave instead of signature
              expectedAddress: account,
              clanID: targetClanID,
              ethAddress: targetClanMemberAddress
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('reject request succeeded');
            handleRefresh(account);

            handleChangeClanView();

            closePopup();
            // setSignPassed(true)
          } else {
            console.error('reject request failed');
           // fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("cannot do this for 1s");
    }
    
  };

  const handleAcceptJoinRequest = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }

    
    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (signatureSave !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/acceptJoinRequest`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature, // Fix: Use signatureSave instead of signature
              expectedAddress: account,
              clanID: targetClanID,
              ethAddress: targetClanMemberAddress
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('accept request succeeded');
            handleRefresh(account);
            closePopup();
            // setSignPassed(true)
          } else {
            console.error('accept request failed');
           // fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("cannot do this for 1s");
    }
    
  };



  const handleCreateClan = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }

    
    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (signatureSave !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/createClan`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature, // Fix: Use signatureSave instead of signature
              expectedAddress: account,
              duelAmount: bridgeInput,
              tickerInput: tickerInput,
              baseColorTokenIDInput: baseColorTokenIDInput, 
              clanNameInput: clanNameInput, 
              clanDescInput: clanDescInput,
              dailyTaxRateInput: dailyTaxRateInput
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('Duel Create succeeded');
            handleRefresh(account);
            setCreatePopup(false);
            
            setClanCreation(false);

            closePopup();
            // setSignPassed(true)
          } else {
            console.error('Duel Create failed');
           // fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("cannot do this for 1s");
    }
    
  };

  const handleCreate = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }

    
    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (signatureSave !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: localMessage,
              signature: localSignature, // Fix: Use signatureSave instead of signature
              expectedAddress: account,
              duelAmount: bridgeInput,
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('Duel Create succeeded');
            handleRefresh(account);
            setCreatePopup(false);
            // setSignPassed(true)
          } else {
            console.error('Duel Create failed');
           // fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("cannot do this for 1s");
    }
    
  };


  const partialData = async () => {


  
      try {
        const response = await fetch(`https://${serverIP}/partialData`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: "partialData"
          })
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
  
        const data = await response.json();
  
        // Update state with fetched data


        setGasPrice(data.gasPrice);
        setTokenPrice(data.token_price);
        setETHTOUSD(data.eth_to_usd);

        setOnlinePlayers(data.activePlayers);
        setSpecialShopData(data.specialShop);
        
        setItemShopData(data.listings);
        setAllDuels(data.duels || []);

        setBaltopData([]);
        setBaltopData(data.top100);
        setEventsData([]);
        setEventsData(data.events);
        setItemShopData(data.listings);
        setCurveData(data.curves);

        let totalTokens = data.curves.reduce((acc, item) => {
          return acc + item.reserve_tokens;
        }, 0);
  
        setTokensInCurves(totalTokens);


        
        console.log('Received partial data:', data);
  
      } catch (error) {
        console.error('Error:', error);
      }

  };
  const baseColorsAddress = '0x7bc1c072742d8391817eb4eb2317f98dc72c61db';



  async function getColorFromBaseColor(tokenId) {


    const baseColorsContract = new web3.eth.Contract(basecolorsABI, baseColorsAddress);
      try {
        // Assuming tokenIdToColor is a mapping and directly accessible
        const color = await baseColorsContract.methods.tokenIdToColor(tokenId).call();
        return color;
      } catch (error) {
        console.error('Error fetching color:', error);
        throw error;
      }

  }
  async function fetchBaseColors(response, baseColorsContract) {
    const tempData = [];
    let defaultColor = { id: 0, color: "#F6F6F7" };
    tempData.push(defaultColor);

    if (response.ownedNfts && response.ownedNfts.length>=1) {
      for (const nft of response.ownedNfts) {
        try {
          const tokenId = parseInt(nft.tokenId);
          const colorString = await baseColorsContract.methods.tokenIdToColor(tokenId).call();
          let baseColor = { id: tokenId, color: colorString };
          tempData.push(baseColor);
          //console.log(`${tokenId} is ${colorString}`);
        } catch (error) {
          console.error(`Error fetching base color for tokenId ${nft.tokenId}:`, error);
        }
    
        // Clear and update state after each iteration
        setBasecolorsData([...tempData]);
        const timestampInSeconds = Math.floor(Date.now() / 1000);
        basecolorTimestamp = timestampInSeconds;
  
      }
    }

  }


  function getClanIdByUserAddress(userAddress, clansData) {

    // Find clan by user address
    const foundClan = clansData.find(clan =>
      clan.members.some(member => member.eth_address.toLowerCase() === userAddress.toLowerCase())
    );
  
    // Return the clan ID or 0 if no clan is found
    return foundClan ? foundClan.id : 0;
  }
  

  const handleRefresh = async (addy) => {
    const timestampInSeconds = Math.floor(Date.now() / 1000);
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }
    if (localSignature != null) {

  

      let checksumAddy = Web3.utils.toChecksumAddress(addy);

      if (walletMode == 1) {
        checksumAddy = Web3.utils.toChecksumAddress(smartWalletAccount);

        
      }
  

      if (lowercaseAdmins.includes(checksumAddy.toLowerCase())) {
        setAdmin(true);
        //console.log("USER IS AN ADMIN");
      }
      
      if (lowercaseMods.includes(checksumAddy.toLowerCase())) {
        setModerator(true);
        //console.log("USER IS AN ADMIN");
      }


      try {
        const response = await fetch(`https://${serverIP}/data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: localMessage,
            signature: localSignature,
            expectedAddress: checksumAddy,
          })
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
  
        const data = await response.json();
  


        setClanKillsData(data.clanKills);
        let getClanID = getClanIdByUserAddress(checksumAddy, data.clans);
        console.log("clan id: " + getClanID);
        console.log(data);
        setClanData(data.clans);
        setOurClanID(getClanID);
        setOurClanData(data.clans[getClanID-1]);

        if (filteredClanData && filteredClanData.id){
          let tempClan = data.clans[filteredClanData.id-1];
          setFilteredClanData([]);
          setFilteredClanData(tempClan);
        }
        
      

        
        // Update state with fetched data
        let tokensFixed = (data.token_balance).toFixed(3);
        setTokenBalanceServer(tokensFixed);
        setGasPrice(data.gasPrice);
        setTokenPrice(data.token_price);
        setETHTOUSD(data.eth_to_usd);
        setOurDuel(data.duel);
        setAllDuels(data.duels || []);
        setAirdrop(data.airdrop);
        setRank(data.rank);
        setUsername(data.username);
        localGasPrice = data.gasPrice;
        setUnverified_Username(data.unverified_username);
        setLastHash(data.hash);

        setItemShopData(data.listings);
        setCurveData(data.curves);

        let totalTokens = data.curves.reduce((acc, item) => {
          return acc + item.reserve_tokens;
        }, 0);
  
        setTokensInCurves(totalTokens);



        setPlayerRank(data.rank);
        setNextRankCost(data.next_rank_cost);

        setOnlinePlayers(data.activePlayers);
        setBaltopData([]);
        setBaltopData(data.top100);
        setEventsData([]);
        setEventsData(data.events);

        setRankImage("./Rank" + data.rank + ".png");
        let nextRank = data.rank+1;
        setNextRankImage("./Rank" + nextRank + ".png");

        if (data.blocks !== blocksData || data.tools !== itemsData) {
          setBlocksData(data.blocks || []);
          setItemsData(data.tools || []);
          setAllData(data);
        }

            


        if (provider) {

          const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
          const web3Instance = new Web3(provider);
          const basecraftToken = new web3alchemy.eth.Contract(basecraftABI, basecraftAddress);


          
          try {
            const tokenBalance = await basecraftToken.methods.balanceOf(addy).call();
            let tokenNumber = parseFloat(tokenBalance.toString());
            let realTokens = ((tokenNumber / 10 ** 18).toFixed(3));
            setTokenBalanceWallet(realTokens);
            console.log("Your token balance is " + tokenBalance);
        } catch (error) {
            console.error("Error fetching token balance: ", error);
        }
         // console.log("Your token balance is " + tokenBalance);
  
  
        try {
            const etherbalance = await web3alchemy.eth.getBalance(addy);
            const etherFixed = parseInt(etherbalance.toString());
            const etherReduced = (etherFixed/10**18).toFixed(6);
            setEthBalance(etherReduced);
            console.log("YOUR BALANCE IS " + etherbalance + " = " + etherReduced);
        } catch (error) {
            console.error("Error fetching ether balance: ", error);
        }
            
        try {
          const tokenAllowance = await basecraftToken.methods.allowance(addy, bridgeAddress).call();
          let approveNumber = parseFloat(tokenAllowance.toString());
          setApprovedTokensBridge(approveNumber);
      } catch (error) {
          console.error("Error fetching token allowance for bridge: ", error);
      }
      
  
      try {
        const tokenAllowanceSwap = await basecraftToken.methods.allowance(addy, swapperAddress).call();
        let approveNumberSwap = parseFloat(tokenAllowanceSwap.toString());
        setApprovedTokensSwap(approveNumberSwap);
        console.log("Approved to swap " + approveNumberSwap);
    } catch (error) {
        console.error("Error fetching token allowance for swapper: ", error);
    }



    let timerr = timestampInSeconds - basecolorTimestamp;
      if (timerr >= 60 ) {
        
     // console.log(timestampInSeconds + " minus " + basecolorTimestamp + " is " + timerr);
      const baseColorsContract = new web3alchemy.eth.Contract(basecolorsABI, baseColorsAddress);

      const options = { method: 'GET', headers: { accept: 'application/json' } };
        basecolorTimestamp = timestampInSeconds;
      fetch(`https://base-mainnet.g.alchemy.com/nft/v3/${alchemyAPIKey}/getNFTsForOwner?owner=${addy}&contractAddresses[]=${baseColorsAddress}&withMetadata=true&pageSize=100`, options)
        .then(response => response.json())
        .then(response => {
          // Create an array of promises for fetching colors
          fetchBaseColors(response, baseColorsContract);
      
  
        })
        .catch(err => console.error(err));
  
  


    }






        }
      








        if (data.username != "0") {
          setLinkPopup(false);
        }
        



        console.log('Received data:', data);
        console.log('AllDuels:', data.duels || []);
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      console.log("Local Signature is not found!");
    }
  };

  const handleSwitch2 = () => {
    if (buyMode == 0) {
      setBuyMode(1);
    } else {
      setBuyMode(0);
    }
  }
  const handleChangeBonding = () => {
    if (bondingCurveMode == 0) {
      setBondingCurveMode(1);
    } else {
      setBondingCurveMode(0);
    }
    
  }
  function calculateTotalCostSell(curveTokens, curveBlocks, fixedAmount, liquidityFee) {
    let k = 1 + liquidityFee; // Convert percentage to multiplier (e.g., 1.05 becomes 1.05)
    let n = 2; // The power factor for the curve

    // Function to calculate the total revenue from selling `fixedAmount` tokens
    function computeTotalRevenue(tokens, blocks, amount) {
        let totalRevenue = 0;

        // Calculate the total revenue analytically
        for (let i = 0; i < amount; i++) {
            let currentPrice = (tokens / blocks) * k;
            totalRevenue += currentPrice;
            tokens -= currentPrice; // Decrease tokens by the current price
            blocks += 1; // Increase blocks by 1
        }

        return totalRevenue;
    }

    let totalRevenue = computeTotalRevenue(curveTokens, curveBlocks, fixedAmount);

    // Calculate the amount that goes to the pool's reserve tokens
    let poolContribution = totalRevenue * (liquidityFee / k); // 5% of totalRevenue

    let total = totalRevenue - poolContribution;

    return total.toFixed(5);
}
  function calculateTotalCostBuy(curveTokens, curveBlocks, fixedAmount, liquidityFee) {
    let k = liquidityFee;
    let n = 2; // The power factor for the curve
    
    // Function to calculate the total cost of purchasing `fixedAmount` tokens
    function computeTotalCost(tokens, blocks, amount) {
        let initialPrice = (tokens / blocks) * k;
        let totalCost = 0;

        // Calculate the total cost analytically
        for (let i = 0; i < amount; i++) {
            let currentPrice = (tokens / blocks) * k;
            totalCost += currentPrice;
            tokens += currentPrice; // Increase tokens by the current price
            blocks -= 1; // Decrease blocks by 1
        }

        return totalCost.toFixed(5);
    }

    let totalCost = computeTotalCost(curveTokens, curveBlocks, fixedAmount);
    
    return totalCost;
}

  const handleChangePath = (mode) => {
    setSellPath(mode);
  }
  const handleChangeColor = () => {
    if (colorblindMode) {
      setColorblindMode(false);
    } else {
      setColorblindMode(true);
    }
  }
  const handleChangeDarkness = () => {
    if (screenMode) {
      setScreenMode(false);
    } else {
      setScreenMode(true);
    }
  }
  const handleChangeCreate = () => {
    if (clanCreation) {
      setClanActionMode(0);
      setClanLeaderboardMode(0);
      setClanCreation(false);
     
    } else {
      setClanActionMode(0);
      setClanLeaderboardMode(0);
      setClanCreation(true);
      setClanView(false);
    }
  }
  const handleChangeClanView = () => {
    if (clanView) {
      setClanActionMode(0);
      setClanLeaderboardMode(0);
      setClanView(false);
    } else {
      setClanActionMode(0);
      setClanLeaderboardMode(0);
      setClanView(true);
      setClanCreation(false);
    }
  }
  const handleSwitch = () => {
    if (bridgeMode == 0) {
      setBridgeMode(1);
      setRecieveOn("Server");
      setSendFrom("Wallet");
    } else {
      setBridgeMode(0);
      setRecieveOn("Wallet");
      setSendFrom("Server");
    }
  }


  const handleSearchChange2 = (event) => {
    const value = event.target.value;
    const lettersOnly = value.replace(/[^A-Za-z]/g, ''); // Remove non-letter characters
    setSearchTerm(lettersOnly);
  
    // Convert blocksData to an array of entries [key, value]
    if (blocksData && typeof blocksData === 'object') {
      const entries = Object.entries(blocksData);
  
      const filteredData = entries.filter(([key, value]) =>
        key.toLowerCase().includes(lettersOnly.toLowerCase())
      );
  
      // Convert the filtered entries back to an object
      const filteredDataObject = Object.fromEntries(filteredData);

      setFilteredBlocksData(filteredDataObject);
    } else {
      console.error('blocksData is not an object:', blocksData);
    }
    if (itemsData && Array.isArray(itemsData) && lettersOnly && typeof lettersOnly === 'string') {
      // Filter items based on item names and enchantments
      const filteredData = itemsData.filter(item => {
        if (item.item_name && typeof item.item_name === 'string' && item.item_name.toLowerCase().includes(lettersOnly.toLowerCase())) {
          return true; // Match found in item_name
        }
    
        // Check enchantments for match
        if (item.enchantments && Array.isArray(item.enchantments)) {
          for (const enchantment of item.enchantments) {
            if (
              enchantment &&
              typeof enchantment === 'object' &&
              enchantment.enchantment_level &&
              typeof enchantment.enchantment_level === 'string' &&
              enchantment.enchantment_level.toLowerCase().includes(lettersOnly.toLowerCase())
            ) {
              return true; // Match found in enchantment_level
            }
    
            if (
              enchantment &&
              typeof enchantment === 'object' &&
              enchantment.enchantment_name &&
              typeof enchantment.enchantment_name === 'string' &&
              enchantment.enchantment_name.toLowerCase().includes(lettersOnly.toLowerCase())
            ) {
              return true; // Match found in enchantment_name
            }
          }
        }
    
        return false; // No matches found
      });
    
      console.log(itemsData);
      console.log(filteredData);
      setFilteredItemsData(filteredData);
    } else {
      console.error('itemsData is not an array, or lettersOnly is not a string:', itemsData, lettersOnly);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    const lettersOnly = value.replace(/[^A-Za-z]/g, ''); // Remove non-letter characters
    setSearchTerm(lettersOnly);


    const filteredData = curveData.filter(item =>
      item.item_id.toLowerCase().includes(lettersOnly.toLowerCase())
    );
    setFilteredCurveData(filteredData);


    if (itemShopData && Array.isArray(itemShopData) && lettersOnly && typeof lettersOnly === 'string') {
      // Filter items based on item names and enchantments
      const filteredData = itemShopData.filter(item => {
        if (item.item_name && typeof item.item_name === 'string' && item.item_name.toLowerCase().includes(lettersOnly.toLowerCase())) {
          return true; // Match found in item_name
        }
    
        // Check enchantments for match
        if (item.enchantments && Array.isArray(item.enchantments)) {
          for (const enchantment of item.enchantments) {
            if (
              enchantment &&
              typeof enchantment === 'object' &&
              enchantment.enchantment_level &&
              typeof enchantment.enchantment_level === 'string' &&
              enchantment.enchantment_level.toLowerCase().includes(lettersOnly.toLowerCase())
            ) {
              return true; // Match found in enchantment_level
            }
    
            if (
              enchantment &&
              typeof enchantment === 'object' &&
              enchantment.enchantment_name &&
              typeof enchantment.enchantment_name === 'string' &&
              enchantment.enchantment_name.toLowerCase().includes(lettersOnly.toLowerCase())
            ) {
              return true; // Match found in enchantment_name
            }
          }
        }
    
        return false; // No matches found
      });
    
      console.log(itemShopData);
      console.log(filteredData);
      setFilteredItemsData(filteredData);
    } else {
      console.error('itemShopData is not an array, or lettersOnly is not a string:', itemShopData, lettersOnly);
    }
    
  };

  const handleBridgeChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {  // Regex to allow only digits
      setBridgeInput(value);
    }
  };
  const handleRefChange = (event) => {
    setReferralInput(event.target.value);
  };
  const handleNameChange = (event) => {
    setNameInput(event.target.value);
  };
  const handleAdminTargetChange = (event) => {
    setAdminTarget(event.target.value);
  };
  const handleAdminMode = (val) => {
    setAdminMode(val);
  };
  const handleAdminValueChange = (event) => {
    setAdminValue(event.target.value);
  };

  const handlePage = (page) => {
    setSearchTerm("");
    setPage(page);
    pageSave = page;
    if (page == "Duel") {
      setBackgroundImageUrl("./Background6.png");
    } else {
      setBackgroundImageUrl("./Background2.png");
    }
  }
  const openMetamask = (e) => {
    e.preventDefault();
    window.open("https://metamask.io/", '_blank');
  };
  const openLogin = () => {
    setLoginPopup(true);
  }
  const openLinkInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const closeLogin = () => {
    setLoginPopup(false);
  }
  useEffect(() => {
    const timestampInSeconds = Math.floor(Date.now() / 1000);
    if (timestampInSeconds >= lastUpdateTime) {
      lastUpdateTime = (Math.floor(Date.now() / 1000) + 1);
      partialData();
    }
    
    const initWeb3 = async () => {
      provider = await detectEthereumProvider();


      if (provider) {

        setMetamaskInstalled(true);
        const web3Instance = new Web3(provider);


        setWeb3(web3Instance);
        const accounts = await provider.request({ method: 'eth_accounts' });


        provider.removeAllListeners('chainChanged'); // Remove previous listeners
        provider.removeAllListeners('accountsChanged'); // Remove previous listeners
  


        provider.on('chainChanged', handleChainChanged);
        provider.on('accountsChanged', handleAccountsChanged);

        await fetchChainData(provider, accounts);

        // Set an interval to check the chain ID periodically
        setInterval(async () => {
          await throttledFetchChainData(provider, accounts);
        }, 10000); // Check every 30 seconds


      } else {

       

        provider = sdk.makeWeb3Provider({ options: 'smartWalletOnly' });
        const web3Instance = new Web3(provider);
        const accounts = await provider.request({ method: 'eth_accounts' });


        setWeb3(web3Instance);


        provider.removeAllListeners('chainChanged'); // Remove previous listeners
        provider.removeAllListeners('accountsChanged'); // Remove previous listeners
  


        provider.on('chainChanged', handleChainChanged);
        provider.on('accountsChanged', handleAccountsChanged);

        await fetchChainData(provider, accounts);

        // Set an interval to check the chain ID periodically
        setInterval(async () => {
          await throttledFetchChainData(provider, accounts);
        }, 10000); // Check every 30 seconds


      }
    };

    if (!pageStarted) {
      setPageStarted(true);
      initWeb3();
    }

  }, [pageStarted]);

  const fetchChainData = async (provider, accounts) => {
    //console.log('fetchChainData called at', Date.now());
    const timestampInSeconds = Math.floor(Date.now() / 1000);
    if (timestampInSeconds >= lastUpdateTime) {
      lastUpdateTime = (Math.floor(Date.now() / 1000) + 4);
      //console.log('fetchChainData called at', Date.now());
      try {
        let localSignature = null;
        if (signatureSave) {
          localSignature = signatureSave;
        } else if (signatureReal) {
          localSignature = signatureReal;
        }
        let localMessage = null;
        if (messageSave) {
          localMessage = messageSave;
        } else if (signatureReal) {
          localMessage = message;
        }






        const web3Instance = new Web3(provider);


        if (localSignature && localMessage) {

          
          if (lowercaseAdmins.includes(accounts[0].toLowerCase())) {
            setAdmin(true);
         //   console.log("USER IS AN ADMIN");
          }

          if (lowercaseMods.includes(accounts[0].toLowerCase())) {
            setModerator(true);
         //   console.log("USER IS AN ADMIN");
          }

          if (walletMode == 0) {
            // injected
            if (accounts && accounts[0]) {

              const chainId = await provider.request({ method: 'eth_chainId' });
             // setAccount(accounts[0] || null);
              setChainId(chainId);
              chainSave = chainId;
           //   console.log(`Fetched Chain ID: ${chainId}`);
      
              if (chainId !== TARGET_CHAIN_ID) {
                await switchToTargetChain(provider);
              } else {


                const web3alchemy = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyAPIKey}`));
  
 
      
                const checksumAddy = Web3.utils.toChecksumAddress(accounts[0]);
                const basecraftToken = new web3alchemy.eth.Contract(basecraftABI, basecraftAddress);


                try {
                  const tokenBalance = await basecraftToken.methods.balanceOf(accounts[0]).call();
                  let tokenNumber = parseFloat(tokenBalance.toString());
                  let realTokens = ((tokenNumber / 10 ** 18).toFixed(3));
                  setTokenBalanceWallet(realTokens);
                  console.log("Your token balance is " + tokenBalance);
              } catch (error) {
                  console.error("Error fetching token balance: ", error);
              }
               // console.log("Your token balance is " + tokenBalance);
      
  
              try {
                  const etherbalance = await web3alchemy.eth.getBalance(accounts[0]);
                  const etherFixed = parseInt(etherbalance.toString());
                  const etherReduced = (etherFixed/10**18).toFixed(6);
                  setEthBalance(etherReduced);
                  console.log("YOUR BALANCE IS " + etherbalance + " = " + etherReduced);
              } catch (error) {
                  console.error("Error fetching ether balance: ", error);
              }
                  

              if (approvedTokensBridge == 0) {
                try {
                  const tokenAllowance = await basecraftToken.methods.allowance(accounts[0], bridgeAddress).call();
                  let approveNumber = parseFloat(tokenAllowance.toString());
                  setApprovedTokensBridge(approveNumber);
              } catch (error) {
                  console.error("Error fetching token allowance for bridge: ", error);
              }
              
              }

      
              if (approvedTokensSwap == 0) {
                try {
                  const tokenAllowanceSwap = await basecraftToken.methods.allowance(accounts[0], swapperAddress).call();
                  let approveNumberSwap = parseFloat(tokenAllowanceSwap.toString());
                  setApprovedTokensSwap(approveNumberSwap);
                  console.log("Approved to swap " + approveNumberSwap);
              } catch (error) {
                  console.error("Error fetching token allowance for swapper: ", error);
              }
              }

              let timerr = timestampInSeconds - basecolorTimestamp;
              if (timerr >= 60 ) {
                
               // console.log(timestampInSeconds + " minus " + basecolorTimestamp + " is " + timerr);
                const baseColorsContract = new web3alchemy.eth.Contract(basecolorsABI, baseColorsAddress);
                basecolorTimestamp = timestampInSeconds;

                const options = { method: 'GET', headers: { accept: 'application/json' } };
            
                fetch(`https://base-mainnet.g.alchemy.com/nft/v3/${alchemyAPIKey}/getNFTsForOwner?owner=${accounts[0]}&contractAddresses[]=${baseColorsAddress}&withMetadata=true&pageSize=100`, options)
                  .then(response => response.json())
                  .then(response => {
                    // Create an array of promises for fetching colors
                    fetchBaseColors(response, baseColorsContract);
                
            
                  })
                  .catch(err => console.error(err));
  
              }



      

      
                fetch(`https://${serverIP}/data`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    message: localMessage,
                    signature: localSignature,
                    expectedAddress: checksumAddy,
                  })
                })
                .then(response => response.json())
                .then(data => {
                  // Log the parsed JSON data
                  console.log('Received data:', data);


                  
        setClanKillsData(data.clanKills);
        let getClanID = getClanIdByUserAddress(checksumAddy, data.clans);
        console.log("clan id: " + getClanID);
        console.log(data);
        setClanData(data.clans);
        setOurClanID(getClanID);
        setOurClanData(data.clans[getClanID-1]);


        if (filteredClanData && filteredClanData.id){
          let tempClan = data.clans[filteredClanData.id-1];
          setFilteredClanData([]);
          setFilteredClanData(tempClan);
        }
        




                
                  let tokensFixed = (data.token_balance).toFixed(3);
                  setTokenBalanceServer(tokensFixed);
                  setGasPrice(data.gasPrice);
                  setTokenPrice(data.token_price);
                  setETHTOUSD(data.eth_to_usd);
                  setOurDuel(data.duel);
                  setAllDuels(data.duels || []);
                  setAirdrop(data.airdrop);
                  setUsername(data.username);
                  setUnverified_Username(data.unverified_username);
                  setLastHash(data.hash);
                  setCurveData(data.curves);
                  localGasPrice = data.gasPrice;
    
                  setRank(data.rank);

                  setEventsData([]);
                  setEventsData(data.events);

                  
                  setOnlinePlayers(data.activePlayers);
                  let totalTokens = data.curves.reduce((acc, item) => {
                    return acc + item.reserve_tokens;
                  }, 0);
            
                  setTokensInCurves(totalTokens);
  
                  setBaltopData([]);
                  setBaltopData(data.top100);
                  setEventsData([]);
                  setEventsData(data.events);
  
                  setPlayerRank(data.rank);
                  setNextRankCost(data.next_rank_cost);
                  setRankImage("./Rank" + data.rank + ".png");
                  let nextRank = data.rank+1;
                  setNextRankImage("./Rank" + nextRank + ".png");
         
      
                    setItemShopData(data.listings);
      
                  if (data.blocks !== blocksData || data.tools !== itemsData) {
                    setBlocksData(data.blocks || []);
                    setItemsData(data.tools || []);
                    setAllData(data);
                  }
                    
      
      
                  if (data.username != "0") {
                    setLinkPopup(false);
                  }
                  
      
                 //itemsData = data.tools
      
      
      
      
                
                  // Log the duels to verify
                 // console.log('AllDuels:', data.duels || []);
                })
                .catch(error => {
                  console.error('Error:', error);
                });
      
              }
  
  
  
            } else {
              console.log("Cannot find account");
            }
          } else {
            // smart wallet
            if (provider) {
              console.log("time to get data with smart wallet!");
              const chainId = await provider.request({ method: 'eth_chainId' });
              //setAccount(accounts[0] || null);
              setChainId(chainId);
              chainSave = chainId;
              console.log(`Fetched Chain ID: ${chainId}`);
  

  

  
              if (chainId !== TARGET_CHAIN_ID) {
  
                console.log("Cannot fetch data from other chains!");
              } else {
  
                const smartWeb3 = new Web3(provider);
                const basecraftToken = new smartWeb3.eth.Contract(basecraftABI, basecraftAddress);
                console.log("Fetching data for " + smartWalletAccount);

                try {
                  const etherbalance = await smartWeb3.eth.getBalance(smartWalletAccount);
                  const etherFixed = parseInt(etherbalance.toString());
                  const etherReduced = (etherFixed/10**18).toFixed(6);
                  setEthBalance(etherReduced);
                  console.log("YOUR BALANCE IS " + etherbalance + " = " + etherReduced);
              } catch (error) {
                  console.error("Error fetching ether balance: ", error);
              }


                try {
                  const tokenAllowance = await basecraftToken.methods.allowance(smartWalletAccount, bridgeAddress).call();
                  let approveNumber = parseFloat(tokenAllowance.toString());
                  setApprovedTokensBridge(approveNumber);
              } catch (error) {
                  console.error("Error fetching token allowance for bridge: ", error);
              }
              try {
                const tokenAllowance = await basecraftToken.methods.allowance(smartWalletAccount, swapperAddress).call();
                let approveNumber = parseFloat(tokenAllowance.toString());
                setApprovedTokensSwap(approveNumber);
            } catch (error) {
                console.error("Error fetching token allowance for swapper: ", error);
            }
              try {
                const tokenBalance = await basecraftToken.methods.balanceOf(smartWalletAccount).call();
                let tokenNumber = parseFloat(tokenBalance.toString());
                let realTokens = ((tokenNumber / 10 ** 18).toFixed(3));
                setTokenBalanceWallet(realTokens);
                console.log("Your token balance is " + tokenBalance);
            } catch (error) {
                console.error("Error fetching token balance: ", error);
            }
            const checksumAddy = Web3.utils.toChecksumAddress(smartWalletAccount);
              


      
            fetch(`https://${serverIP}/data`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                message: localMessage,
                signature: localSignature,
                expectedAddress: checksumAddy,
              })
            })
            .then(response => response.json())
            .then(data => {
              // Log the parsed JSON data
              console.log('Received data:', data);
            

              setClanKillsData(data.clanKills);
              let getClanID = getClanIdByUserAddress(checksumAddy, data.clans);
              console.log("clan id: " + getClanID);
              console.log(data);
              setClanData(data.clans);
              setOurClanID(getClanID);
              setOurClanData(data.clans[getClanID-1]);

                          
            if (filteredClanData && filteredClanData.id){
              let tempClan = data.clans[filteredClanData.id-1];
              setFilteredClanData([]);
              setFilteredClanData(tempClan);
            }
            


              let tokensFixed = (data.token_balance).toFixed(3);
              setTokenBalanceServer(tokensFixed);
              setGasPrice(data.gasPrice);
              setTokenPrice(data.token_price);
              setETHTOUSD(data.eth_to_usd);
              setOurDuel(data.duel);
              setAllDuels(data.duels || []);
              setAirdrop(data.airdrop);
              setUsername(data.username);
              setUnverified_Username(data.unverified_username);
              setLastHash(data.hash);
              setCurveData(data.curves);
              setRank(data.rank);
              setOnlinePlayers(data.activePlayers);
              let totalTokens = data.curves.reduce((acc, item) => {
                return acc + item.reserve_tokens;
              }, 0);
        
              setTokensInCurves(totalTokens);

              localGasPrice = data.gasPrice;
              setBaltopData([]);
              setBaltopData(data.top100);
              setEventsData([]);
              setEventsData(data.events);

              setPlayerRank(data.rank);
              setNextRankCost(data.next_rank_cost);
              setRankImage("./Rank" + data.rank + ".png");
              let nextRank = data.rank+1;
              setNextRankImage("./Rank" + nextRank + ".png");
     
  
                setItemShopData(data.listings);
  
              if (data.blocks !== blocksData || data.tools !== itemsData) {
                setBlocksData(data.blocks || []);
                setItemsData(data.tools || []);
                setAllData(data);
              }
                
  
  
              if (data.username != "0") {
                setLinkPopup(false);
              }
              
  
             //itemsData = data.tools
  
  
  
  
            
              // Log the duels to verify
             // console.log('AllDuels:', data.duels || []);
            })
            .catch(error => {
              console.error('Error:', error);
            });





              }
            } else {
              console.log("Smart Wallet provider crashed");
            }



          }

        } else {
          console.log("Cannot find local signature or message");
        }






      } catch (error) {
        console.error('Error fetching chain data:', error);
      }
    }
  };
  const throttledFetchChainData = throttle(fetchChainData, 10000); // Throttling to avoid multiple calls in a short period



  const handleAccountsChanged = (accounts) => {
    setAccount(accounts[0] || null);
  };

  const handleChainChanged = () => {
    window.location.reload();
  };
  const TARGET_CHAIN_PARAMS = {
    chainId: TARGET_CHAIN_ID,
    chainName: 'Base Mainnet', // Replace with your target chain name
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://base.drpc.org'], // Replace with your RPC URL
    blockExplorerUrls: ['https://basescan.org/'], // Replace with your block explorer URL
  };
  const switchToTargetChain = async (provider) => {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: TARGET_CHAIN_ID }],
      });
    } catch (error) {
      if (error.code === 4902) {
        console.error('This network is not available in your MetaMask, attempting to add it.');
        console.log('Target chain not detected:', TARGET_CHAIN_ID);
  
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [TARGET_CHAIN_PARAMS],
          });
          console.log('Target chain added successfully, switching to it.');
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: TARGET_CHAIN_ID }],
          });
        } catch (addError) {
          console.error('Failed to add the network:', addError);
        }
      } else {
        console.error('Failed to switch network:', error);
      }
    }
  };
  const handleLogin = async () => {
    if (web3) {
      try {
        const accounts = await web3.eth.requestAccounts();
        
        setAccount(accounts[0]);
        walletMode = 0;
   
        const chainId = await provider.request({ method: 'eth_chainId' });
        // setAccount(accounts[0] || null);
         setChainId(chainId);
         chainSave = chainId;

        console.log("regular wallet is ", accounts[0]);


      } catch (error) {
        console.error('User denied account access');
      }
    } else {
      console.log("No web3");
    }
  };


  const handleLogin2 = async () => {
    provider = sdk.makeWeb3Provider({ options: 'smartWalletOnly' });
    let addresses = await provider.request({ method: 'eth_requestAccounts' });
    console.log("smart wallet is ", addresses);
    walletMode = 1;
    setAccount(addresses[0]);
    smartWalletAccount = addresses[0];

              const chainId = await provider.request({ method: 'eth_chainId' });
             // setAccount(accounts[0] || null);
              setChainId(chainId);
              chainSave = chainId;

  };

  

  
  const gasFreeBridge = async (addy) => {

    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }

    
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }



    if (web3 && addy) {
      try {

        const checksumAddy = Web3.utils.toChecksumAddress(addy);
       // console.log(signature);
        //console.log(messageHex);
        //console.log(checksumAddy);
        
        fetch(`https://${serverIP}/gasFreeBridge`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: localMessage,
            signature: localSignature,
            expectedAddress: checksumAddy,
            tokenAmount: parseFloat(bridgeInput)
          })
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            console.log('Gas free transaction was authorized');
            //fetchUserDataByVerifiedAddress(account);
            //setSignPassed(true)
            closePopup();
            handleRefresh(checksumAddy);
            
            //setLoginPopup(false);
            //setPage("Wallet");
          } else {
            console.error('Gas free transaction was denied by server');
           // setSignPassed(false);
        //   signatureSave = null;
         //  setSignatureReal(null);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        //  signatureSave = null;
        //  setSignatureReal(null);
      });
      } catch (error) {
        console.error('Error signing message:', error);
       // setSignatureReal(null);
       // signatureSave = null;
      }
    }
};
  
  const gasFreeSwap = async (addy) => {

    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }

    
    let localMessage = null;
    if (messageSave) {
      localMessage = messageSave;
    } else if (signatureReal) {
      localMessage = message;
    }

    if (web3 && addy) {
      try {

        const checksumAddy = Web3.utils.toChecksumAddress(addy);
       // console.log(signature);
        //console.log(messageHex);
        //console.log(checksumAddy);
        
        fetch(`https://${serverIP}/gasFreeSale`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: localMessage,
            signature: localSignature,
            expectedAddress: checksumAddy,
            tokenAmount: parseFloat(amountIn)
          })
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            console.log('Gas free transaction was authorized');
            //fetchUserDataByVerifiedAddress(account);
            //setSignPassed(true)

            closePopup();
            handleRefresh(checksumAddy);
            
            
            //setLoginPopup(false);
            //setPage("Wallet");
          } else {
            console.error('Gas free transaction was denied by server');
           // setSignPassed(false);
        //   signatureSave = null;
         //  setSignatureReal(null);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        //  signatureSave = null;
        //  setSignatureReal(null);
      });
      } catch (error) {
        console.error('Error signing message:', error);
       // setSignatureReal(null);
       // signatureSave = null;
      }
    }
};

const resetUsername = async (addy) => {
  if (web3 && addy) {
    try {
      let address = web3.utils.toChecksumAddress(addy);
      let localSignature = null;
      if (signatureSave) {
        localSignature = signatureSave;
      } else if (signatureReal) {
        localSignature = signatureReal;
      }
      let localMessage = null;
      if (messageSave) {
        localMessage = messageSave;
      } else if (signatureReal) {
        localMessage = message;
      }

     // console.log(signature);
      //console.log(messageHex);
      //console.log(checksumAddy);
      fetch(`https://${serverIP}/resetUsername`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: localMessage,
          signature: localSignature,
          expectedAddress: address,
        })
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('Username Reset Request Succeeded');
          //fetchUserDataByVerifiedAddress(account);
          //setSignPassed(true)
          handleRefresh(address);
        } else {
          console.error('Username Update failed');
         // setSignPassed(false);
        }
      })
      .catch(error => {
        console.error('Error:', error);
    });
    } catch (error) {
      console.error('Error signing message:', error);
    }
  }
};

  
const updateUsername = async (user, addy) => {
  if (web3 && addy) {
    try {
      let address = web3.utils.toChecksumAddress(addy);
      let localSignature = null;
      if (signatureSave) {
        localSignature = signatureSave;
      } else if (signatureReal) {
        localSignature = signatureReal;
      }
      let localMessage = null;
      if (messageSave) {
        localMessage = messageSave;
      } else if (signatureReal) {
        localMessage = message;
      }

     // console.log(signature);
      //console.log(messageHex);
      //console.log(checksumAddy);
      fetch(`https://${serverIP}/updateUsername`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: localMessage,
          signature: localSignature,
          expectedAddress: address,
          newName: user,
          referralName: referralInput
        })
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('Username Update Request Succeeded');
          //fetchUserDataByVerifiedAddress(account);
          //setSignPassed(true)
          handleRefresh(address);
        } else {
          console.error('Username Update failed');
         // setSignPassed(false);
        }
      })
      .catch(error => {
        console.error('Error:', error);
    });
    } catch (error) {
      console.error('Error signing message:', error);
    }
  }
};

  
  const signWallet = async (addy) => {
    if (web3 && addy) {
      try {
        let address = web3.utils.toChecksumAddress(addy);
        const web3Instance = new Web3(window.ethereum);
        const signature = await web3Instance.eth.personal.sign(
          messageToSign,
          address,
          ''
        );
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
        setMessage(messageHex);
        messageSave = messageHex;
        //console.log(messageHex);
        const checksumAddy = Web3.utils.toChecksumAddress(addy);
        //console.log(signature);
       // console.log(messageHex);
        //console.log(checksumAddy);
        fetch(`https://${serverIP}/verifySignature`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: messageHex,
            signature: signature,
            expectedAddress: checksumAddy,
          })
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            console.log('Signature verification succeeded');
            //fetchUserDataByVerifiedAddress(account);
            //setSignPassed(true)
            

            
            setLoginPopup(false);
            setPage("Wallet");


            signatureSave = signature;
            setSignatureReal(signature);


            handleRefresh(checksumAddy);


            
            
          } else {
            console.error('Signature verification failed');
           // setSignPassed(false);
           signatureSave = null;
           setSignatureReal(null);
          }
        })
        .catch(error => {
          console.error('Error:', error);
          signatureSave = null;
          setSignatureReal(null);
      });
      } catch (error) {
        console.error('Error signing message:', error);
        setSignatureReal(null);
        signatureSave = null;
      }
    }
};
const signSmartWallet = async (addy) => {
  if (provider) {
    try {
      let address = web3.utils.toChecksumAddress(addy);
      
      // Convert messageToSign to hex format prefixed with '0x'
      const messageHex = '0x' + Array.prototype.map.call(new TextEncoder().encode(messageToSign), (x) => ('00' + x.toString(16)).slice(-2)).join('');

      const signature = await provider.request({
           method: "personal_sign",
           params: [messageHex, address]
         });


       //  console.log(signature);

         const valid = await publicClient.verifyMessage({ 
          address: address,
          message: { raw: messageHex },
          signature,
        })



        setMessage(messageHex);
        messageSave = messageHex;
  

      console.log("Valid = " + valid);





      fetch(`https://${serverIP}/verifySignature`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: messageHex,
          signature: signature,
          expectedAddress: address,
        })
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('Signature verification succeeded');
          //fetchUserDataByVerifiedAddress(account);
          //setSignPassed(true)
          

          
          setLoginPopup(false);
          setPage("Wallet");


          signatureSave = signature;
          setSignatureReal(signature);


          handleRefresh(address);
        } else {
          console.error('Signature verification failed');
         // setSignPassed(false);
         signatureSave = null;
         setSignatureReal(null);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        signatureSave = null;
        setSignatureReal(null);
    });




      // Assuming provider is your EIP-1193 compliant provider

      
      //const signatureRequest = await provider.request({
     //   method: "personal_sign",
     //   params: [messageHex, address]
     // });
     // const { 
      //  ReturnedAddress,
      //  data,
     //   signature,
     // } = parseErc6492Signature(signatureRequest)
     // console.log("Address Returned: " + ReturnedAddress);


     // console.log(messageHex);
     // console.log(signature);
     
    



    } catch (error) {
      console.error('Error signing message:', error.message);
      // Handle errors appropriately
    }
  } else {
    console.log("NO PROVIDOR");
  }
};


  return (
    
    <div className="App">
<link rel="preconnect" href="https://fonts.googleapis.com"/>
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
<link href="https://fonts.googleapis.com/css2?family=Freeman&display=swap" rel="stylesheet"></link>

<link rel="preconnect" href="https://fonts.googleapis.com"></link>
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
<link href="https://fonts.googleapis.com/css2?family=VT323&display=swap" rel="stylesheet"></link>



    <header className="header" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundPosition: 'center', // Centers the image

                  }}>
      <img className="logo" src={colorblindMode ? "./home1.png" : "./home.png"} alt="Base Craft!" onClick={() => handlePage("Home")}/>

      <img className="logo" src={colorblindMode ? "./shop1.png" : "./shop.png"} alt="Base Craft!" onClick={() => handlePage("Shop")}/>
      <img className="logo" src={colorblindMode ? "./duel1.png" : "./duel.png"} alt="Base Craft!" onClick={() => handlePage("Duel")}/>
      <img className="logo" src={colorblindMode ? "./clans1.png" : "./clans.png"} alt="Base Craft!" onClick={() => handlePage("Clan")}/>
      {account && signatureReal != null ? (
          <img className="logo" src={colorblindMode ? "./Wallet1.png" : "./Wallet.png"} alt="Base Craft!" onClick={() => handlePage("Wallet")}/>
        ) : (
          <button className='button-85' onClick={openLogin}>Login</button>
        )}
      
    </header>

        











    <div>
        {
          linkPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         

                <img src={colorblindMode ? "./LinkProfile3.png" : "./LinkProfile2.png"} alt="buy or sell tokens" class="wallet-header-image"/>
                
                


              {
                unverified_username == "0" ? (<p className="wallet-box-info" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  width: "60%",
                  height: "20%",
                  top: "10%",
                  left: "20%",
                  }}>
                    Enter your minecraft username
                    + referral's username</p>) : (
                 
                 <img src="./typeThis.png" alt="final step" class="wallet-footer-image"
                 style={{top:"30%"}}/>)
              }
                                  <div>

{
  unverified_username == "0" ? (                  <div className="wallet-box"                    style={{
    top: "40%",
    left: "10%",
    backgroundImage: "url(/woodbackground.png)",
    backgroundSize: 'cover', // Ensures the image covers the entire div
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  }}>



                    
              <input
        type="text"
        class="wallet-input"
        required
        autoComplete="off"
        value={referralInput}
        onChange={handleRefChange}
        placeholder="referral username"


        style={{

          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          
                        }}
                        
        />



    </div>) : (<div/>)
}

</div>


                  <div>

                  {
                    unverified_username == "0" ? (                  <div className="wallet-box"                    style={{
                      top: "60%",
                      left: "10%",
                      backgroundImage: "url(/woodbackground.png)",
                      backgroundSize: 'cover', // Ensures the image covers the entire div
                      backgroundPosition: 'center', // Centers the image
                      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    }}>


                        <img src={colorblindMode ? "./send1.png" : "./send.png"} alt="update username" class="link-button" onClick={() => updateUsername(nameInput, account)}/>
  
  
  
                                      
                                <input
                          type="text"
                          class="wallet-input"
                          required
                          autoComplete="off"
                          value={nameInput}
                          onChange={handleNameChange}
                          placeholder="your username"
  
  
                          style={{
  
                            backgroundImage: "url(/Plank.png)",
                            backgroundSize: 'cover', // Ensures the image covers the entire div
                            backgroundPosition: 'center', // Centers the image
                            backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                            
                                          }}
                                          
                          />
  
  
  
                      </div>) : (<p className="wallet-box-info" style={{
  backgroundImage: "url(/Plank.png)",
  backgroundSize: 'cover', // Ensures the image covers the entire div
  backgroundPosition: 'center', // Centers the image
  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  width: "60%",
  height: "20%",
  top: "40%",
  left: "20%",
  padding: "10px", // Adds padding inside the box
  wordWrap: "break-word", // Wraps long words to the next line
  overflow: "hidden", // Ensures content stays inside the box
  boxSizing: "border-box" // Includes padding in the element's total width and height
}}>
  /sync {account}
</p>)
                  }

                  </div>


                    {
                      unverified_username == "0" ? (<img src={colorblindMode ? "./linking02.png" : "./linking01.png"} alt="0/2" class="linking-image"/>
                      ) : (<img src={colorblindMode ? "./linking12.png" : "./linking11.png"} alt="1/2" class="linking-image"/>)
                    }
              
                  
                    {
                      unverified_username == "0" ? (<div/>) : (<img src={colorblindMode ? "./resetUsername.png" : "./resetUsername1.png"} alt="1/2" class="reset-button"
                        onClick={() => resetUsername(account)}/>)
                    }
              

              


          
           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>






        <div>
        {
          charmPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
            
                <img src={colorblindMode ? "./rareEnchantments1.png" : "./rareEnchantments.png"} alt="send tokens to minecraft!" class="wallet-header-image"/>
                



                <div>
                
                

                <EnchantmentList itemEnchantments={charmEnchantments} />

           

              {
                charmEnchantments.length == 0 && charmMode == 0  ? (
                  <div className="wallet-box"                    style={{
                      top: "50%",
                      left: "10%",
                      backgroundImage: "url(/woodbackground.png)",
                      backgroundSize: 'cover', // Ensures the image covers the entire div
                      backgroundPosition: 'center', // Centers the image
                      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    }}>
    
    
                    <img src="./Enchant2.png" alt="use max amount" class="wallet-box-max-button" onClick={() => charmItem(charmItemID) }/>
    
    
    
                <p className="wallet-box-info" style={{
    
    backgroundImage: "url(/Plank.png)",
    backgroundSize: 'cover', // Ensures the image covers the entire div
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    
    
    
                }}>{tokenBalanceServer}</p>
                <img src={charmImage} class="wallet-box-token"/>
                <div class="wallet-input" style={{
    
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          
                        }}>Enchant for 25 tokens?</div>
    
    
                          </div>                
    ) : (<div><img src={colorblindMode ? "./sell1.png" : "./sell.png"} alt="use max amount" class="trade-button" onClick={openShopFromCharm} style={{top:"55%"}}/>
    <img src="./edit.png" alt="use max amount" class="trade-button" onClick={openCleanFromCharm} style={{top:"75%"}}/>
    
    </div>)
              }

   

                    

             



              
          </div>







                  <div>
                  


                  <img src="./MaxEnchantments.png" alt="sharpness x!" class="wallet-footer-image"/>
                  
                   </div>



           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>




























        <div>
        {
          clanRequestPopup2 == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
            
                <img src={colorblindMode ? "./asktojoin1.png" : "./asktojoin.png"} alt="send tokens to minecraft!" class="wallet-header-image"/>
                


                <p className="wallet-box-info" style={{

                top: '25%',
                left: '20%',
                width: '60%',
                height: '25.5%',

                backgroundImage: "url(/Plank.png)",
                backgroundSize: 'auto',
                backgroundPosition: 'center top',
                backgroundRepeat: 'repeat',



                }}>{targetClanMemberName}<br/>
                request to join {`[${filteredClanData.ticker}]`}? <br/>
                this will cancel any previous requests.
                </p>

                <img src={colorblindMode ? "./send1.png" : "./send.png"} alt="create duel" class="clan-create-button" onClick={handleRequestClan}/>







           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>





        <div>
        {
          clanMemberPopupMod == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
            
                <img src={colorblindMode ? "./member1.png" : "./member.png"} alt="send tokens to minecraft!" class="wallet-header-image"/>
                


                <p className="wallet-box-info" style={{

                top: '25%',
                left: '20%',
                width: '60%',
                height: '25.5%',

                backgroundImage: "url(/Plank.png)",
                backgroundSize: 'auto',
                backgroundPosition: 'center top',
                backgroundRepeat: 'repeat',



                }}>target: {targetClanMemberName}<br/>
                Rank: {targetClanMemberRank} <br/>
                </p>

                <img src={colorblindMode ? "./kick1.png" : "./kick.png"} alt="create duel" class="clan-create-button" onClick={handleKickFromClan}
                style={{marginLeft:"-30%", width: "15%"}}/>
                <img src={colorblindMode ? "./demote1.png" : "./demote.png"} alt="create duel" class="clan-create-button" onClick={handleRejectJoinRequest}
                style={{marginLeft:"0%", width: "25%"}}/>
                <img src={colorblindMode ? "./promote1.png" : "./promote.png"} alt="create duel" class="clan-create-button" onClick={handleRejectJoinRequest}
                style={{marginLeft:"33%", width: "25%"}}/>







           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>






        <div>
        {
          clanRequestPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
            
                <img src={colorblindMode ? "./joinrequestsign1.png" : "./joinrequestsign.png"} alt="send tokens to minecraft!" class="wallet-header-image"/>
                


                <p className="wallet-box-info" style={{

                top: '25%',
                left: '20%',
                width: '60%',
                height: '25.5%',

                backgroundImage: "url(/Plank.png)",
                backgroundSize: 'auto',
                backgroundPosition: 'center top',
                backgroundRepeat: 'repeat',



                }}>{targetClanMemberName}<br/>
                want's to join your clan <br/>
                </p>

                <img src={colorblindMode ? "./accept1.png" : "./accept.png"} alt="create duel" class="clan-create-button" onClick={handleAcceptJoinRequest}
                style={{marginLeft:"-25%", width: "22%"}}/>
                <img src={colorblindMode ? "./decline1.png" : "./decline.png"} alt="create duel" class="clan-create-button" onClick={handleRejectJoinRequest}
                style={{marginLeft:"25%", width: "25%"}}/>







           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>


























        <div>
        {
          adminPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
            
                <img src="./adminTitle.png" alt="send tokens to minecraft!" class="wallet-header-image"/>
                
                <div className="flex-container" style={{top:"15%", position: "absolute", width: "80%", left: "10%"}}>
                  <img src={colorblindMode ? "./sendtokens.png" : "./sendtokens.png"} alt="transfer block" className="inventory-button-3" style={{width:"33%", marginTop: "0%"}} onClick={() => handleAdminMode(0)}/>
                  <img src={colorblindMode ? "./unbanplayer.png" : "./unbanplayer.png"} alt="transfer block" className="inventory-button-3" style={{width:"33%", marginTop: "0%"}} onClick={() => handleAdminMode(1)}/>


    </div>

              <div>
            {
              adminMode == 0 ? (<div>                     <img src="./sendAdminTitle.png" alt="sharpness x!" class="wallet-footer-image"/>
             <div className="wallet-box"                    style={{
                top: "40%",
                left: "10%",
                backgroundImage: "url(/woodbackground.png)",
                backgroundSize: 'cover', // Ensures the image covers the entire div
                backgroundPosition: 'center', // Centers the image
                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
              }}>



                                
                          <input
                    type="text"
                    class="wallet-input"
                    required
                    autoComplete="off"
                    value={adminValue}
                    onChange={handleAdminValueChange}
                    placeholder="update amount"


                    style={{

                      backgroundImage: "url(/Plank.png)",
                      backgroundSize: 'cover', // Ensures the image covers the entire div
                      backgroundPosition: 'center', // Centers the image
                      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                      
                                    }}
                                    
                    />












                </div>
















                <div className="wallet-box"                    style={{
                top: "60%",
                left: "10%",
                backgroundImage: "url(/woodbackground.png)",
                backgroundSize: 'cover', // Ensures the image covers the entire div
                backgroundPosition: 'center', // Centers the image
                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
              }}>
                  <img src={colorblindMode ? "./send1.png" : "./send.png"} alt="update username" class="link-button" onClick={adminGiveBalance}/>



                                
                          <input
                    type="text"
                    class="wallet-input"
                    required
                    autoComplete="off"
                    value={adminTarget}
                    onChange={handleAdminTargetChange}
                    placeholder="target address"


                    style={{

                      backgroundImage: "url(/Plank.png)",
                      backgroundSize: 'cover', // Ensures the image covers the entire div
                      backgroundPosition: 'center', // Centers the image
                      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                      
                                    }}
                                    
                    />












                </div>
              </div>) : (<div/>)
            }

              </div>

              <div>
            {
              adminMode == 1 ? (<div>                     <img src="./unbanAdminTitle.png" alt="sharpness x!" class="wallet-footer-image"/>
             
             

             
     





                                    <div className="wallet-box"                    style={{
                top: "60%",
                left: "10%",
                backgroundImage: "url(/woodbackground.png)",
                backgroundSize: 'cover', // Ensures the image covers the entire div
                backgroundPosition: 'center', // Centers the image
                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
              }}>

                                                        
                        <input
                        type="text"
                        class="wallet-input"
                        required
                        autoComplete="off"
                        value={adminTarget}
                        onChange={handleAdminTargetChange}
                        placeholder="target username"


                        style={{

                        backgroundImage: "url(/Plank.png)",
                        backgroundSize: 'cover', // Ensures the image covers the entire div
                        backgroundPosition: 'center', // Centers the image
                        backgroundRepeat: 'no-repeat', // Prevents the image from repeating

                                  }}
                                  
                        />

<img src="./ban.png" alt="update username" class="link-button" onClick={adminUnbanUser}/>

                                </div>



              </div>) : (<div/>)
            }

              </div>


           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>




















        <div>
        {
          createClanPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
            
                <img src={colorblindMode ? "./createClanTitle1.png" : "./createClanTitle.png"} alt="send tokens to minecraft!" class="wallet-header-image"/>
                




                <p className="wallet-box-info" style={{

                top: '10%',
                left: '15%',
                width: '70%',
                height: '57.5%',
    
    backgroundImage: "url(/Plank.png)",
    backgroundSize: 'auto',
    backgroundPosition: 'center top',
    backgroundRepeat: 'repeat',
    
    
    
                }}>Create {clanNameInput}<br/>
                {`[${tickerInput}]`} With Base Color #{baseColorTokenIDInput}<br/><br/>

                Earn {dailyTaxRateInput} coins daily per member<br/>
                trade stocks for your clan<br/><br/>
                5,000 basedMC needed for pool<br/>
                </p>

                <img src="./Create2.png" alt="create duel" class="clan-create-button" onClick={handleCreateClan}/>

                <img src={colorblindMode ? "./tickerWarning1.png" : "./tickerWarning.png"} alt="only do this if logged in!" class="wallet-footer-image"/>
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>



























        <div>
        {
          modPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
            
                <img src="./modTitle.png" alt="send tokens to minecraft!" class="wallet-header-image"/>
                


              <div>
            {
              adminMode == 0 ? (<div>                     <img src="./banInfo.png" alt="sharpness x!" class="wallet-footer-image"/>




                <div className="wallet-box"                    style={{
                top: "60%",
                left: "10%",
                backgroundImage: "url(/woodbackground.png)",
                backgroundSize: 'cover', // Ensures the image covers the entire div
                backgroundPosition: 'center', // Centers the image
                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
              }}>
                  <img src="./ban.png" alt="update username" class="link-button" onClick={modBanUser}/>



                                
                          <input
                    type="text"
                    class="wallet-input"
                    required
                    autoComplete="off"
                    value={adminTarget}
                    onChange={handleAdminTargetChange}
                    placeholder="target username"


                    style={{

                      backgroundImage: "url(/Plank.png)",
                      backgroundSize: 'cover', // Ensures the image covers the entire div
                      backgroundPosition: 'center', // Centers the image
                      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                      
                                    }}
                                    
                    />












                </div>
              </div>) : (<div/>)
            }

              </div>




           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>









        <div>
        {
          settingsPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
            
                <img src={colorblindMode ? "./settingsTitle2.png" : "./settingsTitle.png"} alt="send tokens to minecraft!" class="wallet-header-image"/>
                

                <img src={colorblindMode ? "./colorblind1.png" : "./colorblind2.png"} style={{top:"25%"}} alt="send tokens to minecraft!" class="wallet-header-image"/>




              <div>

              {
                colorblindMode ? (                <div class="wallet-button-container" style={{top:"30%"}}>

                  <img src={colorblindMode ? "./mode2orange.png" : "./mode2blue.png"}  class="wallet-custom-button" />
                  <img src="./mode1white.png" class="wallet-custom-button"onClick={() => handleChangeColor()}/>
                  </div>) : (                <div class="wallet-button-container" style={{top:"30%"}}>

<img src="./mode2white.png"  class="wallet-custom-button" onClick={() => handleChangeColor()}/>
<img src={colorblindMode ? "./mode1orange.png" : "./mode1blue.png"} class="wallet-custom-button"/>
</div>)
              }

              </div>






              <img src={colorblindMode ? "./backgroundsettings1.png" : "./backgroundsettings.png"} style={{top:"60%"}} alt="send tokens to minecraft!" class="wallet-header-image"/>




<div>

{
  !screenMode ? (                <div class="wallet-button-container" style={{top:"75%"}}>

    <img src={colorblindMode ? "./darkorange.png" : "./darkblue.png"}  class="wallet-custom-button" />
    <img src="./lightwhite.png" class="wallet-custom-button"onClick={() => handleChangeDarkness()}/>
    </div>) : (                <div class="wallet-button-container" style={{top:"75%"}}>

<img src="./darkwhite.png"  class="wallet-custom-button" onClick={() => handleChangeDarkness()}/>
<img src={colorblindMode ? "./lightorange.png" : "./lightblue.png"} class="wallet-custom-button"/>
</div>)
}

</div>



           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>
























        <div>
        {
          cleanPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
            
                <img src="./clearTitle.png" alt="send tokens to minecraft!" class="wallet-header-image"/>
                



                <div>
                
                

                <EnchantmentList itemEnchantments={cleanEnchantments}/>

           

              {
                charmEnchantments.length == 0 ? (
                  <div className="wallet-box"                    style={{
                      top: "40%",
                      left: "10%",
                      backgroundImage: "url(/woodbackground.png)",
                      backgroundSize: 'cover', // Ensures the image covers the entire div
                      backgroundPosition: 'center', // Centers the image
                      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    }}>
    
    
                    <img src="./clean.png" alt="use max amount" class="wallet-box-max-button" onClick={() => cleanItem(charmItemID) }/>
    
    
    
                <p className="wallet-box-info" style={{
    
    backgroundImage: "url(/Plank.png)",
    backgroundSize: 'cover', // Ensures the image covers the entire div
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    
    
    
                }}>{tokenBalanceServer}</p>
                <img src={charmImage} class="wallet-box-token"/>
                <div class="wallet-input" style={{
    
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          
                        }}>Wipe all enchantments for 3 tokens?</div>
    
    
                          </div>                
    ) : (<div/>)
              }

   

{
                itemDurability === 0 || itemDurability === itemMaxDurability ? (<div/>) : (
                  <div className="wallet-box"                    style={{
                      top: "70%",
                      left: "10%",
                      backgroundImage: "url(/woodbackground.png)",
                      backgroundSize: 'cover', // Ensures the image covers the entire div
                      backgroundPosition: 'center', // Centers the image
                      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    }}>
    
    
                    <img src="./repair.png" alt="use max amount" class="wallet-box-max-button" onClick={() => repairItem(charmItemID) }/>
    
    
    
                <p className="wallet-box-info" style={{
    
    backgroundImage: "url(/Plank.png)",
    backgroundSize: 'cover', // Ensures the image covers the entire div
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    
    
    
                }}>{itemDurability}/{itemMaxDurability}</p>
                <img src={charmImage} class="wallet-box-token"/>
                <div class="wallet-input" style={{
    
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          
                        }}>Repair item for 15 tokens?</div>
    
    
                          </div> )
              }   

             



              
          </div>







                  <div>
                  


                  <img src="./MaxEnchantments.png" alt="sharpness x!" class="wallet-footer-image"/>
                  
                   </div>



           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>



        <div>
        {
          specialShopPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
            
                <img src={colorblindMode ? "./specialTitle1.png" : "./specialTitle.png"} alt="send tokens to minecraft!" class="wallet-header-image"/>

                <img src={itemImage} class="special-item"/>
                <div>
                
                




              <div className="wallet-box"                    style={{
                  top: "50%",
                  left: "10%",
                  backgroundImage: "url(/woodbackground.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                }}>


                <img src={colorblindMode ? "./buy1.png" : "./buy.png"} alt="use max amount" class="wallet-box-max-button" onClick={() => buySpecialItem(specialShopID)  }/>
              


          


            <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating



            }}>{tokenBalanceServer}</p>
            <div

    class="wallet-input"



    style={{

      backgroundImage: "url(/Plank.png)",
      backgroundSize: 'cover', // Ensures the image covers the entire div
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      
                    }}
                    
    >buy {specialShopName} for {specialShopCost} </div>


                      </div>                


                    

             



              
          </div>







                  <div>
                  



                  
                   </div>



           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>




        <div>
        {
          itemShopPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
            
            {
              itemShopMode == 0 ? (<img src="./listTitle.png" alt="send tokens to minecraft!" class="wallet-header-image"/>) : (<img src="./purchaseTitle.png" alt="send tokens to minecraft!" class="wallet-header-image"/>)
            }
                



                <div>
                
                

                <EnchantmentList itemEnchantments={itemEnchantments} />




              <div className="wallet-box"                    style={{
                  top: "50%",
                  left: "10%",
                  backgroundImage: "url(/woodbackground.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                }}>


                {
                  itemShopMode == 0 ? (<img src="./list.png" alt="use max amount" class="wallet-box-max-button" onClick={() => listItem(itemShopID, itemShopInput) }/>) : (<img src={colorblindMode ? "./buy1.png" : "./buy.png"} alt="use max amount" class="wallet-box-max-button" onClick={() => sendToMinecraft(itemID, itemName, 1, true) }/>)
                }
              




            <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating



            }}>tax: {(itemShopInput * 0.02).toFixed(1)}</p>
            <img src={itemImage} class="wallet-box-token"/>
            <input
    type="text"
    id="numericInput"
    class="wallet-input"
    required
    autoComplete="off"
    value={itemShopInput}
    onChange={handleItemShopChange}
    placeholder="0"


    style={{

      backgroundImage: "url(/Plank.png)",
      backgroundSize: 'cover', // Ensures the image covers the entire div
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      
                    }}
                    
    />


                      </div>                


                    

             



              
          </div>







                  <div>
                  


                  <img src="./listwarning.png" alt="only do this if logged in!" class="wallet-footer-image"/>
                  
                   </div>



           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>





    <div>
        {
          createPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         

                <img src="./CreateQuestion.png" alt="buy or sell tokens" class="wallet-header-image"/>
                
                



                <p className="wallet-box-info" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  width: "60%",
                  height: "20%",
                  top: "10%",
                  left: "20%",
                  }}>
                    Create a duel!<br />
                    you have {tokenBalanceServer} tokens </p>












                  <div>



                  <div className="wallet-box"                    style={{
                    top: "45%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


<img src="./Create2.png" alt="create duel" class="wallet-box-max-button" onClick={handleCreate}/>




<p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

}}>{tokenBalanceServer}</p>
<img src="./Token512.png" class="wallet-box-token"/>
          <input
    type="text"
    id="numericInput"
    class="wallet-input"
    required
    autoComplete="off"
    value={bridgeInput}
    onChange={handleBridgeChange}
    placeholder="0"


    style={{

      backgroundImage: "url(/Plank.png)",
      backgroundSize: 'cover', // Ensures the image covers the entire div
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      
                    }}
                    
    />



</div>

                  </div>
                  <img src="./chanceWarning.png" alt="odds of winning" class="wallet-footer-image"/>


              

 


          
           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>







    <div>
        {
          transferPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         

              <div>

              {

                bridgeMode == 1 ? (<img src={colorblindMode ? "./toServer1.png" : "./toServer.png"} alt="buy or sell tokens" class="wallet-header-image"/>) : (<img src={colorblindMode ? "./toWallet1.png" : "./toWallet.png"} alt="buy or sell tokens" class="wallet-header-image"/>)
              }
              </div>
                
                



                <div>


                {bridgeMode == 1 ? (<p className="wallet-box-info" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  width: "60%",
                  height: "20%",
                  top: "10%",
                  left: "20%",
                  }}>
                    Send from {sendFrom} to {recieveOn}<br />
                    you have {tokenBalanceWallet} tokens </p>
                  ) : (<p className="wallet-box-info" style={{
                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    width: "60%",
                    height: "20%",
                    top: "10%",
                    left: "20%",
                    }}>
                      Send from {sendFrom} to {recieveOn}<br />
                      you have {tokenBalanceServer} tokens </p>
                  )}

                </div>













                  <div>



                  {


bridgeMode == 1 ? (<div>
  
  <div className="wallet-box"                    style={{
                    top: "40%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


{

approvedTokensBridge >= bridgeInput*10**18? (<img src={colorblindMode ? "./send1.png" : "./send.png"} alt="use max amount" class="wallet-box-max-button" onClick={handleBridge}/>) : (<img src="./Approve.png" alt="use max amount" class="wallet-box-max-button" onClick={() => approveBridge(basecraftAddress)}/>)
}




<p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

}}>{tokenBalanceWallet}</p>
<img src="./Token512.png" class="wallet-box-token"/>
          <input
    type="text"
    id="numericInput"
    class="wallet-input"
    required
    autoComplete="off"
    value={bridgeInput}
    onChange={handleBridgeChange}
    placeholder="tokens to bridge:"


    style={{

      backgroundImage: "url(/Plank.png)",
      backgroundSize: 'cover', // Ensures the image covers the entire div
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      
                    }}
                    
    />



</div>

  
  
  </div>) : (<div>
  
  <div className="wallet-box"                    style={{
                    top: "40%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>

<img src={colorblindMode ? "./send1.png" : "./send.png"} alt="use max amount" class="wallet-box-max-button" onClick={() => gasFreeBridge(account)}/>

    



<p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

}}>{tokenBalanceServer}</p>
<img src="./Token512.png" class="wallet-box-token"/>
          <input
    type="text"
    id="numericInput"
    class="wallet-input"
    required
    autoComplete="off"
    value={bridgeInput}
    onChange={handleBridgeChange}
    placeholder="tokens to bridge:"


    style={{

      backgroundImage: "url(/Plank.png)",
      backgroundSize: 'cover', // Ensures the image covers the entire div
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      
                    }}
                    
    />



</div>

  
  
  </div>)

}



                  </div>









    <img src={colorblindMode ? "./Swap1.png" : "./Swap.png"} class="wallet-swap-button" onClick={handleSwitch} style={{top: "60%"}}/>














    <div>




{

  bridgeMode == 0 ? (<img src={colorblindMode ? "./gasFree1.png" : "./gasFree.png"} alt="you need gas for this" class="wallet-footer-image"/>) : (
  <div>

    {
    ethBalance == 0 ? (<img src={colorblindMode ? "./gasWarning1.png" : "./gasWarning.png"} alt="you need gas for this" class="wallet-footer-image"/>) : (<img src={colorblindMode ? "./gasAllowed1.png" : "./gasAllowed.png"} alt="you need gas for this" class="wallet-footer-image"/>)
    }
    </div>)
}

</div>



              

 


          
           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>


        <div>
        {
          rankupPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         
                <img src="./rankupTitle.png" alt="send tokens to minecraft!" class="wallet-header-image"/>

                <img src={nextRankImage} alt="next rank" class="next-rank-image"/>

                <p className="wallet-box-info" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  width: "60%",
                  height: "20%",
                  top: "20%",
                  left: "20%",
                  }}>
                    faster /tpa, /rtp, and silk spawner<br />
                    item shop listing slots: {(rank + 1 ) * 2} </p>


    
<div className="wallet-box"                    style={{
                    top: "50%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                <img src={colorblindMode ? "./buy1.png" : "./buy.png"} alt="use max amount" class="wallet-box-max-button" onClick={handleRankup}/>




              <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{tokenBalanceServer}</p>
              <img src="./Token512.png" class="rankup-token"/>
                        <div

                  class="wallet-input"
                  placeholder="0 Eth"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  >This rankup costs {nextRankCost} server tokens</div>


                        </div>                








                  <div>
                  


                  <img src="./rankupNote.png" alt="only do this if logged in!" class="wallet-footer-image"/>
                  
                   </div>



           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>

        <div>
        {
          buyPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         
                <img src={colorblindMode ? "./auctionTitle1.png" : "./auctionTitle.png"} alt="send tokens to minecraft!" class="wallet-header-image"/>



                <div>
                
                

                <EnchantmentList itemEnchantments={buyEnchantments} />




              <div className="wallet-box"                    style={{
                  top: "50%",
                  left: "10%",
                  backgroundImage: "url(/woodbackground.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                }}>


              <img src={colorblindMode ? "./buy1.png" : "./buy.png"} alt="use max amount" class="wallet-box-max-button" onClick={() =>  buyItem(buyID) }/>




            <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

            }}>{tokenBalanceServer}</p>
            <img src={buyImage} class="wallet-box-token"/>
                      <div
                class="wallet-input"
                style={{

                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  fontSize:" clamp(0.5rem, 2.5vw, 2rem)" /* Scale font size responsively */
                                }}
                                
                > Buy {itemName}? <br/>for {buyPrice} tokens ${(buyPrice * tokenPrice).toFixed(2)}</div>


                      </div>                




             



              
          </div>








                  <div>
                  


                  <img src="./stableNote.png" alt="only do this if logged in!" class="wallet-footer-image"/>
                  
                   </div>



           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>


        <div>
        {
          sendPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         
                <img src="./sendTitle.png" alt="send tokens to minecraft!" class="wallet-header-image"/>



            <div>

            {

              itemID == 0 ? (<div>
                
                

                    <p className="wallet-box-info" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  width: "60%",
                  height: "20%",
                  top: "15%",
                  left: "20%",
                  fontSize:" clamp(0.5rem, 2.5vw, 2rem)" /* Scale font size responsively */
                  }}>
                    Send {itemAmount} x {blockID}<br />
                    to your minecraft inventory? </p>






<div className="wallet-box"                    style={{
                    top: "50%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                <img src={colorblindMode ? "./max1.png" : "./max.png"} alt="use max amount" class="wallet-box-max-button" onClick={() => setMaxSend(itemMaxBalance)}/>




              <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{itemMaxBalance}</p>
              <img src={itemImage} class="wallet-box-token"/>
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0 Eth"
                  value={itemAmount}
                  onChange={handleSendChange}
                  required
                  autoComplete="off"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />


                        </div>                


                        <img src={colorblindMode ? "./send1.png" : "./send.png"} alt="send blocks" class="send-button" onClick={() => sendToMinecraft(0, blockID, itemAmount,  false) }/>



                    
                </div>) : (<div>
                
                

                  <EnchantmentList itemEnchantments={itemEnchantments} />




                <div className="wallet-box"                    style={{
                    top: "50%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                <img src={colorblindMode ? "./send1.png" : "./send.png"} alt="use max amount" class="wallet-box-max-button" onClick={() => sendToMinecraft(itemID, itemName, 1, true) }/>




              <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{itemMaxBalance}</p>
              <img src={itemImage} class="wallet-box-token"/>
                        <div
                  class="wallet-input"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    fontSize:" clamp(0.5rem, 2.5vw, 2rem)" /* Scale font size responsively */
                                  }}
                                  
                  > Send {itemName} to your minecraft inventory? </div>


                        </div>                




               



                
            </div>)
            }

            </div>








                  <div>
                  


                  <img src="./sendNote.png" alt="only do this if logged in!" class="wallet-footer-image"/>
                  
                   </div>



           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>



        <div>
        {
          curvePopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         
                <div>

                {

                   bondingCurveMode == 0 ? (<img src={colorblindMode ? "./bondingTitle0.png" : "./bondingTitle01.png"} alt="buy or sell tokens" class="wallet-header-image"/>) : (<img src={colorblindMode ? "./bondingTitle1.png" : "./bondingTitle11.png"} alt="sell tokens for cash" class="wallet-header-image"/>)
                }
                </div>
              
                
                {
                  bondingCurveMode == 0 ? (                <p className="wallet-box-info" style={{
                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    width: "60%",
                    height: "20%",
                    top: "50%",
                    left: "20%",
                    }}>
                      Buy {bondingCurveInput} {(bondingCurveName).substring(0, 9)}: {calculateTotalCostBuy(bondingCurveTokens, bondingCurveBlocks, bondingCurveInput, 1.02)}<br />
                      valued at ${(calculateTotalCostBuy(bondingCurveTokens, bondingCurveBlocks, bondingCurveInput, 1.02) * tokenPrice).toFixed(5)}
                      <img src={bondingCurveImage} class="wallet-box-token2"/>
                      </p>) : (                <p className="wallet-box-info" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  width: "60%",
                  height: "20%",
                  top: "50%",
                  left: "20%",
                  }}>
                    Sell {bondingCurveInput} {(bondingCurveName).substring(0, 9)}: {calculateTotalCostSell(bondingCurveTokens, bondingCurveBlocks, bondingCurveInput, 1.08)}<br />
                    valued at ${(calculateTotalCostSell(bondingCurveTokens, bondingCurveBlocks, bondingCurveInput, 1.08) * tokenPrice).toFixed(5)}
                    <img src="./Token512.png" class="wallet-box-token2"/>
                    </p>)
                }


                <div className="wallet-box"                    style={{
                    top: "25%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                  {
                    bondingCurveMode == 0 ? (<img src={colorblindMode ? "./max1.png" : "./max.png"} alt="use max amount" class="wallet-box-max-button" onClick={() => handleSetMaxBondingBuy(curveBalance)}/>) : (<img src={colorblindMode ? "./max1.png" : "./max.png"} alt="use max amount" class="wallet-box-max-button" onClick={() => handleSetMaxBondingSell(curveBalance)}/>)
                  }
                


                  {
                    bondingCurveMode == 0 ? (              <p className="wallet-box-info" style={{

                      backgroundImage: "url(/Plank.png)",
                      backgroundSize: 'cover', // Ensures the image covers the entire div
                      backgroundPosition: 'center', // Centers the image
                      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      
                    }}>{tokenBalanceServer}</p>) : (              <p className="wallet-box-info" style={{

                      backgroundImage: "url(/Plank.png)",
                      backgroundSize: 'cover', // Ensures the image covers the entire div
                      backgroundPosition: 'center', // Centers the image
                      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      
                    }}>{curveBalance}</p>)
                  }


                            {
                              bondingCurveMode == 0 ? (<img src="./Token512.png" class="wallet-box-token"/>) : (<img src={bondingCurveImage} class="wallet-box-token"/>)
                            }
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0"
                  value={bondingCurveInput}
                  onChange={handleBondingChange}
                  required
                  autoComplete="off"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />




                        </div> 

                      {
                        bondingCurveMode == 0 ? (<img src={colorblindMode ? "./buy1.png" : "./buy.png"} alt="use max amount" class="trade-button" onClick={buyBondingCurve}/>) : (<img src={colorblindMode ? "./sell1.png" : "./sell.png"} alt="use max amount" class="trade-button" onClick={sellBondingCurve}/>)
                      }
                        

                        <img src={colorblindMode ? "./Swap1.png" : "./Swap.png"} class="wallet-swap-button" onClick={handleChangeBonding}/>

      
           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>

























    <div>
        {
          sellPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         
                <div>

                {

                  sellPath == 0 ? (<img src={colorblindMode ? "./buyTitle1.png" : "./buyTitle.png"} alt="buy or sell tokens" class="wallet-header-image"/>) : (<img src={colorblindMode ? "./buyTitle1b.png" : "./buyTitleb.png"} alt="sell tokens for cash" class="wallet-header-image"/>)
                }
                </div>
                

                <div>
                {
                  sellPath == 0 ? (                
                  <div class="wallet-button-container">

                  <img src="./serverGray.png"  class="wallet-custom-button" onClick={() => handleChangePath(1)}/>
                  <img src={colorblindMode ? "./walletOrange.png" : "./walletGreen.png"} class="wallet-custom-button"/>
                </div>) : (                
                <div class="wallet-button-container">

                <img src={colorblindMode ? "./serverOrange.png" : "./serverGreen.png"}  class="wallet-custom-button"/>
                <img src="./walletGray.png" class="wallet-custom-button" onClick={() => handleChangePath(0)}/>
                </div>)
                }
                </div>
                <div>

                {
                  sellPath == 0 ? (<div>
                  


                  <img src={colorblindMode ? "./routeNote1.png" : "./routeNote.png"} alt="this route uses uniswap" class="wallet-footer-image"/>
                  
                   </div>) : (<div>


                    <p className="wallet-box-info" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  width: "60%",
                  height: "20%",
                  top: "25%",
                  left: "20%",
                  }}>
                    Sell tokens directly from the server<br />
                    This uses tokens for gas! </p>




                    <div className="wallet-box"                    style={{
                    top: "55%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                <img src={colorblindMode ? "./sell1.png" : "./sell.png"} alt="use max amount" class="wallet-box-max-button" onClick={() => gasFreeSwap(account)}/>




              <p className="wallet-box-info" style={{

                backgroundImage: "url(/Plank.png)",
                backgroundSize: 'cover', // Ensures the image covers the entire div
                backgroundPosition: 'center', // Centers the image
                backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{tokenBalanceServer}</p>
                            <img src="./Token512.png" class="wallet-box-token"/>
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0"
                  value={amountIn}
                  onChange={handleInputChangeOut}
                  required
                  autoComplete="off"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />



                        </div>  


                   <img src={colorblindMode ? "./gasFree1.png" : "./gasFree.png"} alt="this route has a fee" class="wallet-footer-image"/>
                  
                   </div>)
                }
                </div>

                <div>
                {

                  sellPath == 0 ? (<div> 



                    <div>



                    {


                      swapMode == 0 ? (<div>
                        


                        <div className="wallet-box"                    style={{
                    top: "30%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                <img src={colorblindMode ? "./max1.png" : "./max.png"} alt="use max amount" class="wallet-box-max-button" onClick={() => setMaxValueIn(ethBalance)}/>




              <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{ethBalance}</p>
              <img src="./ETH.png" class="wallet-box-token"/>
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0 Eth"
                  value={amountIn}
                  onChange={handleInputChangeIn}
                  required
                  autoComplete="off"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />


                        </div>                











                  <img src={colorblindMode ? "./Swap1.png" : "./Swap.png"} class="wallet-swap-button" onClick={changeSwap}/>









                        

                  <div className="wallet-box"                    style={{
                    top: "65%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                <img src={colorblindMode ? "./bridge1b.png" : "./bridgeb.png"} alt="use max amount" class="wallet-box-max-button" onClick={swapETHtoToken}/>




              <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{tokenBalanceWallet}</p>
              <img src="./Token512.png" class="wallet-box-token"/>
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0 Eth"
                  value={amountOut}
                  onChange={handleInputChangeIn}
                  required
                  disabled={true}
                  autoComplete="off"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />


                        </div>                




                        
                        </div>) :
                        
                        
                        
                        
                        
                        
                        (<div>
                        


                        <div className="wallet-box"                    style={{
                    top: "30%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                <img src={colorblindMode ? "./max1.png" : "./max.png"} alt="use max amount" class="wallet-box-max-button" onClick={() => setMaxValueOut(tokenBalanceWallet)}/>




              <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{tokenBalanceWallet}</p>
              <img src="./Token512.png" class="wallet-box-token"/>
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0"
                  value={amountIn}
                  onChange={handleInputChangeOut}
                  required
                  autoComplete="off"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />


                        </div>                











                  <img src={colorblindMode ? "./Swap1.png" : "./Swap.png"} class="wallet-swap-button" onClick={changeSwap}/>









                        

                  <div className="wallet-box"                    style={{
                    top: "65%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                  <div>
                  {

                    approvedTokensSwap >= amountIn*10**18 ? (<img src={colorblindMode ? "./bridge1b.png" : "./bridgeb.png"} alt="use max amount" class="wallet-box-max-button" onClick={swapTokenToETH}/>) : (<img src="./Approve.png" alt="use max amount" class="wallet-box-max-button" onClick={() => approveSwap(basecraftAddress)}/>)
                  }
                  </div>
                




              <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{ethBalance}</p>
              <img src="./ETH.png" class="wallet-box-token"/>
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0 Eth"
                  value={amountOut}
                  onChange={handleInputChangeOut}
                  required
                  autoComplete="off"
                  disabled={true}

                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />


                        </div>                




                        
                        </div>)
                    }


                    </div>
  
                              
                    </div>) : (<div>
                      
                      
                      
                      </div>) 
                }

                </div>


      
           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>



    <div>
        {
          mainPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="popup">
                <img src={mainPopupPath} className="popupImage" alt="Bring Metamask" />
                <div className="popupTitle">{mainPopupTitle}</div>
                <button className="button-68" onClick={handleInfoButton}>{mainPopupButtonText}</button>
                <div className="popupFooter">{mainPopupDescription}</div>
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>


        <div>
        {
          loginPopup == true ? (<div>
            <div className="popup-overlay">
            {
              account == null ? (<div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         
                <img src="./connectTitle.png" alt="connect your wallet" class="wallet-header-image"/>



                <div>
                
          


                <div className="wallet-box"                    style={{
                  top: "25%",
                  left: "10%",
                  backgroundImage: "url(/woodbackground.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                }}>


              <img src="./logintext.png" alt="use max amount" class="wallet-box-max-button" onClick={() =>  handleLogin2() } style={{top:"37.5%"}}/>

            <img src="./smartWallet.png" class="login-image"/>
                      <div
                class="wallet-input"
                style={{

                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  fontSize:" clamp(0.5rem, 2.5vw, 2rem)" /* Scale font size responsively */
                                }}
                                
                >Coinbase Smart Wallet {"(easy for beginners)"}</div>


                      </div>  






                      <div className="wallet-box"                    style={{
                  top: "55%",
                  left: "10%",
                  backgroundImage: "url(/woodbackground.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                }}>


              <img src="./logintext.png" alt="use max amount" class="wallet-box-max-button" onClick={handleLogin} style={{top:"37.5%"}}/>

            <img src="./metamask.png" class="login-image"/>
                      <div
                class="wallet-input"
                style={{

                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  fontSize:" clamp(0.5rem, 2.5vw, 2rem)" /* Scale font size responsively */
                                }}
                                
                >Wallet Extension {"(coinbase or metamask)"}</div>


                      </div>  





             



              
          </div>








                  <div>
                  


                  <img src="./walletNote.png" alt="only do this if logged in!" class="wallet-footer-image"/>
                  
                   </div>



           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closeLogin}/>
                </div>) : (<div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         
                <img src="./verifyTitle.png" alt="connect your wallet" class="wallet-header-image"/>



                <div>
                
          
                {
                  walletMode == 0 ? (
                    <div className="wallet-box"                    style={{
                      top: "40%",
                      left: "10%",
                      backgroundImage: "url(/woodbackground.png)",
                      backgroundSize: 'cover', // Ensures the image covers the entire div
                      backgroundPosition: 'center', // Centers the image
                      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    }}>
    
    
                  <img src="./sign.png" alt="use max amount" class="wallet-box-max-button" onClick={() => signWallet(account)} style={{top:"37.5%"}}/>
    
                <img src="./metamask.png" class="login-image"/>
                          <div
                    class="wallet-input"
                    style={{
    
                      backgroundImage: "url(/Plank.png)",
                      backgroundSize: 'cover', // Ensures the image covers the entire div
                      backgroundPosition: 'center', // Centers the image
                      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                      fontSize:" clamp(0.5rem, 2.5vw, 2rem)" /* Scale font size responsively */
                                    }}
                                    
                    >Sign with your extension wallet</div>
    
    
                          </div>  
    ) : (
      <div className="wallet-box"                    style={{
        top: "40%",
        left: "10%",
        backgroundImage: "url(/woodbackground.png)",
        backgroundSize: 'cover', // Ensures the image covers the entire div
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      }}>


    <img src="./sign.png" alt="use max amount" class="wallet-box-max-button" onClick={() => signSmartWallet(account)} style={{top:"37.5%"}}/>

  <img src="./smartWallet.png" class="login-image"/>
            <div
      class="wallet-input"
      style={{

        backgroundImage: "url(/Plank.png)",
        backgroundSize: 'cover', // Ensures the image covers the entire div
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat', // Prevents the image from repeating
        fontSize:" clamp(0.5rem, 2.5vw, 2rem)" /* Scale font size responsively */
                      }}
                      
      >Sign with coinbase smart wallet</div>


            </div>  
)
                }



             



              
          </div>








                  <div>
                  


                  <img src="./secureNote.png" alt="only do this if logged in!" class="wallet-footer-image"/>
                  
                   </div>



           
                <img src={colorblindMode ? "./close1.png" : "./close.png"} className="button-5" alt="close" onClick={closeLogin}/>
                </div>)
            }

          </div> 


          
            </div>) : (<div/>)

        }
        </div>


        <div>

        {

          page == "Home" ? (<div className="wallet-container"><div className="wallet-content" style={{
            backgroundImage: `url(${screenMode ? "./Background2.png" : "./Background6.png"})`,
            backgroundSize: 'auto', // Maintains the original size of the image
            backgroundPosition: 'center top', // Starts the image from the top center
            backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
          }}>


<div className="home-content" style={{
  backgroundImage: "url(/BackgroundWater.png)",
  backgroundSize: 'auto', // Maintains the original size of the image
  backgroundPosition: 'center top', // Starts the image from the top center
  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
}}>



<div className="baltop-item" style={{
          backgroundImage: "url(/invis.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally

          height: "1vw"
        }}>

          
<img src="./invis.png" alt="this route uses uniswap"style={{
          position: "inherit",
          width: "100%"
        }}/>

      
        </div>


<div className="baltop-item" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally

          height: "5vw"
        }}>

          
<div className="flex-container">
                  <img src={colorblindMode ? "./chart1.png" : "./chart.png"} alt="transfer block" className="inventory-button-3" style={{width:"15%", marginTop: "0%"}} onClick={() => openLinkInNewTab('https://dexscreener.com/base/0x45fe440d3d212c1c0f430f7df7e393e0647dad82')}/>
                  <img src={colorblindMode ? "./discord1.png" : "./discord.png"} alt="transfer block" className="inventory-button-3" style={{width:"20%", marginTop: "0%"}} onClick={() => openLinkInNewTab('https://discord.gg/Gqun73fcKj')}/>
                  <img src={colorblindMode ? "./twitter1.png" : "./twitter.png"} alt="transfer block" className="inventory-button-3"style={{width:"20%", marginTop: "0%"}} onClick={() => openLinkInNewTab('https://x.com/based_minecraft')}/>
                  <img src={colorblindMode ? "./telegram1.png" : "./telegram.png"} alt="transfer block" className="inventory-button-3" style={{width:"20%", marginTop: "0%"}} onClick={() => openLinkInNewTab('https://t.me/+kIE7Q-DfXF5mMjRh')}/>



    </div>
         

      
        </div>



        <div className="baltop-item" style={{
          backgroundImage: "url(/invis.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally

          height: "5vw"
        }}>

          
<img src="./invis.png" alt="this route uses uniswap"style={{
          position: "inherit",
          width: "100%"
        }}/>

      
        </div>



        <div className="baltop-item" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally

          height: "20vw"
        }}>








                            



<p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  left: "0%",
                  height: "20vw",
                  width: "100vw"
                  }}>Season 1 of based minecraft is here!<br/>{tokensInCurves.toFixed(1)} tokens can be earned from playing!<br/> join our server on minecraft 1.20.1 with the IP:<br/> 15.204.150.11<br/>OR

<img src="./serverIp.png" alt="season1"style={{
          position: "inherit",
          width: "100%",
          marginTop: "5%",
        }}/>
                  </p>
                  


                  
        </div>








        <div className="baltop-item" style={{
          backgroundImage: "url(/invis.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally

          height: "5vw"
        }}>

          
<img src="./invis.png" alt="this route uses uniswap"style={{
          position: "inherit",
          width: "100%"
        }}/>

      
        </div>





        <div className="baltop-item" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally

          height: "20vw"
        }}>




<p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  left: "0%",

                  height: "20vw",
                  width: "100vw"
                  }}>Season 1 is a 3000x3000 map with pvp enabled and no safezones.  raiding, griefing, pvp, trapping are allowed!
                  <br></br>bug abuse, and cheating will be punished by moderators who are watching in game!

<img src="./season1.png" alt="transfer block" className="inventory-button-3" style={{
          position: "inherit",
          width: "100%",
          marginTop: "5%",
        }}/>


                  </p>
                  


                  
        </div>









        <div className="baltop-item" style={{
          backgroundImage: "url(/invis.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally

          height: "5vw"
        }}>

          
<img src="./invis.png" alt="this route uses uniswap"style={{
          position: "inherit",
          width: "100%",
          
        }}/>

      
        </div>













        <div className="baltop-item" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally

          height: "20vw"
        }}>








                            



<p className="setup-description5" style={{
                  backgroundImage: "url(/invis.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  left: "0%",

                  height: "20vw",
                  width: "100vw"
                  }}>This project was built for the Base Onchain Summer Buildathon!<br/>We have adheered to minecrafts TOS and utilized features like gas free swaps to make a play to earn game anyone can experience, even a person with 0 eth!

<img src="./onchainsummer.png" alt="transfer block" className="inventory-button-3" style={{
          position: "inherit",
          width: "100%",
          marginTop: "5%",
        }} onClick={() => openLinkInNewTab('https://devfolio.co/projects/based-minecraft-15ba')}/>


                  </p>
                  


                  
        </div>






















        <div className="baltop-item" style={{
          backgroundImage: "url(/invis.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally

          height: "5vw"
        }}>

          
<img src="./invis.png" alt="this route uses uniswap"style={{
          position: "inherit",
          width: "100%",
          
        }}/>

      
        </div>










        </div>

<div className="info-box" style={{
  backgroundImage: "url(/BackgroundGrass.png)",
  backgroundSize: 'auto', // Maintains the original size of the image
  backgroundPosition: 'center top', // Starts the image from the top center
  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
}}>
            <p className="setup-description6" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`there are ${onlinePlayers} players in game!`}</p>






</div>



<div className="baltop-content" style={{
  backgroundImage: "url(/BackgroundSand.png)",
  backgroundSize: 'auto', // Maintains the original size of the image
  backgroundPosition: 'center top', // Starts the image from the top center
  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
}}>

  
<p className="setup-description6" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`leaderboard:`}</p>
          {Array.isArray(baltopData) ? <BaltopList balances={baltopData} /> : <p>Loading...</p>}
        </div>

        <div className="events-content" style={{
  backgroundImage: "url(/BackgroundGrass.png)",
  backgroundSize: 'auto', // Maintains the original size of the image
  backgroundPosition: 'center top', // Starts the image from the top center
  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
}}>
  
<p className="setup-description6" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`events:`}</p>
           {Array.isArray(eventsData) ? <EventList events={eventsData} /> : <p>Loading...</p>}
        </div>

          </div></div>) : (<div/>)


        }



        {

        page == "Wallet" || page == "Duel" || page == "Shop" || page == "Clan"  ? (<div>
          
          
          
          <div className="wallet-container">
          <div className="wallet-content" style={{
  backgroundImage: `url(${screenMode ? "./Background2.png" : "./Background6.png"})`,
  backgroundSize: 'auto', // Maintains the original size of the image
  backgroundPosition: 'center top', // Starts the image from the top center
  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
}}>



          {
            page == "Duel" ? (          <div className="duel-content" >

            <div className="scrollable-content" style={{
  backgroundImage: `url(${!screenMode ? "./Background4.png" : "./Background3.png"})`,
  backgroundSize: 'auto', // Maintains the original size of the image
  backgroundPosition: 'center top', // Starts the image from the top center
  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
}}>
          {Array.isArray(AllDuels) ? <DuelList duels={AllDuels} /> : <p>Loading...</p>}
        </div>

        <img src={colorblindMode ? "./duelNote1.png" : "./duelNote.png"} alt="Player Rank" className="duel-title" onClick={()=>newPopup("Risk your tokens versus other players!", "Earn tokens for free by playing minecraft!", "./BringMetamask.png", "Get Tokens!", 1)}/>
     

            {
              ourDuel == 0 ? (<img src="./Create.png" alt="create duel" className="duel-footer" onClick={openCreate}/>) : (<img src={colorblindMode ? "./Remove1.png" : "./remove.png"} alt="remove duel" className="duel-footer" onClick={handleRemove}/>)
            }

        
   
              
        

        </div>) : (
          

          <div className="duel-content" >

            {
              page == "Wallet" ? ( <div className="scrollable-content" style={{
                backgroundImage: `url(${!screenMode ? "./Background4.png" : "./Background3.png"})`,
                backgroundSize: 'auto', // Maintains the original size of the image
                backgroundPosition: 'center top', // Starts the image from the top center
                backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
              }}>
            {itemsData && blocksData ? (
              <div>
                {searchTerm !== "" ? (
                  <InventoryList tools={filteredItemsData} blocks={filteredBlocksData} />
                ) : (
                  <InventoryList tools={itemsData} blocks={blocksData} />
                )}
              </div>
            ) : (
              <p>Loading...</p>
            )}


                        <div className="wallet-box"                    style={{
                bottom: "5%",
                left: "1.875%",
                width: "70%",
                height: "5%",
                padding: "10px",
                backgroundImage: "url(/woodbackground.png)",
                backgroundPosition: 'center', // Centers the image
           
              }}>


<img src={colorblindMode ? "./clear1.png" : "./clear.png"} alt="create duel" class="wallet-box-clear-button" onClick={handleReset}/>

<input
  type="text"
  id="letterInput"
  class="wallet-input"
  required
  autoComplete="off"
  value={searchTerm}
  onChange={handleSearchChange2}
  placeholder="Search..."
  pattern="[A-Za-z]*"
  style={{
    backgroundImage: "url(/Plank.png)",

    backgroundPosition: 'center', // Centers the image

  }}
/>



</div>


                      </div>) : (<div/>)
            }






































{
          page == "Clan" ? (
          
          

          <div className="clan-content">



{
            clanCreation && !clanView ? (            <div className="scrollable-content-clan" style={{
              backgroundImage: `url(${!screenMode ? "./Background4.png" : "./Background3.png"})`,
              backgroundSize: 'auto', // Maintains the original size of the image
              backgroundPosition: 'center top', // Starts the image from the top center
              backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally

              
            }}>
                    
                    <div className="clan-box"                    style={{
    top: "25%",
    left: "20%",
    width: "40%",
    backgroundImage: "url(/woodbackground.png)",
    backgroundSize: 'cover', // Ensures the image covers the entire div
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  }}>      
              <input
        type="text"
        class="clan-input"
        required
        autoComplete="off"
        value={tickerInput}
        onChange={handleTickerChange}
        placeholder="what's the ticker?"


        style={{

          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          
                        }}
                        
        />



    </div>



    <div className="clan-box"                    style={{
    top: "35%",
    left: "20%",
    width: "40%",
    backgroundImage: "url(/woodbackground.png)",
    backgroundSize: 'cover', // Ensures the image covers the entire div
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  }}>      
              <input
        type="text"
        class="clan-input"
        required
        autoComplete="off"
        value={clanNameInput}
        onChange={handleClanNameChange}
        placeholder="what's your clan's name?"


        style={{

          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          
                        }}
                        
        />



    </div>

    <div className="clan-box"                    style={{
    top: "45%",
    left: "20%",
    width: "40%",
    backgroundImage: "url(/woodbackground.png)",
    backgroundSize: 'cover', // Ensures the image covers the entire div
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  }}>      
              <input
        type="text"
        class="clan-input"
        required
        autoComplete="off"
        value={clanDescInput}
        onChange={handleClanDescChange}
        placeholder="brief clan description."


        style={{

          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          
                        }}
                        
        />



    </div>
    <div className="clan-box"                    style={{
    top: "55%",
    left: "20%",
    width: "40%",
    backgroundImage: "url(/woodbackground.png)",
    backgroundSize: 'cover', // Ensures the image covers the entire div
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  }}>      
              <input
        type="text"
        class="clan-input"
        required
        autoComplete="off"
        value={dailyTaxRateInput}
        onChange={handleTaxRateChange}
        placeholder="daily member fee (10-100)"


        style={{

          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          
                        }}
                        
        />



    </div>

    <div className="clan-box"                    style={{
    top: "65%",
    left: "20%",
    width: "40%",
    height: "15%",
    backgroundImage: "url(/woodbackground.png)",
    backgroundSize: 'cover', // Ensures the image covers the entire div
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  }}>      
<div className="basecolors-content" style={{
  backgroundImage: "url(/Plank.png)",
  backgroundSize: 'auto', // Maintains the original size of the image
  backgroundPosition: 'center top', // Starts the image from the top center
  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
  left: "10%",
  width: "80%",
  height: "50%",
  top: "10%"
}}>


          {Array.isArray(basecolorsData) ? <BasecolorList balances={basecolorsData} /> : <p>Loading...</p>}
        </div>


    </div>
    <div className="clan-box"                    style={{
    top: "75%",
    left: "20%",
    width: "40%",
    backgroundImage: "url(/woodbackground.png)",
    backgroundSize: 'cover', // Ensures the image covers the entire div
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  }}>      




<div className="inventory-buttons" style={{left:"60%", width: "80%", top: "50%"}}>
            <div><img src={colorblindMode ? "./Create2b.png" : "./Create2.png"} alt="sell block" className="inventory-button-1" onClick={openCreateClan} />
            </div>
            <div> <img src={colorblindMode ? "./colors1.png" : "./colors.png"} alt="transfer block" className="inventory-button-1" onClick={() => openLinkInNewTab("https://www.basecolors.com/")} /></div>
           
          </div>

          <img src={colorblindMode ? "./colorpicker1.png" : "./colorpicker.png"}
          style={{width:"50%", left: "25%", marginTop: "-2.5%", marginLeft: "0%"}} alt="Player Rank" className="clan-title"/>
 

    </div>

                    </div>) : (<div/>)
          }




        {
            !clanCreation && !clanView ? (            <div className="scrollable-content-clan" style={{
              backgroundImage: `url(${!screenMode ? "./Background4.png" : "./Background3.png"})`,
              backgroundSize: 'auto', // Maintains the original size of the image
              backgroundPosition: 'center top', // Starts the image from the top center
              backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally


              
            }}>



<div className="clan-box"                    style={{
  position: "absolute",
    top: "0%",
    width: "100%",
    height: "7%",
    backgroundImage: "url(/Plank.png)",
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'repeat', // Prevents the image from repeating
  }}>  



  </div>

  <div className="clan-box-scroll"                    style={{
  position: "absolute",
    top: "7%",
    left: "0%",
    width: "70%",
    height: "53%",
    backgroundImage: "url(/BackgroundSand.png)",
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'repeat', // Prevents the image from repeating
  }}>  
{Array.isArray(clanData) ? <ClansList clans={clanData} /> : <p>Loading...</p>}


  </div>

  <div className="clan-box-scroll"                    style={{
  position: "absolute",
    top: "60%",
    left: "0%",
    width: "70%",
    height: "40%",
    backgroundImage: "url(/BackgroundWater.png)",
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'repeat', // Prevents the image from repeating
  }}>  

{Array.isArray(fakeKills) ? <KillsList kills={fakeKills} /> : <p>Loading...</p>}

  </div>


<div className="clan-box"                    style={{
  position: "absolute",
    top: "7%",
    right: "0%",
    width: "30%",
    height: "93%",
    backgroundImage: "url(/BackgroundGrass.png)",
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'repeat', // Prevents the image from repeating
  }}>  



  </div>





                    </div>) : (<div/>)
          }


        {
            !clanCreation && clanView ? (            <div className="scrollable-content-clan" style={{
              backgroundImage: `url(${!screenMode ? "./Background4.png" : "./Background3.png"})`,
              backgroundSize: 'auto', // Maintains the original size of the image
              backgroundPosition: 'center top', // Starts the image from the top center
              backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally

              
            }}>



<div className="clan-box"                    style={{
  position: "absolute",
    top: "0%",
    width: "100%",
    height: "7%",
    backgroundImage: "url(/Plank.png)",
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'repeat', // Prevents the image from repeating
  }}>  



  </div>

  <div className="clan-box-scroll"                    style={{
  position: "absolute",
    top: "7%",
    left: "0%",
    width: "70%",
    height: "53%",
    backgroundImage: "url(/BackgroundSand.png)",
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'repeat', // Prevents the image from repeating
  }}>  

{
  clanActionMode == 0 ? (
    <div className="clan-box"                    style={{
      top: "37.5%",
      left: "2.5%",
      width: "95%",
      height: "60%",
      backgroundImage: "url(/Background3.png)",
      backgroundSize: 'auto', // Maintains the original size of the image
      backgroundPosition: 'center', // Starts the image from the top center
      backgroundRepeat: 'repeat', // Prevents the image from repeating
    }}>   
  
  
  
  
    </div>
  ) : (
    <div className="clan-box"                    style={{
      top: "37.5%",
      left: "2.5%",
      width: "95%",
      height: "60%",
      backgroundImage: "url(/Background.png)",
      backgroundSize: 'auto', // Maintains the original size of the image
      backgroundPosition: 'center', // Starts the image from the top center
      backgroundRepeat: 'repeat', // Prevents the image from repeating
    }}>   
  
  
  
  
    </div>
  )
}




<div className="baltop-item" style={{
          backgroundImage: "url(/Plank.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
          height: "5vw"
        }}>



          
<div className="flex-container">


{
                    clanLeaderboardMode == 0 ? (                  <img src={colorblindMode ? "./kills1.png" : "./kills.png"} alt="transfer block" className="inventory-button-3" style={{width:"25%", marginTop: "0%"}} onClick={() => handleChangeClanLeaderboardMode()}/>
                  ) : 
                  (                  <img src={colorblindMode ? "./stocks1.png" : "./stocks.png"} alt="transfer block" className="inventory-button-3" style={{width:"25%", marginTop: "0%"}} onClick={() => handleChangeClanLeaderboardMode()}/>
                )
                  }




{
  filteredClanData.members.some(member => member.eth_address.toLowerCase() === account.toLowerCase()) ? (<div>
{
                    clanActionMode == 0 ? (                  <img src={colorblindMode ? "./settings1.png" : "./settings.png"} alt="transfer block" className="inventory-button-3" style={{width:"60%", marginTop: "0%"}} onClick={() => handleChangeClanActionMode()}/>
                  ) : 
                  (                  <img src={colorblindMode ? "./trade1.png" : "./trade.png"} alt="transfer block" className="inventory-button-3" style={{width:"40%", marginTop: "0%"}} onClick={() => handleChangeClanActionMode()}/>
                )
                  }

  </div>)
  : 
  (<div>
    {
      ourClanData ? (<div/>) : (<img src={colorblindMode ? "./joinclan1.png" : "./joinclan.png"} alt="transfer block" className="inventory-button-3" style={{width:"40%", marginTop: "0%"}} onClick={() => handleOpenClanRequest()}/>
    )
    }
    
    </div>)
}

    </div>
         
      
        </div>













  </div>

{
  clanLeaderboardMode == 0 ? (  <div className="clan-box-scroll"                    style={{
    position: "absolute",
      top: "60%",
      left: "0%",
      width: "70%",
      height: "40%",
      backgroundImage: "url(/BackgroundWater.png)",
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'repeat', // Prevents the image from repeating
    }}>  
  {Array.isArray(filteredClanData.stock_activity) ? <StockList stocks={fakeStockLog} /> : <p>Loading...</p>}
  
  
    </div>) : (<div className="clan-box-scroll"                    style={{
    position: "absolute",
      top: "60%",
      left: "0%",
      width: "70%",
      height: "40%",
      backgroundImage: "url(/BackgroundWater.png)",
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'repeat', // Prevents the image from repeating
    }}>  
  {Array.isArray(filteredClanData.members) ? <MembersKillsList members={filteredClanData.members} /> : <p>Loading...</p>}
  
  
    </div>)
}




  
  {
  filteredClanData && filteredClanData.owner_address.toLowerCase() == account.toLowerCase() ? (  <div className="clan-box"                    style={{
    position: "absolute",
    top: "60%",
    right: "0%",
    width: "30%",
    height: "40%",
    backgroundImage: "url(/BackgroundGrass.png)",
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'repeat', // Prevents the image from repeating
    }}>  
  
  
  {Array.isArray(filteredClanData.requests) ? <RequestsList requests={filteredClanData.requests} /> : <p>Loading...</p>}
  
    </div>) : (  <div className="clan-box"                    style={{
  position: "absolute",
  top: "60%",
  right: "0%",
  width: "30%",
  height: "40%",
  backgroundImage: "url(/BackgroundGrass.png)",
  backgroundPosition: 'center', // Centers the image
  backgroundRepeat: 'repeat', // Prevents the image from repeating
  }}>  


{Array.isArray(filteredClanData.members) ? <MembersList members={filteredClanData.members} moderator={false} /> : <p>Loading...</p>}

  </div>)
}





  {
  filteredClanData && filteredClanData.owner_address.toLowerCase() == account.toLowerCase() ? (  <div className="clan-box"                    style={{
    position: "absolute",
      top: "7%",
      right: "0%",
      width: "30%",
      height: "53%",
      backgroundImage: "url(/BackgroundGrass.png)",
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'repeat', // Prevents the image from repeating
    }}>  
  
  
  {Array.isArray(filteredClanData.members) ? <MembersList members={filteredClanData.members} moderator={true} /> : <p>Loading...</p>}
  
    </div>) : (  <div className="clan-box"                    style={{
  position: "absolute",
    top: "7%",
    right: "0%",
    width: "30%",
    height: "53%",
    backgroundImage: "url(/BackgroundGrass.png)",
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'repeat', // Prevents the image from repeating
  }}>  



  </div>)
}




                    </div>) : (<div/>)
          }



{
              ourClanID == 0 && clanCreation == false  && clanView == true? (
              
       
              <img src={colorblindMode ? "./return1.png" : "./return.png"} alt="create duel" className="clan-footer" onClick={handleChangeCreate}/>
         
            
            ) : (<div/>)
            }


            {
              ourClanID == 0 && clanCreation == false  && clanView == false? (
              
       
              <img src={colorblindMode ? "./clanCreate1.png" : "./clanCreate.png"} alt="create duel" className="clan-footer" onClick={handleChangeCreate}/>
         
            
            ) : (<div/>)
            }







{ 
              ourClanID == 0 && clanCreation == true? (
              
       
                <img src={colorblindMode ? "./clanCreator1.png" : "./clanCreator.png"} alt="Player Rank" className="clan-title"/>
         
            
            ) : (<div/>)
            }


            {
              ourClanID == 0 && clanCreation == true? (
              
       
              <img src={colorblindMode ? "./cancel1.png" : "./cancel.png"} alt="create duel" className="clan-footer" onClick={handleChangeCreate}/>
         
            
            ) : (<div/>)
            }





             {
              ourClanID != 0 && !clanView ? (
              
       
                <img src={colorblindMode ? "./view1.png" : "./view.png"} alt="create duel" className="clan-footer" onClick={() => {handleClanIDClick(ourClanID)}}/>         
            
            ) : (<div/>)
            }

   
            {
              ourClanID != 0 && clanView ? (
              
       
                <img src={colorblindMode ? "./return1.png" : "./return.png"} alt="create duel" className="clan-footer" onClick={handleChangeClanView}/>         
            
            ) : (<div/>)
            }
              
        

        </div>) : (<div/>)
        }
































            {
              page == "Shop" && shopMode == 0 ? (<div className="shop-content" style={{
                backgroundImage: `url(${!screenMode ? "./Background4.png" : "./Background3.png"})`,
                backgroundSize: 'auto', // Maintains the original size of the image
                backgroundPosition: 'center top', // Starts the image from the top center
                backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                marginRight: "10%"
              }}>
                {
                  searchTerm !== "" ? 
                    <ShopList curves={filteredCurveData} /> 
                  : 
                    (curveData ? <ShopList curves={curveData} /> : <p>Loading...</p>)
                }
              </div>) : (<div/>)
            }

{
              page == "Shop" && shopMode == 1 ? (<div className="shop-content" style={{
                backgroundImage: `url(${!screenMode ? "./Background4.png" : "./Background3.png"})`,
                backgroundSize: 'auto', // Maintains the original size of the image
                backgroundPosition: 'center top', // Starts the image from the top center
                backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                marginRight: "10%"
              }}>
                {
                  searchTerm !== "" ? 
                    <ListingList listings={filteredItemsData} /> 
                  : 
                    (itemShopData ? <ListingList listings={itemShopData} /> : <p>Loading...</p>)
                }
              </div>) : (<div/>)
            }

{
              page == "Shop" && shopMode == 2 ? (<div className="shop-content" style={{
                backgroundImage: `url(${!screenMode ? "./Background4.png" : "./Background3.png"})`,
                backgroundSize: 'auto', // Maintains the original size of the image
                backgroundPosition: 'center top', // Starts the image from the top center
                backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                marginRight: "10%"
              }}>
                {
                  searchTerm !== "" ? 
                    <SpecialList listings={specialShopData} /> 
                  : 
                    (itemShopData ? <SpecialList listings={specialShopData} /> : <p>Loading...</p>)
                }
              </div>) : (<div/>)
            }


            {
              page == "Shop" && shopMode != 2 ? (  <div className="wallet-box"                    style={{
                bottom: "5%",
                left: "1.875%",
                width: "70%",
                height: "5%",
                padding: "10px",
                backgroundImage: "url(/woodbackground.png)",
    
                backgroundPosition: 'center', // Centers the image
           
              }}>




<img src={colorblindMode ? "./clear1.png" : "./clear.png"} alt="create duel" class="wallet-box-clear-button" onClick={handleReset}/>

<input
  type="text"
  id="letterInput"
  class="wallet-input"
  required
  autoComplete="off"
  value={searchTerm}
  onChange={handleSearchChange}
  placeholder="Search..."
  pattern="[A-Za-z]*"
  style={{
    backgroundImage: "url(/Plank.png)",

    backgroundPosition: 'center', // Centers the image

  }}
/>



</div>) : (<div/>)
}











{
  page == "Shop" ? (  
    <div className="shop-button-container">
        <img
          src={shopMode === 0 ? "./itemsColored.png" : "./itemsGray.png"}
          className="shop-custom-button"
          onClick={() => handleShopMode(0)}
        />
        <img
          src={shopMode === 1 ? "./toolsColored.png" : "./toolsGray.png"}
          className="shop-custom-button"
          onClick={() => handleShopMode(1)}
        />
        <img
          src={shopMode === 2 ? "./specialColored.png" : "./specialGray.png"}
          className="shop-custom-button"
          onClick={() => handleShopMode(2)}
        />
        {/* Add more buttons as needed */}
      </div>) : (<div/>)
}


         
{

  page == "Wallet" ? (<img src={colorblindMode ? "./Inventory1.png" : "./Inventory.png"} alt="Player Rank" className="duel-title" onClick={()=>newPopup("These are your items!", "Click them to open the action menu.", "./BringMetamask.png", "Get Tokens!", 1)}/>
) : (<div>
  
  



  
  </div>)
}


  


</div>)
          }

        <div className="wallet-right-content" >
          <div className="wallet-avatar">
            {/* Add player avatar here */}
            <img src="./Login.png" alt="Player Avatar" />

          </div>
          <div className="wallet-user-details">
            {
              username == "0" ? (<p><img src={colorblindMode ? "./LinkProfile1.png" : "./LinkProfile.png"} alt="Player Rank" className="wallet-rank" onClick={openLink}/></p>) :
               (<p><img src={rankImage} alt="Player Rank" className="wallet-rank" onClick={openRankup}/></p>)
            }
            
            <p className="setup-description6" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`Server Tokens: `}  <br/>{`${tokenBalanceServer}`}</p>
                  <p className="setup-description6" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`Wallet Tokens: `}  <br/>{`${tokenBalanceWallet}`}</p>
                  <p className="setup-description6" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`Airdrop: `}  <br/>{`${airdrop.toFixed(3)}`}</p>

          </div>
          <div className="wallet-user-details">

          <p><img src={colorblindMode ? "./bridge1.png" : "./bridge.png"} alt="Player Rank" className="wallet-menu-button" onClick={openSell}/></p>
          <p><img src={colorblindMode ? "./Transfer1.png" : "./Transfer.png"} alt="Player Rank" className="wallet-menu-button" onClick={openTransfer}/></p>
        
    
     
          </div>

        </div>
          </div>
        </div>
          
          
          
          </div>) : (<div/>)


        }


        </div>

        <div>

          {
            !savedTransactionHash ? (<div/>) : (<img src="./exclamation.png" className="notification" alt="close" onClick={openTransaction}/>)
          }
        </div>

        <div>

        {
          admin == false ? (<div/>) : (<img src="./admin.png" className="admin" alt="close" onClick={openAdmin}/>)
        }
        </div>

        <div>

{
  moderator == false ? (<div/>) : (<img src="./mod.png" className="moderator" alt="close" onClick={openMod}/>)
}
</div>
<img src="./commandBlock.png" className="settings" alt="close" onClick={openSettings}/>
  
    </div>
  );
};

export default App;
